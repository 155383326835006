<div class="list-of-content">
  <esg-dynamic-content-item
    *ngFor="let item of contentItems; let i = index; trackBy: trackByMethod"
    [item]="item"
    [onChange]="onChange"
    [reorderMode]="reorderMode"
    [readMode]="readMode"
    [active]="activeContentId === item.contentId"
    (onDelete)="onDelete.emit($event)"
    (onSetActive)="handleOnSetActiveId($event)"></esg-dynamic-content-item>
</div>
