import { Injectable } from '@angular/core';
import { KpiTargetUi } from './set-target-dialog.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IOption, TargetTrendOptions } from 'src/app/static-data/options';
import { IndicatorDataValuesSeriesApiService } from 'src/app/api-client/report-api/indicator-data-value-series-api-service';
import {
  TargetConfiguration,
  Trend,
  UpdateIndicatorDataValueSeriesTargetCommandParams,
} from 'src/api-client/report-api.generated';
import { CustomFormValidators } from 'src/app/shared/utils/custom-form-validators';

@Injectable()
export class SetTargetDialogStateService {
  kpiId!: string;
  fc!: KpiTargetUi;
  formGroup!: FormGroup;
  targetTrendOptions = TargetTrendOptions;

  constructor(public seriesApi: IndicatorDataValuesSeriesApiService) {
    this.fc = {
      baseYear: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required, CustomFormValidators.numeric, Validators.min(1990)],
      }),
      baseYearValue: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required, CustomFormValidators.numeric],
      }),
      targetYear: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required, CustomFormValidators.numeric],
      }),
      targetTrendType: new FormControl<IOption>(TargetTrendOptions[0], {
        nonNullable: true,
        validators: [Validators.required],
      }),
      targetPerecentChange: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required, CustomFormValidators.numeric],
      }),
      targetValue: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required, CustomFormValidators.numeric],
      }),
    };

    this.formGroup = new FormGroup(this.fc);
  }

  get baseYearValidationMessage() {
    if (!this.fc.baseYear.dirty || !this.fc.baseYear.touched) {
      return undefined;
    }

    if (this.fc.targetYear.touched && parseInt(this.fc.baseYear.value) >= parseInt(this.fc.targetYear.value)) {
      return 'The base year needs to be earlier than the target year.';
    }

    if (parseInt(this.fc.baseYear.value) < 1990) {
      return 'The year needs to be 1990 or later, according to the Kyoto Protocol.';
    }

    return undefined;
  }

  get targetYearValidationMessage() {
    if (!this.fc.targetYear.dirty || !this.fc.targetYear.touched) {
      return undefined;
    }

    if (parseInt(this.fc.targetYear.value) < 1990) {
      return 'The year needs to be 1990 or later, according to the Kyoto Protocol.';
    }

    if (this.fc.baseYear.touched && parseInt(this.fc.targetYear.value) <= parseInt(this.fc.baseYear.value)) {
      return 'The target year needs to be later than the base year.';
    }

    return undefined;
  }

  async init(kpiId: string) {
    this.kpiId = kpiId;

    var tc = await this.seriesApi.getTarget(kpiId);

    if (tc.result) {
      this.fc.baseYear.setValue(tc.result.baseYear.toString());
      this.fc.baseYearValue.setValue(tc.result.baseValue.toString());
      this.fc.targetYear.setValue(tc.result.targetYear.toString());
      this.fc.targetTrendType.setValue(
        TargetTrendOptions.find(o => o.value === tc.result?.trend) || TargetTrendOptions[0]
      );
      this.fc.targetPerecentChange.setValue(tc.result.targetPercentage.toString());
      this.fc.targetValue.setValue(tc.result.targetValue.toString());
    }

    this.fc.targetPerecentChange.valueChanges.subscribe(value => {
      this.calculateTargetValue(
        this.fc.baseYearValue.value,
        value,
        Trend[this.fc.targetTrendType.value.value as Trend]
      );
    });

    this.fc.targetTrendType.valueChanges.subscribe(value => {
      this.calculateTargetValue(
        this.fc.baseYearValue.value,
        this.fc.targetPerecentChange.value,
        Trend[value.value as Trend]
      );
    });

    this.fc.baseYearValue.valueChanges.subscribe(value => {
      this.calculatePercentageChange(value, this.fc.targetValue.value);
    });

    this.fc.targetValue.valueChanges.subscribe(value => {
      this.calculatePercentageChange(this.fc.baseYearValue.value, value);
    });
  }

  calculateTargetValue(baseYearValue: string, percentageChange: string, trend: Trend) {
    var baseYearValueNumber = parseInt(baseYearValue);
    var percentageChangeNumber = parseInt(percentageChange);
    if (Number.isNaN(baseYearValueNumber) || Number.isNaN(percentageChangeNumber)) {
      return;
    }
    this.fc.targetValue.setValue(
      (
        baseYearValueNumber +
        ((baseYearValueNumber * percentageChangeNumber) / 100) * (trend === Trend.Increase ? 1 : -1)
      ).toString(),
      { emitEvent: false }
    );
  }

  calculatePercentageChange(baseYearValue: string, targetValue: string) {
    var baseYearValueNumber = parseInt(baseYearValue);
    var targetValueNumber = parseInt(targetValue);
    if (Number.isNaN(baseYearValueNumber) || Number.isNaN(targetValueNumber)) {
      return;
    }
    this.fc.targetTrendType.setValue(
      baseYearValueNumber < targetValueNumber ? TargetTrendOptions[1] : TargetTrendOptions[0],
      { emitEvent: false }
    );

    this.fc.targetPerecentChange.setValue(
      Math.round((Math.abs(targetValueNumber - baseYearValueNumber) * 100) / baseYearValueNumber).toString(),
      { emitEvent: false }
    );
  }

  async setTarget() {
    return await this.seriesApi.updateTarget(
      new UpdateIndicatorDataValueSeriesTargetCommandParams({
        id: this.kpiId,
        targetConfiguration: new TargetConfiguration({
          baseYear: parseInt(this.fc.baseYear.value),
          baseValue: parseInt(this.fc.baseYearValue.value),
          targetYear: parseInt(this.fc.targetYear.value),
          trend: Trend[this.fc.targetTrendType.value.value as Trend],
          targetPercentage: parseInt(this.fc.targetPerecentChange.value),
          targetValue: parseInt(this.fc.targetValue.value),
        }),
      })
    );
  }
}
