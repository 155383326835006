<esg-set-indicator-target-dialog
  *ngIf="state.targetDialogOpen"
  [kpiId]="indicatorId"
  [indicatorUnit]="indicatorUnit"
  (onClose)="handleCloseTargetDialog()"></esg-set-indicator-target-dialog>
<div class="top-section">
  <div class="time-options">
    <esg-select
      label="FROM"
      [formControl]="state.timeFormGroup.controls.yearFrom"
      [options]="state.fromYearOptions"></esg-select>
    <svg class="dash" xmlns="http://www.w3.org/2000/svg" width="12" height="3" viewBox="0 0 12 3" fill="none">
      <path d="M0 2.20312V0.703125H12V2.20312H0Z" fill="#666666" />
    </svg>
    <esg-select
      label="TO"
      [formControl]="state.timeFormGroup.controls.yearTo"
      [options]="state.toYearOptions"></esg-select>
    <esg-select
      class="period"
      label="INTERVAL"
      [formControl]="state.timeFormGroup.controls.timePeriod"
      [options]="state.timePeriodTypeOptions"></esg-select>
  </div>
  <div class="right-toolbar">
    <esg-button *ngIf="!indicatorTableDefinition" class="tertiary" size="standard" (onClick)="handleSetTarget()">
      <esg-icon-target color="#0071e3"></esg-icon-target>
      <!-- <span *ngIf="kpiData.targetSet">Edit Target</span> -->
      <span>Set Target</span>
    </esg-button>
    <esg-context-menu [options]="state.contextMenuProvider()"></esg-context-menu>
  </div>
</div>

<ng-container *ngIf="!state.loading">
  <div *ngIf="!indicatorTableDefinition" class="calculated-numeric">
    <esg-numeric-calculated-summary
      *ngIf="state.numericCalculatedDataSummary"
      class="summary-bar"
      [summary]="state.numericCalculatedDataSummary"
      [unit]="indicatorUnit.name"
      [yearLabel]="state.yearInterval"
      [isAverage]="isNumericAverage"></esg-numeric-calculated-summary>
    <ng-container *ngIf="state.numericCalculatedData.length">
      <esg-indicator-graph-view
        *ngIf="state.calculatedGraph"
        class="graph-view"
        [name]="indicatorId"
        [unit]="indicatorUnit.name"
        [isAnnual]="state.isAnnual"
        [graphData]="state.calculatedGraph"></esg-indicator-graph-view>
      <esg-numeric-calculated-data
        *ngIf="state.numericCalculatedData.length"
        [data]="state.numericCalculatedData"
        [unit]="indicatorUnit.name"
        [isAnnual]="state.isAnnual"
        (expandSubstrateData)="state.handleExpandSubstrateData($event)"
        (expandSubstrateGroupData)="state.handleExpandSubstrateGroupData($event)"></esg-numeric-calculated-data>
    </ng-container>
  </div>

  <div *ngIf="indicatorTableDefinition" class="calculated-tables">
    <esg-generic-table
      class="calculated-table-headers"
      [columns]="calculatedTableHeaders"
      [isConfigurable]="false"></esg-generic-table>
    <esg-indicator-table-value
      *ngFor="let table of state.tableCalculatedData"
      [tableValue]="table.value"
      [tableDefinition]="indicatorTableDefinition"
      [periodLabel]="table.periodLabel"
      [errorMessage]="table.errorMessage"
      [columnTypeMap]="calculatedTableColumnTypeMap"
      [columnOptionsMap]="calculatedTableColumnOptionsMap"
      [columnUnitMap]="calculatedTableColumnUnitMap"
      [unitOptions]="state.unitOptions"></esg-indicator-table-value>
  </div>
</ng-container>

<esg-dot-flashing *ngIf="state.loading" class="loader" label="Loading data"></esg-dot-flashing>
