import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-empty-content',
  templateUrl: './empty-content.component.html',
  host: { '(click)': 'handleOnClick($event)' },

  styleUrls: ['./empty-content.component.scss'],
})
export class EmptyContentComponent implements OnInit {
  @Input() infoText: string = '';
  @Input({ required: true }) addText!: string;
  @Output() onClick = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  handleOnClick(event: Event) {
    event.stopPropagation();
    this.onClick.emit();
  }
}
