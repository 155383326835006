import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DynamicContentBase, IContentCommonActions, IContentCommonStates } from '../../dynamic-content-data';
import { IUploadedPhoto, IUploadPhotoComponentTexts } from 'src/app/shared/ui/upload-picture/upload-picture.component';

export interface IPictureContentActions extends IContentCommonActions {
  uploadPhoto: (file?: File) => void;
}

export interface IPictureContentStates extends IContentCommonStates {
  uploadedPhoto?: IUploadedPhoto;
}

export interface IPictureContentData {
  blobName: string;
  altText: string;
}

@Component({
  selector: '[app-picture]',
  templateUrl: './picture.component.html',
  styleUrls: ['./picture.component.scss'],
})
export class PictureComponent
  extends DynamicContentBase<IPictureContentActions, IPictureContentStates, IPictureContentData>
  implements OnInit
{
  @Input() style: Object = { height: '360px' };

  componentTexts: IUploadPhotoComponentTexts = {
    firstLineP1: 'Drag and drop an image here or ',
    firstLineP2: ' an image',
    firstLineP1UploadedPhoto: 'Drag and drop an image here or ',
    firstLineP2UploadedPhoto: ' an image',
    secondLine: 'Image can be SVG, JPG or PNG with a minimum width of 1200px',
  };

  editing: boolean = false;

  fc = {
    altTag: new FormControl<string | null>({ value: '', disabled: true }),
  };
  formgroup = new FormGroup(this.fc);

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.fc.altTag.setValue(this.data?.altText || '');
    if (this.active && !this.states.uploadedPhoto?.url) this.editing = true;
  }

  handleImageClick() {
    this.onSetActive.emit(true);
  }

  handleOnAltTagChange(tag: string) {
    this.onChange(
      this.contentId,
      {
        uploadedPhoto: this.states.uploadedPhoto,
      },
      this.data?.blobName ? { blobName: this.data?.blobName, altText: tag || '' } : undefined
    );
  }

  handleOnEdit() {
    this.editing = true;
    this.onSetActive.emit(true);
  }

  handleOnEditCancel() {
    this.editing = false;
    this.onSetActive.emit(false);
  }

  handleOutsideClick() {
    this.active && this.handleOnEditCancel();
  }

  async handleUploadCoverPhoto(file: File) {
    await this.actions.uploadPhoto(file);
    this.active && this.handleOnEditCancel();
  }

  handleClearPhoto() {
    this.actions.uploadPhoto(undefined);
  }
}
