import { Injectable } from '@angular/core';
import {
  AssetType,
  UpdateClientAssetCommandParams,
  ClientAsset,
  CreateClientAssetCommandParams,
  ClientAssetVm,
  CountryCodes,
  UpdateAssetBasePropertiesCommandParams,
  UpdateClientAssetBasePropertiesCommandParams,
} from 'src/api-client/report-api.generated';
import { AssetApiService } from 'src/app/api-client/report-api/asset-api-service';
import { OrganizationApiService } from 'src/app/api-client/report-api/organization-api-service';
import { AppInfoService } from 'src/app/core/app-info.service';
import { AssetListStateService } from '../asset-list-state.service';
import { CountryApiService } from 'src/app/api-client/report-api/country-api-service';
import { AssetsMenuStateService } from '../../assets-menu/assets-menu-state.service';
import { SectorApiService } from 'src/app/api-client/report-api/sector-api-service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import {
  DataRecordOutput,
  ExcelImportValidationMessage,
  FieldConfig,
  validateExcelOptionColumn,
} from 'src/app/shared/components/data-import-from-excel/data-import-from-excel-state.service';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';

@Injectable()
export class ClientsStateService extends AssetListStateService {
  assetType = AssetType.Client;

  constructor(
    appInfo: AppInfoService,
    appConfig: AppConfigurationService,
    assetApiService: AssetApiService,
    countryApiService: CountryApiService,
    organizationApiService: OrganizationApiService,
    sectorApiService: SectorApiService,
    notificationService: NotificationService,
    assetsMenuState: AssetsMenuStateService
  ) {
    super(
      appInfo,
      appConfig,
      assetApiService,
      countryApiService,
      organizationApiService,
      sectorApiService,
      notificationService,
      assetsMenuState
    );
  }

  excelTitle = 'Clients';
  importFromExcelFieldConfigs: FieldConfig[] = [
    { propertyName: 'name', title: 'Client name', type: 'string' },
    { propertyName: 'country', title: 'Client’s country', type: 'string' },
    { propertyName: 'organization', title: 'Organization', type: 'string' },
    { propertyName: 'versionValidFrom', title: 'Version valid from', type: 'date', optional: true },
  ];

  importFromExcelEnabled = true;

  async getAssets() {
    const response = await this.assetApiService.getAssetsByOrganizationAndType(undefined, this.assetType);
    const result = response.result as ClientAssetVm[];
    this.assets = result.map(asset => ({
      id: asset.id,
      name: asset.name,
      country: asset.countryName,
      organizationName: asset.orgOrSuborgName,
      versionValidFrom: asset.versionValidFrom,
      recordsCount: asset.recordCount,
      firstRecordDate: asset.firstRecordDate,
      lastRecordDate: asset.lastRecordDate,
    }));
  }

  async formSubmit(params: CreateClientAssetCommandParams) {
    if (this.selectedAsset) {
      const selected = this.selectedAsset;
      this.setSelectedAsset(undefined);

      const updateParams = new UpdateClientAssetBasePropertiesCommandParams({
        id: selected.id,
        type: this.assetType,
        name: params.name,
        subOrganizationId: params.subOrganizationId,
        countryCode: params.countryCode,
      });
      const response = await this.assetApiService.updateAssetBaseProperties(updateParams);
      if (response.success) {
        this.getAssets();
      } else {
        this.notificationService.showError(
          `Unable to update ${this.assetType}`,
          response.validationErrors?.join('\n') || response.message || 'Unknown error'
        );
      }
    } else {
      const createParams = new CreateClientAssetCommandParams({
        type: this.assetType,
        name: params.name,
        organizationId: this.organizationId || '',
        subOrganizationId: params.subOrganizationId,
        countryCode: params.countryCode,
        versionValidFrom: this.defaultVersionDate,
      });
      this.updating = true;
      const response = await this.assetApiService.createAsset(createParams);
      if (response.success) {
        await this.getAssets();
        this.assetsMenuState.loadAssetTypeCounters();
      } else {
        this.notificationService.showError(
          `Unable to add ${this.assetType}`,
          response.validationErrors?.join('\n') || response.message || 'Unknown error'
        );
      }
      this.updating = false;
    }
  }

  createAssetFromExcel(asset: DataRecordOutput) {
    const organizationOption = this.getExcelOptionColumn(
      'organization',
      this.organizationOptions,
      asset,
      'Organization'
    );
    const countryOption = this.getExcelOptionColumn('country', this.countryOptions, asset, 'Client’s country');

    const body = new CreateClientAssetCommandParams({
      type: this.assetType,
      organizationId: this.organizationId || '',
      subOrganizationId: this.organizationId === organizationOption.value ? undefined : organizationOption.value,
      name: asset.name?.value || '',
      versionValidFrom: asset.versionValidFrom?.value || this.defaultVersionDate,
      countryCode: CountryCodes[countryOption.value as keyof typeof CountryCodes],
    });
    return this.assetApiService.createAsset(body);
  }

  validateOptionColumns(records: DataRecordOutput[]): ExcelImportValidationMessage[] {
    let validationMessages: ExcelImportValidationMessage[] = [];
    validationMessages = validateExcelOptionColumn(
      validationMessages,
      'organization',
      this.organizationOptions,
      records,
      'Organization'
    );

    validationMessages = validateExcelOptionColumn(
      validationMessages,
      'country',
      this.countryOptions,
      records,
      'Client’s country'
    );

    return validationMessages;
  }
}
