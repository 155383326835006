import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiModule } from '../ui/ui.module';
import { IndicatorPreviewComponent } from './indicator-preview/indicator-preview.component';
import { SubstrateDataPreviewComponent } from './substrate-data-preview/substrate-data-preview.component';
import { TableDataComponent } from './table-data/table-data.component';
import { DetailsDialogComponent } from './details-dialog/details-dialog.component';
import { DirectiveModule } from '../directives/directive.module';
import { FormDialogComponent } from './form-dialog/form-dialog.component';
import { TimePeriodComponent } from './time-period/time-period.component';
import { AssetHistoryDialogComponent } from './asset-history-dialog/asset-history-dialog.component';
import { HeaderWithGuidanceComponent } from './header-with-guidance/header-with-guidance.component';
import { ChartV3Component } from './chart/chart-v3.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { BetaTagComponent } from './beta-tag/beta-tag.component';
import { DataImportFromExcelComponent } from './data-import-from-excel/data-import-from-excel.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { CalculationTableComponent } from './calculation-table/calculation-table.component';
import { RiskLabelComponent } from './risk-label/risk-label.component';
import { RiskBarComponent } from './risk-label/risk-bar.component';
import { FilterPaginationBarComponent } from './filter-sort-bar/filter-pagination-bar.component';
import { ImportFromExcelToolbarComponent } from './import-from-excel-toolbar/import-from-excel-toolbar.component';
import { OnboardingDialogComponent } from './onboarding-dialog/onboarding-dialog.component';
import { RecordsTableComponent } from './records-table/records-table.component';
import { KeyboardShortcutsDirective } from '../directives/keyboard-shortcuts.directive';
import { TimePeriodSelectorComponent } from './time-period-selector/time-period-selector.component';
import { TimePeriodSelectorOptionsComponent } from './time-period-selector/time-period-selector-options/time-period-selector-options.component';
import { DescriptionAndReferenceComponent } from './description-and-reference/description-and-reference.component';
import { ColumnViewerComponent } from './column-viewer/column-viewer.component';
import { FormatUnitWithHtmlPipe } from '../pipes/format-unit-with-html.pipe';
import { FormatUnitWithUnicodePipe } from '../pipes/format-unit-with-unicode.pipe';
import { RouteSelectorComponent } from './route-selector/route-selector.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    IndicatorPreviewComponent,
    SubstrateDataPreviewComponent,
    TableDataComponent,
    DetailsDialogComponent,
    FormDialogComponent,
    AssetHistoryDialogComponent,
    TimePeriodComponent,
    HeaderWithGuidanceComponent,
    ChartV3Component,
    BetaTagComponent,
    DataImportFromExcelComponent,
    SideMenuComponent,
    CalculationTableComponent,
    RiskLabelComponent,
    RiskBarComponent,
    FilterPaginationBarComponent,
    ImportFromExcelToolbarComponent,
    OnboardingDialogComponent,
    RecordsTableComponent,
    TimePeriodSelectorComponent,
    TimePeriodSelectorOptionsComponent,
    DescriptionAndReferenceComponent,
    ColumnViewerComponent,
    RouteSelectorComponent,
  ],
  imports: [
    UiModule,
    CommonModule,
    DirectiveModule,
    KeyboardShortcutsDirective,
    FormsModule,
    ReactiveFormsModule,
    HighchartsChartModule,
    FormatUnitWithHtmlPipe,
    FormatUnitWithUnicodePipe,
    RouterModule,
  ],
  exports: [
    IndicatorPreviewComponent,
    SubstrateDataPreviewComponent,
    TableDataComponent,
    DetailsDialogComponent,
    FormDialogComponent,
    AssetHistoryDialogComponent,
    TimePeriodComponent,
    ChartV3Component,
    HeaderWithGuidanceComponent,
    BetaTagComponent,
    DataImportFromExcelComponent,
    SideMenuComponent,
    CalculationTableComponent,
    RiskLabelComponent,
    RiskBarComponent,
    FilterPaginationBarComponent,
    ImportFromExcelToolbarComponent,
    OnboardingDialogComponent,
    RecordsTableComponent,
    TimePeriodSelectorComponent,
    TimePeriodSelectorOptionsComponent,
    DescriptionAndReferenceComponent,
    ColumnViewerComponent,
    RouteSelectorComponent,
  ],
})
export class SharedComponentsModule {}
