import {
  UpdateIndicatorDataCommandParams,
  CreateIndicatorDataCommandParams,
  IndicatorValueType,
  IndicatorTableValue,
  GetIndicatorDataByQueryVm,
} from 'src/api-client/report-api.generated';
import { IGroupedOptions, IOption } from 'src/app/static-data/options';
import { AssetApiService } from 'src/app/api-client/report-api/asset-api-service';
import { Injectable } from '@angular/core';
import { AssetCategoryLabel } from 'src/app/static-data/enum-mappings';
import { IndicatorDataApiService } from 'src/app/api-client/report-api/indicator-data-api-service';
import { CurrencyApiService } from 'src/app/api-client/report-api/currency-api-service';
import { sortOptionsAlphabetically } from 'src/app/shared/utils/array';
import { Subscription } from 'rxjs';
import { UnitService } from 'src/app/shared/services/unit/unit.service';
import { ITimePeriodData } from 'src/app/shared/components/time-period/time-period.component';
import { RecordSource } from 'src/app/shared/components/records-table/records-table.component';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';

export interface IndicatorRecordData {
  id: string;
  assetId: string;
  assetName: string;
  timePeriod: ITimePeriodData;
  description?: string;
  numericValue?: number;
  tableValue?: IndicatorTableValue;
  recordSource?: RecordSource;
}

@Injectable()
export class IndicatorDataStateService {
  readonly valueTypeEnum = IndicatorValueType;
  private unitOptionsSubscription?: Subscription;

  indicatorId?: string;

  indicatorRecords: IndicatorRecordData[] = [];
  totalCount: number = 0;
  newlyAddedRowsIds: string[] = [];

  loading = false;
  isAllRecordsDialogOpen = false;

  assetOptions: IGroupedOptions[] = [];
  currencyOptions: IOption[] = [];
  unitOptions: IOption[] = [];

  get getDisplayedItemsRange() {
    if (this.totalCount === 0) {
      return '0 – 0';
    }
    return 1 + ' – ' + Math.min(10, this.totalCount);
  }

  get intersectionObserverRootMargin() {
    if (this.appConfig.NewNavbarEnabled) {
      return 136;
    } else return 181;
  }

  constructor(
    private appConfig: AppConfigurationService,
    private assetApiService: AssetApiService,
    private indicatorDataService: IndicatorDataApiService,
    private currencyApiService: CurrencyApiService,
    private unitService: UnitService
  ) {
    this.unitOptionsSubscription = this.unitService.units$.subscribe(units => {
      this.unitOptions = Object.values(units).map(unit => ({
        value: unit.key,
        label: unit.name,
      }));
    });
  }

  async init(indicatorId: string) {
    this.indicatorId = indicatorId;
    this.loading = true;
    await Promise.all([this.getAssetOptions(), this.getDataRecords(), this.getCurrencies()]);
    this.loading = false;
  }

  async getAssetOptions() {
    const response = await this.assetApiService.getAllAssetOptions();
    const assets = response.result;

    const groupedAssets: { [key: string]: IGroupedOptions } = {};

    assets.map(a => {
      if (!groupedAssets[a.assetType]) {
        groupedAssets[a.assetType] = { label: AssetCategoryLabel[a.assetType], options: [] };
      }
      groupedAssets[a.assetType].options.push({ value: a.id, label: a.name });
    });

    this.assetOptions = Object.values(groupedAssets);
  }

  async getDataRecords() {
    if (this.indicatorId) {
      const response = await this.indicatorDataService.getIndicatorDataBy(
        this.indicatorId,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        1,
        10
      );
      const assetMap = new Map(
        this.assetOptions.flatMap(group => group.options.map(option => [option.value, option.label]))
      );

      this.indicatorRecords = response.result.items.map(record => {
        const recordSource = record.dataRecordId
          ? RecordSource.RawData
          : record.indicatorDataId
          ? RecordSource.IndicatorDefinition
          : undefined;
        return {
          id: record.id,
          assetId: record.assetId,
          assetName: assetMap.get(record.assetId) || '',
          timePeriod: record.timePeriod,
          description: GetIndicatorDataDescription(record),
          numericValue: record.numericValue,
          tableValue: record.tableValue,
          recordSource: recordSource,
        };
      });

      this.totalCount = response.result.totalCount;
    }
  }

  async getCurrencies() {
    const response = await this.currencyApiService.getAllCurrencies();
    this.currencyOptions = sortOptionsAlphabetically(
      response.result.map(currency => ({ value: currency.code, label: currency.code }))
    );
  }

  async delayRemoveAddedId(addedId: string, ms: number) {
    await new Promise(resolve => setTimeout(resolve, ms));
    this.newlyAddedRowsIds = this.newlyAddedRowsIds.filter(id => id !== addedId);
  }

  async handleAddRecord(params: CreateIndicatorDataCommandParams) {
    const response = await this.indicatorDataService.createIndicatorData(params);

    if (response.success) {
      this.getDataRecords();
      const addedId = response.result.id;
      this.newlyAddedRowsIds.push(addedId);
      this.delayRemoveAddedId(addedId, 15000);
    }
  }

  async handleUpdateRecord(params: UpdateIndicatorDataCommandParams) {
    const response = await this.indicatorDataService.updateIndicatorData(params);

    if (response.success) {
      this.getDataRecords();
    }
  }

  handleDeleteRecord(id: string) {
    this.indicatorDataService.deleteIndicatorData(id).then(response => {
      if (response.success) {
        this.getDataRecords();
      }
    });
  }

  handleOpenAllRecordsDialog() {
    this.isAllRecordsDialogOpen = true;
  }

  handleCloseAllRecordsDialog() {
    this.isAllRecordsDialogOpen = false;
  }

  ngOnDestroy() {
    if (this.unitOptionsSubscription) {
      this.unitOptionsSubscription.unsubscribe();
    }
  }
}

export function GetIndicatorDataDescription(record: GetIndicatorDataByQueryVm) {
  if (record.indicatorDataId) {
    return `Indicator / ${record.indicatorName}` + (record.description ? ` / ${record.description}` : '');
  } else if (record.dataRecordId) {
    return `RawData / ${record.dataRecordTypeName}`;
  } else {
    return record.description;
  }
}
