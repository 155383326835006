import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  TopicItemType,
  CreateArticleTopicItemCommandParams,
  UpdateArticleTopicItemCommandParams,
} from 'src/api-client/report-api.generated';
import { TopicItemApiService } from 'src/app/api-client/report-api/topic-item-api-service';
import { TopicItemContentUi } from '../topic-item-details/topic-item-details-state.service';

interface ArticleForm {
  title: FormControl<string | null>;
}

@Injectable()
export class ArticleFormStateService {
  articleFc?: ArticleForm = undefined;
  formgroup?: FormGroup = undefined;

  headerText = 'Create new content';
  submitText = 'Create';

  constructor(private topicItemApiService: TopicItemApiService) {}

  initForm(selectedIndicator: TopicItemContentUi | undefined) {
    this.articleFc = {
      title: new FormControl<string>(selectedIndicator?.name || '', [Validators.required]),
    };
    this.formgroup = new FormGroup(this.articleFc);
    this.headerText = selectedIndicator ? 'Edit Article' : 'Create Article';
    this.submitText = selectedIndicator ? 'Save changes' : 'Create Article';
  }

  async submitForm(reportId: string, mainLevelId: string, topicId: string, topicItemId?: string) {
    this.formgroup?.markAsPristine();
    const articleParams = { type: TopicItemType.Article, name: this.articleFc?.title.value || '' };
    if (topicItemId) {
      await this.topicItemApiService.updateTopicItem(
        new UpdateArticleTopicItemCommandParams({
          topicItemId: topicItemId,
          ...articleParams,
        })
      );
    } else {
      await this.topicItemApiService.addTopicItem(
        new CreateArticleTopicItemCommandParams({
          reportId: reportId,
          mainLevelId: mainLevelId,
          topicId: topicId,
          ...articleParams,
        })
      );
    }
  }
}
