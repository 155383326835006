import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TimePeriodType } from 'src/api-client/report-api.generated';
import { IOption } from 'src/app/static-data/options';
import { generateCurrentYearOptions, globalMonthOptions, globalQuarterOptions } from 'src/app/static-data/options';

export interface ITimePeriodData {
  type: TimePeriodType;
  year?: number;
  period?: number;
  customStart?: Date | undefined;
  customEnd?: Date | undefined;
}

@Component({
  selector: 'esg-time-period',
  templateUrl: './time-period.component.html',
  styleUrls: ['./time-period.component.scss'],
})
export class TimePeriodComponent implements OnInit {
  @Input() timePeriod?: ITimePeriodData;
  @Input() disabled = false;
  @Input() hidePeriodTypes: TimePeriodType[] = [TimePeriodType.Custom];
  @Input() showYearStartMonth: boolean = false;
  @Input() numberOfYearsBefore?: number = 1;

  timePeriodEnum = TimePeriodType;
  timePeriodType!: TimePeriodType;

  annualYearValue!: IOption;
  annualYearStartMonthValue!: IOption;
  quarterlyValue!: IOption;
  quarterlyYearValue!: IOption;
  monthlyValue!: IOption;
  monthlyYearValue!: IOption;

  yearOptions: IOption[] = [];
  quarterOptions: IOption[] = globalQuarterOptions;
  monthOptions: IOption[] = globalMonthOptions;
  customStartDate: FormControl<Date | null> = new FormControl<Date | null>({ value: null, disabled: true });
  customEndDate: FormControl<Date | null> = new FormControl<Date | null>({ value: null, disabled: true });

  @Output() onChange = new EventEmitter<ITimePeriodData>();

  constructor() {}

  ngOnInit(): void {
    var generatedYearOptions = generateCurrentYearOptions(this.numberOfYearsBefore);
    this.yearOptions = generatedYearOptions;

    var currentYear = new Date().getFullYear().toString();
    var currentYearOption: IOption = generatedYearOptions.find(o => o.value === currentYear) || generatedYearOptions[0];

    this.annualYearValue = currentYearOption;
    this.annualYearStartMonthValue = this.monthOptions[0];
    this.quarterlyYearValue = currentYearOption;
    this.quarterlyValue = this.quarterOptions[0];
    this.monthlyYearValue = currentYearOption;
    this.monthlyValue = this.monthOptions[0];

    if (this.timePeriod) {
      const { type, year, period } = this.timePeriod;
      this.timePeriodType = type;
      switch (type) {
        case TimePeriodType.Annual:
          this.annualYearValue =
            generatedYearOptions.find(o => o.value === year?.toString()) || generatedYearOptions[0];
          this.annualYearStartMonthValue =
            this.monthOptions.find(o => o.value === period?.toString()) || this.monthOptions[0];
          break;
        case TimePeriodType.Quarterly:
          this.quarterlyValue = this.quarterOptions.find(o => o.value === period?.toString()) || this.quarterOptions[0];
          this.quarterlyYearValue =
            generatedYearOptions.find(o => o.value === year?.toString()) || generatedYearOptions[0];
          break;
        case TimePeriodType.Monthly:
          this.monthlyValue = this.monthOptions.find(o => o.value === period?.toString()) || this.monthOptions[0];
          this.monthlyYearValue =
            generatedYearOptions.find(o => o.value === year?.toString()) || generatedYearOptions[0];
          break;
        case TimePeriodType.Custom:
          this.customStartDate.setValue(this.timePeriod.customStart || null);
          this.customEndDate.setValue(this.timePeriod.customEnd || null);
          this.customStartDate.enable();
          this.customEndDate.enable();
      }
    }
  }

  selectTimePeriod(timePeriod: TimePeriodType) {
    if (!this.disabled) {
      this.timePeriodType = timePeriod;
      const isCustom = timePeriod === TimePeriodType.Custom;
      if (isCustom) {
        this.customStartDate.enable();
        this.customEndDate.enable();
      } else {
        this.customStartDate.disable();
        this.customEndDate.disable();
      }

      this.updateTimePeriod();
    }
  }

  handleAnnualTimePeriodChange(yearOption: IOption, startMonthOption: IOption) {
    this.annualYearValue = yearOption;
    this.annualYearStartMonthValue = startMonthOption;
    this.updateTimePeriod();
  }

  handleQuarterlyTimePeriodChange(yearOption: IOption, quarterOption: IOption) {
    this.quarterlyYearValue = yearOption;
    this.quarterlyValue = quarterOption;
    this.updateTimePeriod();
  }

  handleMonthTimePeriodChange(yearOption: IOption, monthOption: IOption) {
    this.monthlyYearValue = yearOption;
    this.monthlyValue = monthOption;
    this.updateTimePeriod();
  }

  updateTimePeriod() {
    var timePeriodData = this.getTimePeriodData(this.timePeriodType);
    this.onChange.emit(timePeriodData);
  }

  getTimePeriodData(timePeriod: TimePeriodType): ITimePeriodData {
    switch (timePeriod) {
      case TimePeriodType.Annual:
        return {
          type: TimePeriodType.Annual,
          year: parseInt(this.annualYearValue.value),
          period: parseInt(this.annualYearStartMonthValue.value),
        };
      case TimePeriodType.Quarterly:
        return {
          type: TimePeriodType.Quarterly,
          year: parseInt(this.quarterlyYearValue.value),
          period: parseInt(this.quarterlyValue.value),
        };
      case TimePeriodType.Monthly:
        return {
          type: TimePeriodType.Monthly,
          year: parseInt(this.monthlyYearValue.value),
          period: parseInt(this.monthlyValue.value),
        };
      case TimePeriodType.Custom:
        return {
          type: TimePeriodType.Custom,
          customStart: this.customStartDate.value || undefined,
          customEnd: this.customEndDate.value || undefined,
        };
    }
  }
}
