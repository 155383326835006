import {
  PredefinedIndicator,
  UpdatePredefinedIndicatorCommandParams,
  UpdatePredefinedIndicatorCommandResponse,
  UpdateTextPredefinedIndicatorCommandParams,
} from './../../../../../api-client/report-api.generated';
import { PredefinedIndicatorDetailsUi } from './predefined-indicator-details.component';
import { Injectable, Pipe } from '@angular/core';
import { PredefinedIndicatorApiService } from 'src/app/api-client/report-api/predefined-indicator-api-service';
import {
  DataValuePredefinedIndicator,
  TextPredefinedIndicator,
  UpdateDataValuePredefinedIndicatorCommandParams,
} from 'src/api-client/report-api.generated';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IOption } from 'src/app/shared/ui/dropdown-multiple/dropdown-multiple-form.component';
import { IndicatorDataValueDefinitionApiService } from 'src/app/api-client/report-api/indicator-datavalue-definition-api-service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import _ from 'lodash';

@Injectable()
export class PredefinedIndicatorDetailsStateService {
  predefinedIndicator!: PredefinedIndicatorDetailsUi;
  predefinedIndicatorFcGroup?: FormGroup;
  predefinedIndicatorTypeOptions: IOption[] = [];

  subject = new Subject<UpdatePredefinedIndicatorCommandParams>();

  constructor(
    private predefinedIndicatorApiService: PredefinedIndicatorApiService,
    private indicatorDataValueDefinitionApiService: IndicatorDataValueDefinitionApiService
  ) {
    this.subject
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((body: UpdatePredefinedIndicatorCommandParams) => this.predefinedIndicatorApiService.update(body))
      )
      .subscribe(result => {});
  }

  async init(predefinedIndicatorId: string) {
    const { result } = await this.predefinedIndicatorApiService.getById(predefinedIndicatorId);

    if (result instanceof DataValuePredefinedIndicator) {
      await this.initPredefinedDataValueIndicator(result);
    } else if (result instanceof TextPredefinedIndicator) {
      await this.initPredefinedTextIndicator(result);
    } else {
      throw Error('Lack of typy cast');
    }
  }

  async initPredefinedDataValueIndicator(predefinedIndicator: DataValuePredefinedIndicator) {
    const { result: predefinedIndicatorTypes } =
      await this.indicatorDataValueDefinitionApiService.getAllDataValueTypes();

    this.predefinedIndicatorTypeOptions = _.sortBy(
      predefinedIndicatorTypes.map<IOption>(t => ({ value: t.id, label: t.name })),
      [o => o.label]
    );

    var dataValueOptions = predefinedIndicator.indicatorDataValueDefinitionIds.map<IOption>(typeId => {
      const type = this.predefinedIndicatorTypeOptions.find(t => t.value === typeId);

      if (!type) {
        throw Error("Didn't find a type for id: " + typeId);
      }
      return type;
    });

    this.predefinedIndicator = {
      id: predefinedIndicator.id,
      type: predefinedIndicator.type,
      name: predefinedIndicator.name,
      referenceStandards: new FormControl(predefinedIndicator.referenceStandards, { nonNullable: true }),
      guidance: new FormControl<string>(predefinedIndicator.guidance, { nonNullable: true }),
      dataValues: new FormControl<IOption[]>(dataValueOptions, { nonNullable: true }),
      showSubstrateData: new FormControl<boolean>(predefinedIndicator.showSubstrateData, { nonNullable: true }),
    };
    this.predefinedIndicatorFcGroup = new FormGroup({
      referenceStandards: this.predefinedIndicator.referenceStandards,
      guidance: this.predefinedIndicator.guidance,
      dataValues: this.predefinedIndicator.dataValues,
      showSubstrateData: this.predefinedIndicator.showSubstrateData,
    });

    this.predefinedIndicatorFcGroup.valueChanges.subscribe(change => {
      this.submitDataValuePredefinedIndicator(this.predefinedIndicator);
    });
  }

  async submitDataValuePredefinedIndicator({
    id,
    name,
    referenceStandards,
    guidance,
    dataValues,
    showSubstrateData,
  }: PredefinedIndicatorDetailsUi) {
    if (id) {
      this.subject.next(
        new UpdateDataValuePredefinedIndicatorCommandParams({
          id: id,
          name: name,
          referenceStandards: referenceStandards.value,
          guidance: guidance.value,
          indicatorDataValueDefinitionIds: dataValues.value.map(dv => dv.value),
          showSubstrateData: showSubstrateData.value,
        })
      );
    } else {
    }
  }

  async initPredefinedTextIndicator(predefinedIndicator: TextPredefinedIndicator) {
    this.predefinedIndicator = {
      id: predefinedIndicator.id,
      type: predefinedIndicator.type,
      name: predefinedIndicator.name,
      referenceStandards: new FormControl(predefinedIndicator.referenceStandards, { nonNullable: true }),
      guidance: new FormControl<string>(predefinedIndicator.guidance, { nonNullable: true }),
      dataValues: new FormControl<IOption[]>([], { nonNullable: true }),
      showSubstrateData: new FormControl<boolean>(false, { nonNullable: true }),
    };
    this.predefinedIndicatorFcGroup = new FormGroup({
      referenceStandards: this.predefinedIndicator.referenceStandards,
      guidance: this.predefinedIndicator.guidance,
    });
    this.predefinedIndicatorFcGroup.valueChanges.subscribe(change => {
      this.submitTextPredefinedIndicator(this.predefinedIndicator);
    });
  }

  async submitTextPredefinedIndicator({ id, name, referenceStandards, guidance }: PredefinedIndicatorDetailsUi) {
    if (id) {
      this.subject.next(
        new UpdateTextPredefinedIndicatorCommandParams({
          id: id,
          name: name,
          referenceStandards: referenceStandards.value,
          guidance: guidance.value,
          predefinedText: '',
        })
      );
    } else {
    }
  }
}
