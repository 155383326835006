import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PerformancePageComponent } from './performance-page.component';
import { AppLayoutComponent } from 'src/app/app-layout/app-layout.component';
import { RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { SetTargetDialogComponent } from './kpi-chart/set-target-dialog/set-target-dialog.component';
import { KpiChartComponent } from './kpi-chart/kpi-chart.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SideNavigationComponent } from 'src/app/app-layout/side-navigation/side-navigation.component';

@NgModule({
  declarations: [PerformancePageComponent, SetTargetDialogComponent, KpiChartComponent],
  imports: [
    CommonModule,
    UiModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild([
      {
        path: `performance`,
        component: AppLayoutComponent,
        children: [
          {
            path: ``,
            component: SideNavigationComponent,
            outlet: 'side',
          },
          {
            path: ``,
            component: PerformancePageComponent,
          },
        ],
        canActivate: [MsalGuard],
      },
    ]),
  ],
})
export class PerformanceModule {}
