import { Observable, lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import {
  PredefinedIndicatorClient,
  GetListPredefinedIndicatorsQueryResponse,
  CreatePredefinedIndicatorCommandParams,
  CreatePredefinedIndicatorCommandResponse,
  GetByIdPredefinedIndicatorQueryResponse,
  UpdatePredefinedIndicatorCommandResponse,
  UpdatePredefinedIndicatorCommandParams,
  DeletePredefinedIndicatorCommandResponse,
  UpdatePredefinedIndicatorNameCommandParams,
  UpdatePredefinedIndicatorNameCommandResponse,
} from '../../../api-client/report-api.generated';

@Injectable({
  providedIn: 'root',
})
export class PredefinedIndicatorApiService {
  client: PredefinedIndicatorClient;

  constructor(readonly httpClient: HttpClient, readonly appSettings: AppConfigurationService) {
    this.client = new PredefinedIndicatorClient(httpClient, appSettings.ReportApiBaseUrl);
  }

  async create(body: CreatePredefinedIndicatorCommandParams): Promise<CreatePredefinedIndicatorCommandResponse> {
    const observable$ = this.client.createPredefinedIndicator(body);
    return await lastValueFrom(observable$);
  }

  async updateName(
    body: UpdatePredefinedIndicatorNameCommandParams
  ): Promise<UpdatePredefinedIndicatorNameCommandResponse> {
    const observable$ = this.client.updatePredefinedIndicatorName(body);
    return await lastValueFrom(observable$);
  }
  update(body: UpdatePredefinedIndicatorCommandParams): Observable<UpdatePredefinedIndicatorCommandResponse> {
    return this.client.updatePredefinedIndicator(body);
  }

  async delete(id: string): Promise<DeletePredefinedIndicatorCommandResponse> {
    const observable$ = this.client.deletePredefinedIndicator(id);
    return await lastValueFrom(observable$);
  }

  async getAll(): Promise<GetListPredefinedIndicatorsQueryResponse> {
    const observable$ = this.client.getListPredefinedIndicators();
    return await lastValueFrom(observable$);
  }

  async getById(id: string): Promise<GetByIdPredefinedIndicatorQueryResponse> {
    const observable$ = this.client.getPredefinedIndicatorById(id);
    return await lastValueFrom(observable$);
  }
}
