<div *ngIf="state.customIndicatorFc?.dataValues?.controls" class="wrapper">
  <div *ngFor="let group of state.customIndicatorFc?.dataValues?.controls; let index = index" class="value-row">
    <esg-textbox-form
      *ngIf="group.controls.label"
      class="label"
      label="LABEL"
      placeholder="e.g. Scope 1 GHG Emission"
      [fc]="group.controls.label"></esg-textbox-form>
    <esg-select
      class="unit"
      label="UNIT"
      [value]="group.controls.unit.value || undefined"
      [options]="state.unitOptions"
      [isPopup]="true"
      (onChange)="state.handleDropdownChange(group.controls.unit, $event)">
    </esg-select>
    <button class="delete" [disabled]="index === 0" (click)="state.removeInput(index)">
      <esg-delete-icon color="#666666" hoverColor="#EA1F4C"></esg-delete-icon>
    </button>
  </div>

  <esg-add-button label="Add another input" [showIcon]="false" (onClick)="state.addInput()"></esg-add-button>
</div>
