import { FormControl } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'esg-checkbox-form',
  templateUrl: './checkbox-form.component.html',
  styleUrls: ['./checkbox-form.component.scss'],
})
export class CheckboxFormComponent implements OnInit {
  @Input({ required: true }) fc!: FormControl<boolean>;
  @Input() label?: string;
  @Input() disabled: boolean = false;
  @Output() onCheck = new EventEmitter();

  handleClick(event: Event) {
    event.stopPropagation();
    this.fc.setValue(!this.fc.value);
  }

  constructor() {}

  ngOnInit(): void {}
}
