import {
  TopicItemStatus,
  TopicItemType,
  ReportStatus,
  UserRole,
  VesselType,
  VehicleCraftMachineryType,
  ScrubbersLoopSystem,
  PredefinedIndicatorType,
  ConsumptionValueType,
  DistanceType,
  PdfWritingSystem,
  WasteClass,
  WasteSubClass,
  WasteToDisposal,
  InputType,
  EmployeeCommutingTransport,
  EmployeeCommutingMode,
  Trend,
  TimePeriodType,
  PowerSource,
  EmploymentEvent,
  Gender,
  AgeGroup,
  PositionLevel,
  ParentalLeaveMode,
  EmploymentType,
  Location,
  AssetType,
  FacilityType,
  SupplierStatus,
  RecordDuration,
  MarineCasualtyType,
  ContractType,
} from '../../api-client/report-api.generated';

export interface IOption {
  value: string;
  label: string;
  selected?: boolean;
  id?: string;
  color?: string;
  optionLabel?: string;
  disabled?: boolean;
  group?: string;
}

export interface IGroupedOptions {
  label: string;
  options: IOption[];
}

export function generateCurrentYearOptions(numberOfYearsBefore: number = 1): IOption[] {
  var today = new Date();
  var currentYear = today.getFullYear();
  var yearOptions = [
    { value: currentYear.toString(), label: currentYear.toString() },
    { value: (currentYear + 1).toString(), label: (currentYear + 1).toString() },
  ];

  for (var i = 1; i <= numberOfYearsBefore; i++) {
    var year = currentYear - i;
    yearOptions.unshift({ value: year.toString(), label: year.toString() });
  }

  return yearOptions;
}

export function generateAgeOptions(): IOption[] {
  var today = new Date();
  var currentYear = today.getFullYear();
  var minYear = currentYear - 90;
  var maxYear = currentYear - 15;

  var ageOptions = [];

  for (var year = minYear; year <= maxYear; year++) {
    ageOptions.push({ value: year.toString(), label: year.toString() });
  }

  return ageOptions;
}

export const globalQuarterOptions: IOption[] = [
  { value: '1', label: 'Q1' },
  { value: '2', label: 'Q2' },
  { value: '3', label: 'Q3' },
  { value: '4', label: 'Q4' },
];

export const globalMonthOptions: IOption[] = [
  { value: '1', label: 'January' },
  { value: '2', label: 'February' },
  { value: '3', label: 'March' },
  { value: '4', label: 'April' },
  { value: '5', label: 'May' },
  { value: '6', label: 'June' },
  { value: '7', label: 'July' },
  { value: '8', label: 'August' },
  { value: '9', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
];

export const globalMonthShortOptions: IOption[] = [
  { value: '1', label: 'Jan' },
  { value: '2', label: 'Feb' },
  { value: '3', label: 'Mar' },
  { value: '4', label: 'Apr' },
  { value: '5', label: 'May' },
  { value: '6', label: 'Jun' },
  { value: '7', label: 'Jul' },
  { value: '8', label: 'Aug' },
  { value: '9', label: 'Sep' },
  { value: '10', label: 'Oct' },
  { value: '11', label: 'Nov' },
  { value: '12', label: 'Dec' },
];

export const DurationOptions: IOption[] = [
  { value: RecordDuration.DateFromTo, label: 'Date From-To' },
  { value: RecordDuration.SingleDate, label: 'Single Date' },
  { value: RecordDuration.Period, label: 'Period' },
];

export const IndicatorTypeOptions: IOption[] = [
  { value: TopicItemType.DataValueIndicator, label: 'Data Values' },
  { value: TopicItemType.TextIndicator, label: 'Text' },
];

export const UserRoleOptions: IOption[] = [
  { value: UserRole.User, label: 'User' },
  { value: UserRole.Editor, label: 'Editor' },
  { value: UserRole.Admin, label: 'Admin' },
  { value: UserRole.SuperAdmin, label: 'Super Admin' },
];

export const topicItemStatusOptions: IOption[] = [
  { value: TopicItemStatus.Planned, label: 'Planned', color: '#666666' },
  { value: TopicItemStatus.InProgress, label: 'In Progress', color: '#4F98CC' },
  { value: TopicItemStatus.ToAudit, label: 'To Audit', color: '#E4A445' },
  { value: TopicItemStatus.Published, label: 'Completed', color: '#6BC77F' },
];

export const reportStatusOptions: IOption[] = [
  { value: ReportStatus.Planned, label: 'Planned', color: '#666666' },
  { value: ReportStatus.InProgress, label: 'In Progress', color: '#4F98CC' },
  { value: ReportStatus.ToAudit, label: 'To Audit', color: '#E4A445' },
  { value: ReportStatus.Published, label: 'Published', color: '#6BC77F' },
];

export const paragraphStyleOptions: IOption[] = [
  { value: '', label: 'Body' },
  { value: '2', label: 'Heading' },
];

export const PredefinedIndicatorTypeOptions: { [key: string]: IOption } = {
  [PredefinedIndicatorType.TextPredefinedIndicator]: {
    value: PredefinedIndicatorType.TextPredefinedIndicator,
    label: 'Text',
  },
  [PredefinedIndicatorType.DataValuePredefinedIndicator]: {
    value: PredefinedIndicatorType.DataValuePredefinedIndicator,
    label: 'Data Value',
  },
};

export const PdfWritingSystemOptions: IOption[] = [
  { value: PdfWritingSystem.English, label: 'English (Latin ISO 15924-215)' },
  { value: PdfWritingSystem.Chinese, label: 'Chinese (Han ISO 15924-500)' },
  { value: PdfWritingSystem.Japanese, label: 'Japanese (Japanese ISO 15924-412)' },
  { value: PdfWritingSystem.Korean, label: 'Korean (Hangul ISO 15924-286)' },
  { value: PdfWritingSystem.Thai, label: 'Thai (Thai ISO 15924-352)' },
];

// Data Collect

export const AddAssetOptions: IOption[] = [
  { value: AssetType.Vessel, label: 'Vessel' },
  { value: AssetType.VehicleCraftMachinery, label: 'Vehicles, Craft & Machinery' },
  { value: AssetType.Facility, label: 'Facility' },
  { value: AssetType.Supplier, label: 'Supplier' },
  { value: AssetType.Client, label: 'Client' },
];

export const vesselTypeOptions: IOption[] = [
  { value: VesselType.Offshore, label: 'Offshore' },
  { value: VesselType.PassengerCruise, label: 'Passenger / Cruise' },
  { value: VesselType.Fishery, label: 'Fishery' },
  { value: VesselType.Cargo, label: 'Cargo' },
];

export const vehicleCraftMachineryTypeOptions: IOption[] = [
  { value: VehicleCraftMachineryType.Car, label: 'Car' },
  { value: VehicleCraftMachineryType.Truck, label: 'Truck' },
  { value: VehicleCraftMachineryType.Forklift, label: 'Forklift' },
  { value: VehicleCraftMachineryType.SmallCrafts, label: 'Small Craft' },
  { value: VehicleCraftMachineryType.Cranes, label: 'Crane' },
  { value: VehicleCraftMachineryType.Other, label: 'Other' },
];

export const facilityTypeOptions: IOption[] = [
  { value: FacilityType.Office, label: 'Office' },
  { value: FacilityType.Factory_ProductionFacility_Workshop, label: 'Factory / Production Facility / Workshop' },
  { value: FacilityType.RetailSpace, label: 'Retail Space' },
  { value: FacilityType.Warehouse_Storage, label: 'Warehouse / Storage' },
  { value: FacilityType.Accommodation_Hotel, label: 'Accommodation / Hotel' },
  { value: FacilityType.FoodAndBeverageFacility, label: 'Food & Beverage Facility' },
  { value: FacilityType.AquacultureFarm, label: 'Aquaculture Farm' },
  { value: FacilityType.AgricultureFarm, label: 'Agriculture Farm' },
  { value: FacilityType.EducationalInstitution, label: 'Educational Institution' },
  { value: FacilityType.TransportationHub, label: 'Transportation Hub' },
  { value: FacilityType.RealEstate, label: 'Real Estate' },
  { value: FacilityType.Other, label: 'Other' },
];

export const powerSourceOptions: IOption[] = [
  { value: PowerSource.Fuel, label: 'Fuel' },
  { value: PowerSource.Electric, label: 'Electric' },
  { value: PowerSource.PlugInHybrid, label: 'Plug-in Hybrid' },
  { value: PowerSource.Hydrogen, label: 'Hydrogen' },
];

export const scrubbersLoopSystem: IOption[] = [
  { value: ScrubbersLoopSystem.Closed, label: 'Closed Loop system' },
  { value: ScrubbersLoopSystem.Open, label: 'Open Loop system' },
];

export const SpillTypeOptions: IOption[] = [
  { value: 'Ballast water', label: 'Ballast water' },
  { value: 'Gas', label: 'Gas' },
  { value: 'Fuel', label: 'Fuel' },
  { value: 'Oil', label: 'Oil' },
  { value: 'Chemicals', label: 'Chemicals' },
  { value: 'Bulk cargoes', label: 'Bulk cargoes' },
];

export const ConsumptionValueTypeOptions: IOption[] = [
  { value: ConsumptionValueType.Distance, label: 'Distance' },
  { value: ConsumptionValueType.Fuel, label: 'Fuel' },
];

export const DistanceTypeOptions: IOption[] = [
  { value: DistanceType.Short, label: 'Short' },
  { value: DistanceType.Medium, label: 'Medium' },
  { value: DistanceType.Long, label: 'Long' },
];

export const WasteClassOptions: IOption[] = [
  { value: WasteClass.A_Plastics, label: 'A - Plastics' },
  { value: WasteClass.B_FoodWastes, label: 'B - Food wastes' },
  { value: WasteClass.C_DomesticWastes, label: 'C - Domestic wastes' },
  { value: WasteClass.D_CookingOil, label: 'D - Cooking oil' },
  { value: WasteClass.E_IncineratorAshes, label: 'E - Incinerator ashes' },
  { value: WasteClass.F_OperationalWastes, label: 'F - Operational wastes' },
  { value: WasteClass.G_AnimalCarcass, label: 'G - Animal carcass(es)' },
  { value: WasteClass.H_FishingGear, label: 'H - Fishing gear' },
  { value: WasteClass.I_ElectronicWaste, label: 'I - Electronic waste' },
  { value: WasteClass.J_CargoResidues_NonHarmful, label: 'J - Cargo residues (non-Harmful)' },
  { value: WasteClass.K_CargoResidues_Harmful, label: 'K - Cargo residues (Harmful)' },
];

export const WasteSubClassOptionsByClass: { [key in WasteClass]: IOption[] } = {
  [WasteClass.A_Plastics]: [],
  [WasteClass.B_FoodWastes]: [],
  [WasteClass.C_DomesticWastes]: [
    { value: WasteSubClass.Mixed, label: 'Mixed' },
    { value: WasteSubClass.Paper, label: 'Paper' },
    { value: WasteSubClass.Glass, label: 'Glass' },
    { value: WasteSubClass.Metal, label: 'Metal' },
    { value: WasteSubClass.Wood, label: 'Wood' },
    { value: WasteSubClass.Textile, label: 'Textile' },
  ],
  [WasteClass.D_CookingOil]: [],
  [WasteClass.E_IncineratorAshes]: [],
  [WasteClass.F_OperationalWastes]: [
    { value: WasteSubClass.Mixed, label: 'Mixed' },
    { value: WasteSubClass.MedicalWaste, label: 'Medical waste' },
    { value: WasteSubClass.WastePaint, label: 'Waste Paint' },
    { value: WasteSubClass.ContaminatedMuds_Paint_Rust, label: 'Contaminated muds (paint / rust)' },
    {
      value: WasteSubClass.AbsorbentsContaminatedByHazardiousMaterials,
      label: 'Absorbents contaminated by hazardious materials',
    },
    { value: WasteSubClass.Neon_Bulbs, label: 'Neon/bulbs' },
  ],
  [WasteClass.G_AnimalCarcass]: [],
  [WasteClass.H_FishingGear]: [],
  [WasteClass.I_ElectronicWaste]: [],
  [WasteClass.J_CargoResidues_NonHarmful]: [],
  [WasteClass.K_CargoResidues_Harmful]: [],
};

export function getWasteHandlingOptions(
  wasteClass: WasteClass,
  wasteSubClass: WasteSubClass | undefined = undefined
): IOption[] {
  let recycledDisabled = false;
  let compostedDisabled = true;
  const disposedToSeaDisabled =
    wasteClass !== WasteClass.B_FoodWastes && wasteClass !== WasteClass.J_CargoResidues_NonHarmful;

  if (
    wasteClass === WasteClass.B_FoodWastes ||
    (wasteClass === WasteClass.C_DomesticWastes && wasteSubClass === WasteSubClass.Mixed) ||
    wasteClass === WasteClass.D_CookingOil ||
    wasteClass === WasteClass.E_IncineratorAshes ||
    wasteClass === WasteClass.G_AnimalCarcass
  ) {
    recycledDisabled = true;
    compostedDisabled = false;
  } else if (wasteClass === WasteClass.F_OperationalWastes && wasteSubClass === WasteSubClass.Mixed) {
    compostedDisabled = false;
  }

  return [
    { value: WasteToDisposal.Mixed, label: 'Mixed (Not known)' },
    { value: WasteToDisposal.Recycled, label: 'Recycled', disabled: recycledDisabled },
    { value: WasteToDisposal.Composted, label: 'Composted', disabled: compostedDisabled },
    { value: WasteToDisposal.Incinerated, label: 'Incinerated' },
    { value: WasteToDisposal.Landfilled, label: 'Landfilled' },
    { value: WasteToDisposal.DisposedToSea, label: 'Disposed to Sea', disabled: disposedToSeaDisabled },
    { value: WasteToDisposal.Other, label: 'Other' },
  ];
}

export const ProfileInputOptions: IOption[] = [{ value: InputType.Manual, label: 'Manual Input' }];

export const RecordInputTypeOptions: IOption[] = [
  { value: '', label: 'All inputs' },
  { value: InputType.Manual, label: 'Manual inputs' },
  { value: InputType.ImportedFromExcel, label: 'Imported from excel' },
  { value: InputType.ImportedFrom3rdParty, label: 'Imported from 3rd party' },
];

export const CommutingTransportOptions: IOption[] = [
  { value: EmployeeCommutingTransport.Bus, label: 'Bus' },
  { value: EmployeeCommutingTransport.Subway_Tram_Underground, label: 'Subway/Tram/Underground' },
  { value: EmployeeCommutingTransport.Train, label: 'Train' },
  { value: EmployeeCommutingTransport.Car, label: 'Car' },
  { value: EmployeeCommutingTransport.Motorbike, label: 'Motorbike' },
  { value: EmployeeCommutingTransport.Ferry, label: 'Ferry' },
  { value: EmployeeCommutingTransport.Bicycle, label: 'Bicycle' },
  { value: EmployeeCommutingTransport.ElectricScooter, label: 'ElectricScooter' },
  { value: EmployeeCommutingTransport.Foot, label: 'Foot' },
];

export const CommutingModeByTransport: { [key in EmployeeCommutingTransport]: IOption[] } = {
  [EmployeeCommutingTransport.Bus]: [
    { value: EmployeeCommutingMode.Fuel, label: 'Fuel' },
    { value: EmployeeCommutingMode.Electric, label: 'Electric' },
    { value: EmployeeCommutingMode.TrolleyBus, label: 'TrolleyBus' },
  ],
  [EmployeeCommutingTransport.Subway_Tram_Underground]: [],
  [EmployeeCommutingTransport.Train]: [],
  [EmployeeCommutingTransport.Car]: [
    { value: EmployeeCommutingMode.Fuel, label: 'Fuel' },
    { value: EmployeeCommutingMode.PlugInHybrid, label: 'Plug in Hybrid' },
    { value: EmployeeCommutingMode.Electric, label: 'Electric' },
    { value: EmployeeCommutingMode.Passenger, label: 'Passenger' },
  ],
  [EmployeeCommutingTransport.Motorbike]: [
    { value: EmployeeCommutingMode.Fuel, label: 'Fuel' },
    { value: EmployeeCommutingMode.Electric, label: 'Electric' },
  ],
  [EmployeeCommutingTransport.Ferry]: [
    { value: EmployeeCommutingMode.Car, label: 'Car' },
    { value: EmployeeCommutingMode.Passenger, label: 'Passenger' },
  ],
  [EmployeeCommutingTransport.Bicycle]: [
    { value: EmployeeCommutingMode.NotElectric, label: 'Traditional (not electric)' },
    { value: EmployeeCommutingMode.Electric, label: 'Electric' },
  ],
  [EmployeeCommutingTransport.ElectricScooter]: [],
  [EmployeeCommutingTransport.Foot]: [],
};

export const EmploymentEventOptions: IOption[] = [
  { value: EmploymentEvent.NewHire, label: 'New Hire' },
  { value: EmploymentEvent.Turnover, label: 'Turnover' },
];

export const GenderOptions: IOption[] = [
  { value: Gender.Woman, label: 'Woman' },
  { value: Gender.Man, label: 'Man' },
];

export const LocationOptions: IOption[] = [
  { value: Location.Onshore, label: 'Onshore' },
  { value: Location.Offshore, label: 'Offshore' },
];

export const EmploymentOptions: IOption[] = [
  { value: EmploymentType.FullTime, label: 'Full Time' },
  { value: EmploymentType.PartTime, label: 'Part Time' },
];

export const ContractOptions: IOption[] = [
  { value: ContractType.Permanent, label: 'Permanent' },
  { value: ContractType.Temporary, label: 'Temporary' },
  { value: ContractType.Contractor, label: 'Contractor' },
];

export const AgeGroupOptions: IOption[] = [
  { value: AgeGroup.LessThan30, label: '< 30' },
  { value: AgeGroup.Between30_50, label: '30-50' },
  { value: AgeGroup.Over50, label: '50 >' },
];

export const PositionLevelOptions: IOption[] = [
  { value: PositionLevel.PositionLevel1, label: 'Level 1' },
  { value: PositionLevel.PositionLevel2, label: 'Level 2' },
  { value: PositionLevel.PositionLevel3, label: 'Level 3' },
  { value: PositionLevel.PositionLevel4, label: 'Level 4' },
  { value: PositionLevel.PositionLevel5, label: 'Level 5' },
  { value: PositionLevel.PositionLevel6, label: 'Level 6' },
];

export const ParentalLeaveOptions: IOption[] = [
  { value: ParentalLeaveMode.EntitledTo, label: 'Entitled to' },
  { value: ParentalLeaveMode.ParentLeave, label: 'Parent Leave' },
];

export const MarineCasualtyTypeOptions: IOption[] = [
  { value: MarineCasualtyType.VerySeriousMarineCasualty, label: 'Very Serious Marine Casualty' },
  { value: MarineCasualtyType.MarineCasualty, label: 'Marine Casualty' },
  { value: MarineCasualtyType.SeriousCasualtyWithVessel, label: 'Serious Casualty With Vessel' },
  { value: MarineCasualtyType.OtherVesselAccidents, label: 'Other Vessel Accidents' },
  { value: MarineCasualtyType.PollutionOrRiskOfPollution, label: 'Pollution Or Risk Of Pollution' },
  { value: MarineCasualtyType.OccupationalAccident, label: 'Occupational Accident' },
];

// Performance

export const TargetTrendOptions: IOption[] = [
  { value: Trend.Decrease, label: 'Decrease' },
  { value: Trend.Increase, label: 'Increase' },
];

export const KpisOptions: IOption[] = [
  { value: '44B869EF-C471-4435-9245-52942F3CF965', label: 'Scope 1 GHG Emissions' },
  { value: 'D4153D70-F3E3-4CB1-A71B-D38FD4CBEA87', label: 'Scope 2 GHG Emissions' },
  { value: '46452E86-F3A9-4B05-9CA8-CAC263A023AA', label: 'CII - Offshore Vessels' },
  { value: '51CA055D-FFBB-49CC-B868-F4C04F44DAE3', label: 'CII - Cargo & Passenger Vessels (AER)' },
  { value: '93CA1FA3-D57A-4F07-9954-C449BDEC5EDA', label: 'CII - Ro-Ro/Ro Pax Vessels (cgDist)' },
];

export const KpisTimePeriodTypeOptions: IOption[] = [
  { value: TimePeriodType.Annual, label: 'Annual' },
  { value: TimePeriodType.Quarterly, label: 'Quarter' },
];

export const SupplierStatusOptions: IOption[] = [
  { value: SupplierStatus.Active, label: 'Active' },
  { value: SupplierStatus.Deactivated, label: 'Deactivated' },
  { value: SupplierStatus.Terminated, label: 'Terminated' },
];

export enum AccessibilityTypes {
  NotSet = 'NotSet',
  Global = 'Global',
  Custom = 'Custom',
}

export const AccessibilityOptions: IOption[] = [
  { value: AccessibilityTypes.NotSet, label: 'Not set' },
  { value: AccessibilityTypes.Global, label: 'Global' },
  { value: AccessibilityTypes.Custom, label: 'Custom' },
];
