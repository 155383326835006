<nav>
  <span class="logo-container">
    <a class="logo" [routerLink]="['/']">
      <svg width="60" height="12" viewBox="0 0 60 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M55.9679 12C54.9146 12 53.9561 11.7811 53.0922 11.3432C52.2401 10.8935 51.5596 10.2308 51.0507 9.35503C50.5537 8.46746 50.3052 7.37278 50.3052 6.07101C50.3052 5.11243 50.4531 4.26035 50.749 3.51479C51.0448 2.7574 51.4531 2.11834 51.9738 1.59763C52.4945 1.07692 53.0922 0.680474 53.7667 0.408285C54.4531 0.136095 55.1809 0 55.9501 0C56.7904 0 57.5123 0.147929 58.1158 0.443787C58.7194 0.739645 59.2105 1.09467 59.5892 1.50888L58.2223 3.08876C57.9383 2.8284 57.6247 2.62722 57.2815 2.48521C56.9501 2.33136 56.5359 2.25444 56.0389 2.25444C55.4472 2.25444 54.9206 2.40828 54.459 2.71598C53.9975 3.01183 53.6365 3.43787 53.3762 3.99408C53.1158 4.5503 52.9856 5.21302 52.9856 5.98225C52.9856 6.77515 53.104 7.45562 53.3407 8.02367C53.5892 8.57988 53.9501 9.00592 54.4235 9.30177C54.9087 9.59763 55.5123 9.74556 56.2342 9.74556C56.4709 9.74556 56.7016 9.71598 56.9265 9.65681C57.1513 9.59763 57.3348 9.50888 57.4768 9.39053V7.40237H55.5596V5.27219H59.7845V10.5976C59.3939 10.9882 58.8614 11.3195 58.1868 11.5917C57.5241 11.8639 56.7845 12 55.9679 12Z"
          fill="#4AD1DA" fill-opacity="0.5" />
        <path
          d="M44.8836 12C44.1143 12 43.3569 11.8639 42.6114 11.5917C41.8658 11.3077 41.1972 10.8817 40.6055 10.3136L41.9901 8.60947C42.4043 8.9645 42.8717 9.2426 43.3925 9.44379C43.925 9.64497 44.4457 9.74556 44.9546 9.74556C45.499 9.74556 45.9013 9.64497 46.1617 9.44379C46.4339 9.2426 46.57 8.97041 46.57 8.62722C46.57 8.3787 46.499 8.18343 46.3569 8.04142C46.2268 7.88757 46.0374 7.75148 45.7889 7.63314C45.5404 7.51479 45.2445 7.38462 44.9013 7.2426L43.3925 6.6213C43.0019 6.44379 42.6232 6.21893 42.2564 5.94675C41.9013 5.66272 41.6114 5.31953 41.3865 4.91716C41.1735 4.50296 41.067 4.01183 41.067 3.44379C41.067 2.80473 41.2386 2.23077 41.5818 1.72189C41.9368 1.20118 42.422 0.786983 43.0374 0.47929C43.6646 0.159763 44.3806 0 45.1854 0C45.8836 0 46.5581 0.12426 47.209 0.372781C47.8599 0.621302 48.428 0.982249 48.9132 1.45562L47.5285 3.14201C47.1498 2.85799 46.7593 2.63905 46.3569 2.48521C45.9664 2.33136 45.5759 2.25444 45.1854 2.25444C44.7356 2.25444 44.3747 2.34911 44.1025 2.53846C43.8422 2.71598 43.712 2.97041 43.712 3.30178C43.712 3.52663 43.7889 3.72189 43.9427 3.88757C44.0966 4.04142 44.3096 4.17752 44.5818 4.29586C44.854 4.40237 45.1498 4.52663 45.4694 4.66864L46.9605 5.27219C47.4339 5.46154 47.8362 5.69823 48.1676 5.98225C48.5108 6.26627 48.7771 6.60947 48.9664 7.01183C49.1558 7.40237 49.2504 7.86982 49.2504 8.4142C49.2504 9.05326 49.0788 9.64497 48.7356 10.1893C48.3925 10.7337 47.8954 11.1716 47.2445 11.503C46.5936 11.8343 45.8067 12 44.8836 12Z"
          fill="#4AD1DA" fill-opacity="0.5" />
        <path d="M32 11.866V0.130005H39.344V2.36201H34.646V4.72001H38.642V6.934H34.646V9.634H39.524V11.866H32Z"
          fill="#4AD1DA" fill-opacity="0.5" />
        <path
          d="M8.35707 11.9758V11.6324C8.35452 9.49993 7.50927 7.45551 6.00674 5.94761C4.50421 4.4397 2.46709 3.59144 0.342197 3.58888H0V0H0.342197C3.41135 0.00895657 6.35189 1.23816 8.51986 3.41841C10.6878 5.59867 11.9065 8.55228 11.9091 11.6324V11.9758H8.35707Z"
          fill="#4AD1DA" />
        <path
          d="M16.3526 11.9759V11.6325C16.3485 9.88929 15.7832 8.19412 14.741 6.79957C13.6988 5.40502 12.2355 4.38575 10.5692 3.89366L10.468 3.86463L10.4005 3.78241C9.38796 2.53669 8.14398 1.50017 6.73767 0.730412L5.81714 0.246736L6.85816 0.111307C7.35354 0.0458624 7.85256 0.0119377 8.35221 0.00973511C11.421 0.0199645 14.3608 1.24957 16.5285 3.42955C18.6962 5.60953 19.9153 8.5624 19.9191 11.6422V11.9856L16.3526 11.9759Z"
          fill="#74D6DD" />
        <path
          d="M24.3675 11.9758V11.6324C24.3641 9.88903 23.7991 8.19348 22.7568 6.79877C21.7145 5.40407 20.2508 4.38498 18.584 3.89358L18.478 3.86456L18.4105 3.78233C17.4026 2.54344 16.1654 1.51198 14.767 0.744847L13.8416 0.26117L14.8826 0.125742C15.378 0.0602972 15.877 0.0263725 16.3767 0.0241699C19.4496 0.0280096 22.3956 1.2548 24.5685 3.43547C26.7414 5.61614 27.9638 8.57266 27.9677 11.6566V12L24.3675 11.9758Z"
          fill="#2D8585" />
      </svg>
      <span class="app-version">
        <span class="number">{{ appInfoState.webAppBuildVersion }}</span>
      </span>
    </a>
  </span>
  <div class="actions">
    <ng-container *ngIf="appInfoState.showOrganizationDropdown">
      <esg-select-simple [value]="appInfoState.currentOrganizationOption" [options]="appInfoState.organizationOptions"
        (onChange)="handleSetOrganizationContext($event)">
        <div class="action-button selector" [class.open]="!organizationDropdownCollapsed">
          <span class="action-icon"><esg-company-icon color="white"></esg-company-icon></span>
          <span class="action-text">
            {{ appInfoState.currentOrganizationOption?.label }}
            <esg-arrow-down color="white"></esg-arrow-down>
          </span>
        </div>
      </esg-select-simple>
      <span class="action-divider"></span>
    </ng-container>
    <button class="action-button" [class.active]="assetFormOpen" (click)="handleOpenAssetForm()">
      <span class="action-icon"><esg-asset-icon color="white"></esg-asset-icon></span>
      <span class="action-text">New Asset</span>
    </button>
    <button class="action-button" [class.active]="recordFormOpen" (click)="handleOpenRecordForm()">
      <span class="action-icon"><esg-records-icon color="white"></esg-records-icon></span>
      <span class="action-text">Add Raw Data</span>
    </button>
    <button class="action-button" [class.active]="reportFormOpen" (click)="handleOpenReportForm()">
      <span class="action-icon"><esg-report-icon color="white"></esg-report-icon></span>
      <span class="action-text">Create Report</span>
    </button>
    <span class="action-divider"></span>
    <a class="action-button" routerLinkActive="active" [routerLink]="['/support']">
      <span class="action-icon"><esg-support-icon color="white"></esg-support-icon></span>
      <span class="action-text">Support</span>
    </a>
    <a *ngIf="hasAdminPanelPermission" class="action-button" routerLinkActive="active" [routerLink]="['/admin']">
      <span class="action-icon"><esg-settings-icon color="white"></esg-settings-icon></span>
      <span class="action-text">Settings</span>
    </a>
  </div>
  <div class="user-dropdown">
    <esg-letter-avatar [label]="getInitials"></esg-letter-avatar>
    <esg-context-menu type="custom" [open]="userContextOpen" menuPosition="BottomRight"
      [options]="userContextMenuProvider()" (onCloseOptions)="userContextOpen = false">
      <span class="user-name" [class.open]="userContextOpen" (click)="handleOpenContextMenu($event)">
        {{ appInfoState.userProfileName }}
        <esg-arrow-down color="white"></esg-arrow-down>
      </span>
    </esg-context-menu>
  </div>
</nav>
<esg-asset-form-dialog *ngIf="assetFormOpen" (onCancel)="handleCloseAssetForm()"></esg-asset-form-dialog>
<esg-record-form-dialog *ngIf="recordFormOpen" (onCancel)="handleCloseRecordForm()"></esg-record-form-dialog>
<esg-report-form *ngIf="reportFormOpen" (onClose)="handleCloseReportForm()"
  (onSubmit)="handleCloseReportForm()"></esg-report-form>