<span class="title">Asset versions</span>
<div *ngIf="newVersion" class="version" [class.active]="true">
  <esg-new-icon></esg-new-icon>
  <span class="version-name">{{ newVersion | date: 'd. MMM yyyy' }}</span>
</div>
<div
  *ngFor="let version of assetVersions; let index = index"
  class="version"
  [class.active]="!newVersion && activeVersion === version.id"
  (click)="handleOnVersionClick(version.id)">
  <esg-check-circle-icon *ngIf="index === 0"></esg-check-circle-icon>
  <esg-history-icon *ngIf="index !== 0"></esg-history-icon>
  <span class="version-name">{{ version.value | date: 'd. MMM yyyy' }}</span>
</div>
<div *ngIf="assetVersions.length < 2" class="version no-previous">
  <esg-history-icon></esg-history-icon>
  <span class="version-name">No previous versions</span>
</div>
<esg-add-button
  label="New version"
  size="standard"
  [disabled]="newVersion ? true : false"
  (onClick)="handleOnNewVersionClick($event)"></esg-add-button>
