import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DynamicContent } from './dynamic-content';
import { IMovedContent } from 'src/app/content/dynamic-content-data';
import { ContentDataTypes, ContentStatesTypes, ContentTypes } from './content-configuration';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'esg-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit {
  @Input({ required: true }) contentItems!: DynamicContent[];
  @Input({ required: true }) onChange!: (
    contentId: string,
    states: ContentStatesTypes,
    data?: ContentDataTypes
  ) => void;
  @Input() stickyMenu: boolean = false;
  @Input() loading: boolean = false;
  @Input() stickyTopMargin: number = 0;
  @Input() readMode: boolean = false;
  @Output() onAddContent = new EventEmitter<ContentTypes>();
  @Output() onDeleteContent = new EventEmitter<string>();
  @Output() onMoveContent = new EventEmitter<IMovedContent>();
  @Output() onReorder = new EventEmitter<boolean>();

  removeContentId: string | undefined = undefined;
  removeContentMessage = 'Once you delete this component\nthere is no turning back.';

  reordering: boolean = false;

  get isOrganizeHidden() {
    return this.contentItems.length < 2;
  }

  constructor() {}

  ngOnInit(): void {}

  handleReorder() {
    this.reordering = !this.reordering;
    this.onReorder.emit(this.reordering);
  }

  handleOnDelete() {
    if (this.contentItems.length === 2 && this.reordering) this.handleReorder();
    this.onDeleteContent.emit(this.removeContentId);
    this.removeContentId = undefined;
  }

  handleOnContentDrop(event: CdkDragDrop<any[]>) {
    this.onMoveContent.emit({
      contentId: event.item.element.nativeElement.id,
      prevIndex: event.previousIndex,
      newIndex: event.currentIndex,
    });
  }
}
