<h2 *ngIf="!state.isLoadingTopicItems" class="title">{{ state.topicName }}</h2>

<div *ngIf="!state.isLoadingTopicItems && state.topicTopicItems?.length" class="add-content">
  <esg-context-menu
    type="custom"
    [open]="userContextOpen"
    menuPosition="BottomRight"
    [options]="userContextMenuProvider()"
    (onCloseOptions)="userContextOpen = false">
    <esg-add-button
      label="Add content"
      variant="filled"
      size="standard"
      (onClick)="handleOpenContextMenu($event)"></esg-add-button>
  </esg-context-menu>
</div>

<div *ngIf="state.topicTopicItems" class="list">
  <esg-topic-item-list
    *ngIf="!state.isLoadingTopicItems"
    (onTopicItemClick)="handleSelectTopicItem($event)"></esg-topic-item-list>
  <esg-spinner *ngIf="state.isLoadingTopicItems" class="loader" label="Saving changes"></esg-spinner>
</div>

<esg-dot-flashing
  *ngIf="!state.topicTopicItems && state.isLoadingTopicItems"
  class="loader"
  label="Loading content"></esg-dot-flashing>

<div *ngIf="!state.topicTopicItems?.length && !state.isLoadingTopicItems" class="empty-list">
  <div>There is no content</div>
  <div class="btn-line">
    <span>Please</span>
    <esg-context-menu
      type="custom"
      [open]="userContextOpen"
      menuPosition="BottomRight"
      [options]="userContextMenuProvider()"
      (onCloseOptions)="userContextOpen = false">
      <span class="new-item" (click)="handleOpenContextMenu($event)">add content</span>
    </esg-context-menu>
    .
  </div>
</div>

<ng-container *ngIf="showAddTopicItemDialog">
  <esg-indicator-form
    *ngIf="
      state.selectedType === topicItemType.DataValueIndicator || state.selectedType === topicItemType.TextIndicator
    "
    [reportId]="state.reportId || ''"
    [mainLevelId]="state.mainLevelId || ''"
    [topicId]="state.topicId || ''"
    [selectedTopicItem]="state.selectedTopicItem"
    (onCancel)="handleDialogClose()"
    (onAddSubmit)="handleSubmitAddTopicItem()"
    (onEditSubmit)="handleSubmitEditTopicItem()"></esg-indicator-form>
  <esg-article-form
    *ngIf="state.selectedType === topicItemType.Article"
    [reportId]="state.reportId || ''"
    [mainLevelId]="state.mainLevelId || ''"
    [topicId]="state.topicId || ''"
    [selectedTopicItem]="state.selectedTopicItem"
    (onCancel)="handleDialogClose()"
    (onAddSubmit)="handleSubmitAddTopicItem()"
    (onEditSubmit)="handleSubmitEditTopicItem()"></esg-article-form>
  <esg-new-indicator-form
    *ngIf="state.selectedType === topicItemType.NewIndicator"
    [reportId]="state.reportId || ''"
    [mainLevelId]="state.mainLevelId || ''"
    [topicId]="state.topicId || ''"
    [selectedTopicItem]="state.selectedTopicItem"
    (onCancel)="handleDialogClose()"
    (onAddSubmit)="handleSubmitAddTopicItem()"
    (onEditSubmit)="handleSubmitEditTopicItem()"></esg-new-indicator-form>
  <esg-text-disclosure-form
    *ngIf="state.selectedType === topicItemType.TextDisclosure"
    [reportId]="state.reportId || ''"
    [mainLevelId]="state.mainLevelId || ''"
    [topicId]="state.topicId || ''"
    [selectedTopicItem]="state.selectedTopicItem"
    (onCancel)="handleDialogClose()"
    (onAddSubmit)="handleSubmitAddTopicItem()"
    (onEditSubmit)="handleSubmitEditTopicItem()"></esg-text-disclosure-form>
</ng-container>
<esg-details-dialog
  [open]="showAddKeyFiguresDialog"
  [options]="topicItemMenuProvider()"
  [callToActionText]="state.isSelectedTopicItemArticle ? 'Preview article' : 'Preview indicator'"
  [fade]="state.topicItemDetailsFade"
  (onClose)="handleTopicItemDetailsClose()"
  (onCallToAction)="state.handleClickIndicatorPreview()">
  <esg-topic-item-details
    *ngIf="state.selectedTopicItem"
    [reportId]="state.reportId || ''"
    [topicItem]="state.selectedTopicItem"
    [timePeriod]="state.timePeriod"
    (onReorder)="state.onTopicItemDetailsReorder($event)"
    (onCalculate)="state.setTopicItemDetails(state.selectedTopicItem.id)"></esg-topic-item-details>
</esg-details-dialog>
<esg-confirm-dialog
  *ngIf="showRemoveTopicItemDialog && state.selectedTopicItem"
  [message]="removeTopicItemMessage"
  (onCancel)="handleDialogClose()"
  (onSubmit)="handleDeleteTopicItemSubmit(state.selectedTopicItem.id)"></esg-confirm-dialog>
<esg-dialog
  class="preview-mode"
  *ngIf="state.indicatorPreviewViewModel"
  (onClose)="state.handleCloseIndicatorPreview()">
  <div class="preview-wrapper">
    <esg-indicator-preview
      class="preview-content"
      [indicator]="state.indicatorPreviewViewModel"></esg-indicator-preview>
  </div>
</esg-dialog>
