import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'esg-dynamic-seperator',
  template: `<div class="seperator" [ngStyle]="{ background: bgColor }"></div>`,
  styleUrls: ['./dynamic-seperator.component.scss'],
})
export class DynamicSeperatorComponent implements OnInit {
  @Input() bgColor: string | undefined = '#4ad1da';
  constructor() {}

  ngOnInit(): void {}
}
