import { Content } from 'pdfmake/interfaces';
import { ITopicItems } from './types';
import { IndicatorDataValueType, TimePeriod, TopicItemType } from 'src/api-client/report-api.generated';
import { generateIndicatorKeyFigures, mapKeyFiguresToRow } from './key-figures-table-pdf';
import { generateCalculatedTable } from './calculated-table-pdf';

export const generateTopicItemContent = function (topicItem: ITopicItems, timePeriod?: TimePeriod) {
  let content: Content[] = [];
  const { name, dataValues, substrateData } = topicItem;

  let referenceStandards = topicItem.referenceStandards;
  if (topicItem.type === TopicItemType.NewIndicator) {
    referenceStandards = Array.from(
      new Set(
        topicItem.dataValues?.flatMap(value => value.referenceStandards?.replace(/(\r\n|\n|\r)/gm, '\n').split('\n'))
      )
    ).join('\n');
  }
  const figures = dataValues || [];

  const references = referenceStandards?.replace(/(\r\n|\n|\r)/gm, ', ');

  let tableValues = figures.filter(
    value => value.metadata.type === IndicatorDataValueType.Table && value.metadata.isVisibleInReport
  );
  const numericalValues = figures
    .filter(value => value.metadata.type === IndicatorDataValueType.Numeric && value.metadata.isVisibleInReport)
    .map(value => mapKeyFiguresToRow(value));

  const stack: Content = [{ text: name || '', style: 'topicItemTitle' }];

  if (numericalValues.length) {
    const keyFigure = {
      topicItem: name,
      rows: numericalValues,
      references: referenceStandards,
    };
    stack.push(generateIndicatorKeyFigures(keyFigure, substrateData, timePeriod));
  } else if (tableValues.length) {
    stack.push(generateCalculatedTable(tableValues[0], references, timePeriod));
    tableValues = tableValues.slice(1);
  }

  if (stack.length > 1) {
    content.push({
      unbreakable: true,
      style: 'indicatorStack',
      stack: stack,
    });
  } else {
    content.push({ text: name || '', style: 'topicItemTitle' });
  }

  for (const tableValue of tableValues) {
    content.push(generateCalculatedTable(tableValue, references, timePeriod));
  }
  return content;
};
