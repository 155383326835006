import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { TopicItemType } from 'src/api-client/report-api.generated';
import { IndicatorFormStateService } from './indicator-form-state.service';
import { TopicItemContentUi } from '../topic-item-details/topic-item-details-state.service';
import { IOption } from 'src/app/static-data/options';

export interface IndicatorFormUi {
  name: FormControl<string>;
  description: FormControl<string>;
  references: FormControl<string>;
  indicatorType: FormControl<IOption | undefined>;
  dataValues: FormArray<FormGroup<DataValueUi>>;
  predefinedIndicator: FormControl<IOption | undefined>;
}

export interface DataValueUi {
  label: FormControl<string>;
  unit: FormControl<IOption | undefined>;
  visible?: FormControl<boolean>;
}

@Component({
  selector: 'esg-indicator-form',
  templateUrl: './indicator-form.component.html',
  styleUrls: ['./indicator-form.component.scss'],
  providers: [IndicatorFormStateService],
})
export class IndicatorFormComponent implements OnInit {
  @Input({ required: true }) reportId!: string;
  @Input({ required: true }) mainLevelId!: string;
  @Input({ required: true }) topicId!: string;
  @Input() selectedTopicItem?: TopicItemContentUi;

  @Output() onCancel = new EventEmitter();
  @Output() onAddSubmit = new EventEmitter();
  @Output() onEditSubmit = new EventEmitter();

  get hasPredefinedValues() {
    return this.state.customIndicatorFc?.predefinedIndicator.value?.value;
  }

  get inputTypeIsDisabled() {
    return this.selectedTopicItem || this.hasPredefinedValues ? true : false;
  }

  get isValueType() {
    return this.state.customIndicatorFc?.indicatorType.value?.value === TopicItemType.DataValueIndicator;
  }

  constructor(public state: IndicatorFormStateService) {}

  ngOnInit(): void {
    this.state.initForm(this.selectedTopicItem);
  }

  handleFormSubmit() {
    this.state
      .submitForm(this.reportId, this.mainLevelId, this.topicId, this.selectedTopicItem?.id)
      .then(() => (this.selectedTopicItem ? this.onEditSubmit.emit() : this.onAddSubmit.emit()));
  }
}
