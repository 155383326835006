import { Directive, Input, Output, EventEmitter } from '@angular/core';

export enum DynamicContentMode {
  Read,
  Edit,
  Print,
}

export interface IMovedContent {
  contentId: string;
  prevIndex: number;
  newIndex: number;
}

export interface IContentCommonActions {}

export interface IContentCommonStates {}

export interface IDynamicContentData<TContentActions, TContentStates, TContentData> {
  contentId: string;
  mode: DynamicContentMode;
  actions: TContentActions;
  states: TContentStates;
  data?: TContentData;
  onChange: (contentId: string, states: TContentStates, data?: TContentData) => void;
  style: Object;
  reorderMode: boolean;
  active: boolean;
  onDelete: EventEmitter<string>;
  onSetActive: EventEmitter<boolean>;
}

@Directive()
export abstract class DynamicContentBase<TContentActions, TContentStates, TContentData>
  implements IDynamicContentData<TContentActions, TContentStates, TContentData>
{
  Modes = DynamicContentMode;
  @Input({ required: true }) contentId!: string;
  @Input({ required: true }) mode!: DynamicContentMode;
  @Input({ required: true }) actions!: TContentActions;
  @Input({ required: true }) states!: TContentStates;
  @Input() data?: TContentData;
  @Input() onChange!: (contentId: string, states: TContentStates, data?: TContentData) => void;
  @Input() style: Object = {};
  @Input() reorderMode: boolean = false;
  @Input() active: boolean = false;
  @Output() onDelete = new EventEmitter<string>();
  @Output() onSetActive = new EventEmitter<boolean>();
}
