import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SetTargetDialogStateService } from './set-target-dialog-service.service';
import { FormControl } from '@angular/forms';
import { IOption } from 'src/app/static-data/options';

export interface KpiTargetUi {
  baseYear: FormControl<string>;
  baseYearValue: FormControl<string>;
  targetYear: FormControl<string>;
  targetTrendType: FormControl<IOption>;
  targetPerecentChange: FormControl<string>;
  targetValue: FormControl<string>;
}

@Component({
  selector: 'esg-set-target-dialog',
  templateUrl: './set-target-dialog.component.html',
  styleUrls: ['./set-target-dialog.component.scss'],
  providers: [SetTargetDialogStateService],
})
export class SetTargetDialogComponent {
  @Input({ required: true }) kpiId!: string;
  @Output() onClose = new EventEmitter();
  @Output() onUpdate = new EventEmitter<string>();

  constructor(public state: SetTargetDialogStateService) {}

  ngOnInit() {
    this.state.init(this.kpiId);
  }

  async handleSetTarget() {
    await this.state.setTarget();
    this.onUpdate.emit(this.kpiId);
  }
}
