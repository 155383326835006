import { FormControl } from '@angular/forms';
import { Injectable } from '@angular/core';
import { PredefinedIndicatorApiService } from 'src/app/api-client/report-api/predefined-indicator-api-service';
import { PredefinedIndicatorListItemUi, ViewModeEnums } from './predefined-indicators.component';
import { IOption } from 'src/app/shared/table/table-dropdown/table-dropdown.component';
import { PredefinedIndicatorTypeOptions } from 'src/app/static-data/options';
import {
  CreateDataValuePredefinedIndicatorCommandParams,
  CreateTextPredefinedIndicatorCommandParams,
  PredefinedIndicatorType,
  UpdatePredefinedIndicatorNameCommandParams,
} from 'src/api-client/report-api.generated';
import * as _ from 'lodash';

@Injectable()
export class PredefinedIndicatorsStateService {
  indicators: PredefinedIndicatorListItemUi[] = [];

  editedIndicator?: PredefinedIndicatorListItemUi;

  viewMode?: ViewModeEnums;
  predefinedIndicatorTypeOptions = Object.values(PredefinedIndicatorTypeOptions);
  editedIndicatorNameFormControl = new FormControl<string>('');
  editedIndicatorTypeFormControl = new FormControl<IOption>(this.predefinedIndicatorTypeOptions[1]);

  selectedIndicatorId?: string;
  showStickyDivider = false;

  constructor(private predefinedIndicatorApiService: PredefinedIndicatorApiService) {
    this.init();
  }

  async init() {
    var response = await this.predefinedIndicatorApiService.getAll();

    this.indicators = _.sortBy(response.result, [pi => pi.name.toLowerCase()]).map<PredefinedIndicatorListItemUi>(
      i => ({
        id: i.id,
        name: i.name,
        type: PredefinedIndicatorTypeOptions[i.type],
        lastUpdated: i.lastUpdated,
        editedBy: i.editedBy,
      })
    );
  }

  create() {
    this.viewMode = ViewModeEnums.Create;
  }

  handleStickyDivider(tabEntry: IntersectionObserverEntry) {
    this.showStickyDivider = tabEntry.intersectionRatio === 0;
  }

  submitCreate() {
    if (this.editedIndicatorNameFormControl.value && this.editedIndicatorTypeFormControl.value?.value) {
      switch (this.editedIndicatorTypeFormControl.value?.value) {
        case PredefinedIndicatorType.DataValuePredefinedIndicator:
          this.predefinedIndicatorApiService
            .create(
              new CreateDataValuePredefinedIndicatorCommandParams({
                type: PredefinedIndicatorType.DataValuePredefinedIndicator,
                name: this.editedIndicatorNameFormControl.value,
              })
            )
            .then(response => {
              this.viewMode = undefined;
              this.editedIndicatorNameFormControl.setValue('');
              this.init();
            });
          break;
        case PredefinedIndicatorType.TextPredefinedIndicator:
          this.predefinedIndicatorApiService
            .create(
              new CreateTextPredefinedIndicatorCommandParams({
                type: PredefinedIndicatorType.TextPredefinedIndicator,
                name: this.editedIndicatorNameFormControl.value,
              })
            )
            .then(response => {
              this.viewMode = undefined;
              this.editedIndicatorNameFormControl.setValue('');
              this.init();
            });
          break;
      }
    } else {
      this.viewMode = undefined;
    }
  }

  cancelCreate() {
    this.viewMode = undefined;
  }

  editIndicator(indicator: PredefinedIndicatorListItemUi) {
    this.viewMode = ViewModeEnums.Edit;
    this.editedIndicatorNameFormControl.setValue(indicator.name);
    this.editedIndicatorNameFormControl.markAsDirty();

    this.editedIndicator = { ...indicator };
  }

  async submitEdit() {
    if (!this.editedIndicator || !this.editedIndicatorNameFormControl.value) {
      throw Error('Edited indicator has to be set.');
    }

    if (this.editedIndicator.name !== this.editedIndicatorNameFormControl.value) {
      await this.predefinedIndicatorApiService.updateName(
        new UpdatePredefinedIndicatorNameCommandParams({
          id: this.editedIndicator?.id,
          name: this.editedIndicatorNameFormControl.value,
        })
      );
      this.init();
    }
  }

  clearSelection() {
    this.viewMode = undefined;
    this.editedIndicator = undefined;
    this.editedIndicatorNameFormControl.setValue('');
  }

  selectIndicator(indicatorId: string) {
    this.selectedIndicatorId = indicatorId;
  }

  closeSelectedIndicator() {
    this.selectedIndicatorId = undefined;
  }

  async deleteIndicator() {
    if (this.selectedIndicatorId) {
      await this.predefinedIndicatorApiService.delete(this.selectedIndicatorId);
      this.closeSelectedIndicator();
      this.init();
    }
  }
}
