import { Injectable } from '@angular/core';
import _ from 'lodash';
import {
  CreateIndicatorCommandParams,
  IndicatorCategory,
  SectorType,
  SectorVm,
} from 'src/api-client/report-api.generated';
import { IndicatorDefinitionApiService } from 'src/app/api-client/report-api/indicator-definition-api-service';
import { SectorApiService } from 'src/app/api-client/report-api/sector-api-service';
import { IndicatorsPageStateService } from '../indicators-page-state.service';
import { ObjectArray, toHashTableBy } from 'src/app/shared/utils/object-array';
import { IndicatorApiService } from 'src/app/api-client/report-api/indicator-api-service';

export interface IndicatorDefinitionUi {
  id: string;
  name: string;
  description: string;
  category: IndicatorCategory;
  frameworkStandards: string;
  sectors: SectorType[];
  isAdded: boolean;
}

@Injectable()
export class IndicatorsLibraryStateService {
  indicatorDefinitions: IndicatorDefinitionUi[] = [];
  loading: boolean = false;
  sectorTypes: ObjectArray<SectorVm> = {};

  CategoryColors: { [key in IndicatorCategory]: string } = {
    [IndicatorCategory.Environment]: '#23D468',
    [IndicatorCategory.Social]: '#BD15F8',
    [IndicatorCategory.Governance]: '#03B3FF',
    [IndicatorCategory.Other]: '#183642',
  };

  CategoryLabels: { [key in IndicatorCategory]: string } = {
    [IndicatorCategory.Environment]: 'Environmental',
    [IndicatorCategory.Social]: 'Social',
    [IndicatorCategory.Governance]: 'Governance',
    [IndicatorCategory.Other]: 'Other',
  };

  constructor(
    private indicatorDefinitionsApiService: IndicatorDefinitionApiService,
    private IndicatorApiService: IndicatorApiService,
    private sectorApiService: SectorApiService,
    private menuState: IndicatorsPageStateService
  ) {
    this.init();
  }

  async init() {
    this.loading = true;
    await Promise.all([this.getSectors(), this.getIndicators()]);
    this.loading = false;
  }

  async getIndicators() {
    const response = await this.indicatorDefinitionsApiService.getAll(true);
    this.indicatorDefinitions = _.orderBy(response.result, 'name').map(indicator => ({
      id: indicator.id,
      name: indicator.name,
      description: indicator.description,
      category: indicator.category,
      frameworkStandards: indicator.frameworkStandards,
      sectors: indicator.sectors,
      isAdded: indicator.isAssignedToIndicator,
    }));
    this.menuState.setLibraryIndicatorCount(this.indicatorDefinitions.length);
  }

  async getSectors() {
    const response = await this.sectorApiService.getAll();
    this.sectorTypes = toHashTableBy(response.result, 'type');
  }

  async addIndicatorToOrganization(indicatorId: string) {
    const response = await this.IndicatorApiService.createIndicator(
      new CreateIndicatorCommandParams({ indicatorDefinitionId: indicatorId })
    );
    if (response.success) {
      const indicatorIndex = this.indicatorDefinitions.findIndex(indicator => indicator.id === indicatorId);
      if (indicatorIndex !== -1) {
        this.indicatorDefinitions[indicatorIndex].isAdded = true;
        if (this.menuState.ourIndicatorsCount !== undefined) {
          this.menuState.setOurIndicatorsCount(this.menuState.ourIndicatorsCount + 1);
        }
        const category = this.indicatorDefinitions[indicatorIndex].category;
        if (this.menuState.categoryCounts[category] !== undefined) {
          this.menuState.categoryCounts[category] = this.menuState.categoryCounts[category]! + 1;
          this.menuState.clearRecentlyAddedFlag(category);
          this.menuState.setRecentlyAddedFlag(category);
        }
      }
    }
  }

  getSectorsStringList(sectors: SectorType[]): string {
    if (sectors.length === 0) {
      return 'All';
    } else return sectors.map(sector => this.sectorTypes[sector]?.name || sector).join('\n');
  }
}
