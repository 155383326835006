import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PredefinedIndicatorType } from 'src/api-client/report-api.generated';
import { IOption } from 'src/app/shared/ui/dropdown-multiple/dropdown-multiple-form.component';
import { PredefinedIndicatorDetailsStateService } from './predefined-indicator-details-state.service';

export interface PredefinedIndicatorDetailsUi {
  id?: string;
  type: PredefinedIndicatorType;
  name: string;
  referenceStandards: FormControl<string>;
  guidance: FormControl<string>;
  dataValues: FormControl<IOption[]>;
  showSubstrateData: FormControl<boolean>;
}

@Component({
  selector: 'esg-predefined-indicator-details',
  templateUrl: './predefined-indicator-details.component.html',
  styleUrls: ['./predefined-indicator-details.component.scss'],
  providers: [PredefinedIndicatorDetailsStateService],
})
export class PredefinedIndicatorDetailsComponent implements OnInit {
  @Input({ required: true }) selectedIndicatorId!: string;

  predefinedIndicatorType = PredefinedIndicatorType;

  constructor(public state: PredefinedIndicatorDetailsStateService) {}

  ngOnInit(): void {
    this.state.init(this.selectedIndicatorId);
  }
}
