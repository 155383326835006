<ng-container *ngIf="!state.loading">
  <div class="top-section">
    <div class="records-count">
      Showing
      <span class="bold">{{ state.getDisplayedItemsRange }}</span>
      of
      <span class="bold">{{ state.totalCount }}</span>
      records
    </div>
    <esg-button class="tertiary" (onClick)="state.handleOpenAllRecordsDialog()">
      See all records
      <esg-chevron></esg-chevron>
    </esg-button>
  </div>
  <esg-indicator-record-form-table
    *ngIf="indicatorValueType === state.valueTypeEnum.Numeric"
    [indicatorId]="indicator.id"
    [indicatorRecordDuration]="indicatorRecordDuration"
    [indicatorUnit]="indicatorUnit"
    [indicatorRecords]="state.indicatorRecords"
    [newlyAddedRowsIds]="state.newlyAddedRowsIds"
    [assetOptions]="state.assetOptions"
    [intersectionObserverRootMargin]="state.intersectionObserverRootMargin"
    (onCreate)="state.handleAddRecord($event)"
    (onUpdate)="state.handleUpdateRecord($event)"
    (onDelete)="state.handleDeleteRecord($event)"></esg-indicator-record-form-table>
  <esg-indicator-table-records
    *ngIf="indicatorValueType === state.valueTypeEnum.Table && indicatorTableDefinition"
    [indicatorId]="indicator.id"
    [indicatorRecordDuration]="indicatorRecordDuration"
    [tableDefinition]="indicatorTableDefinition"
    [indicatorRecords]="state.indicatorRecords"
    [newlyAddedRowsIds]="state.newlyAddedRowsIds"
    [assetOptions]="state.assetOptions"
    [currencyOptions]="state.currencyOptions"
    [unitOptions]="state.unitOptions"
    [intersectionObserverRootMargin]="state.intersectionObserverRootMargin"
    (onCreate)="state.handleAddRecord($event)"
    (onUpdate)="state.handleUpdateRecord($event)"
    (onDelete)="state.handleDeleteRecord($event)"></esg-indicator-table-records>
</ng-container>
<esg-dot-flashing *ngIf="state.loading" class="loader" label="Loading records"></esg-dot-flashing>

<esg-all-records-dialog
  *ngIf="state.isAllRecordsDialogOpen"
  [indicator]="indicator"
  [indicatorRecordDuration]="indicatorRecordDuration"
  [indicatorUnit]="indicatorUnit"
  [indicatorValueType]="indicatorValueType"
  [indicatorTableDefinition]="indicatorTableDefinition"
  (onClose)="state.handleCloseAllRecordsDialog()"></esg-all-records-dialog>
