import { Injectable } from '@angular/core';
import {
  DeleteOrganizationCommandParams,
  CreateSubOrganizationCommandParams,
  DeleteSubOrganizationCommandParams,
  UpdateSubOrganizationCommandParams,
  Permission,
} from 'src/api-client/report-api.generated';
import { CountryApiService } from 'src/app/api-client/report-api/country-api-service';
import { OrganizationApiService } from 'src/app/api-client/report-api/organization-api-service';
import { AppInfoService } from 'src/app/core/app-info.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { IOption, UserRoleOptions } from 'src/app/static-data/options';
import {
  IOrganization,
  IOrganizationDetails,
  ISubOrganizationCreate,
  ISubOrganizationDelete,
  ISubOrganizationUpdate,
} from './organizations-types';
import { Subscription } from 'rxjs';

@Injectable()
export class OrganizationsStateService {
  private organizationContextSub!: Subscription;
  protected userInfoSub!: Subscription;

  organizations: IOrganization[] = [];
  loading: boolean = false;
  countryOptions: IOption[] = [];

  selectedOrganization?: IOrganizationDetails;

  hasAddPermission: boolean = false;
  hasEditPermission: boolean = false;
  hasDeletePermission: boolean = false;

  showHasAssetsDialog: boolean = false;

  constructor(
    protected appInfoState: AppInfoService,
    protected organizationApiService: OrganizationApiService,
    protected countryApiService: CountryApiService,
    protected notificationService: NotificationService
  ) {
    this.getPermissions();
    this.initData();
  }

  initData() {
    this.getCountries();

    this.organizationContextSub = this.appInfoState.organizationContext$.subscribe(organizationId => {
      if (organizationId) {
        this.loadOrganizations(organizationId);
      }
    });
  }

  getPermissions() {
    this.userInfoSub = this.appInfoState.userInfo$.subscribe(() => {
      this.hasEditPermission = this.appInfoState.hasPermission(Permission.Report_Organization_Update);
      this.hasDeletePermission = this.appInfoState.hasPermission(Permission.Report_Organization_Delete);
    });
  }

  async loadOrganizations(organizationId?: string) {
    this.loading = true;

    try {
      var result = await this.organizationApiService.getOrganizationsAndSuborganizationsFilteredBy(organizationId);
      this.organizations =
        result.result?.map<IOrganization>(o => ({
          id: o.id,
          name: o.name ?? '',
          orgID: o.orgID ?? '',
          countryCode: o.countryCode,
          countryName: o.countryName,
          currencyCode: o.currencyCode,
          usersCount: o.userCount ?? 0,
          subOrganizations: o.subOrganizations,
          allSubOrganizationsCount: o.allSubOrganizationsCount ?? 0,
          hasAssignedAssets: o.hasAssignedAssets,
        })) || [];
    } catch (error) {
      this.loading = false;
    }
    this.loading = false;
  }

  async loadSpecificOrganization(organizationId: string) {
    const { result } = await this.organizationApiService.getOrganizationsAndSuborganizationsFilteredBy(organizationId);
    if (!result.length) return;

    const organization = {
      id: result[0].id,
      name: result[0].name ?? '',
      orgID: result[0].orgID ?? '',
      countryCode: result[0].countryCode,
      countryName: result[0].countryName,
      currencyCode: result[0].currencyCode,
      usersCount: result[0].userCount ?? 0,
      subOrganizations: result[0].subOrganizations,
      allSubOrganizationsCount: result[0].allSubOrganizationsCount ?? 0,
      hasAssignedAssets: result[0].hasAssignedAssets,
    };
    this.organizations = this.organizations.map<IOrganization>(o => (o.id !== organizationId ? o : organization));
  }

  getCountries() {
    this.countryApiService.getAllCountries().then(result => {
      if (result.success) {
        this.countryOptions = result.result.map(country => ({ value: country.code, label: country.name }));
      }
    });
  }

  async setOrganizationDetails(orgId: string) {
    var { result } = await this.organizationApiService.getOrganizationById(orgId);

    if (!result) {
      throw new Error(`Organization data empty for id: ${orgId}`);
    }

    this.selectedOrganization = {
      id: result.id,
      name: result.name,
      orgID: result.orgID,
      countryCode: result.countryCode,
      countryName: result.countryName,
      currencyCode: result.currencyCode,
      brandColor: result.brandColor,
      tableHeadColor: result.tableHeadColor,
      logo: result.logo,
      logoSquare: result.logo,
      users: result.users.map(u => ({
        id: u.id || '',
        name: u.name || '',
        email: u.email || '',
        role: UserRoleOptions.find(r => r.value === u.role)?.label || '',
      })),
    };
  }

  async deleteOrganization(orgId: string) {
    if (this.hasDeletePermission) {
      const result = await this.organizationApiService.deleteOrganization(
        new DeleteOrganizationCommandParams({ id: orgId })
      );

      if (result.success) {
        this.organizations = this.organizations.filter(org => org.id !== orgId);
      } else {
        this.notificationService.showInfo('', result.message);
      }
    }
  }

  addSubOrganization(organizationId: string, subOrganization: ISubOrganizationCreate) {
    this.organizationApiService
      .createSubOrganization(
        new CreateSubOrganizationCommandParams({
          organizationId: organizationId,
          parentSubOrganizationId: subOrganization.parentSubOrganizationId,
          subOrganizationName: subOrganization.subOrganizationName,
          orgID: subOrganization.orgID,
          countryCode: subOrganization.countryCode,
        })
      )
      .subscribe(async response => {
        if (response.success) {
          this.loadSpecificOrganization(organizationId);
        }
      });
  }

  editSubOrganization(organizationId: string, subOrganization: ISubOrganizationUpdate) {
    this.organizationApiService
      .updateSubOrganization(
        new UpdateSubOrganizationCommandParams({
          organizationId: organizationId,
          subOrganizationId: subOrganization.subOrganizationId,
          subOrganizationName: subOrganization.subOrganizationName,
          orgID: subOrganization.orgID,
          countryCode: subOrganization.countryCode,
        })
      )
      .subscribe(result => {
        if (result.success) {
          this.loadSpecificOrganization(organizationId);
        }
      });
  }

  deleteSubOrganization(organizationId: string, subOrganization: ISubOrganizationDelete) {
    if (subOrganization.hasAssignedAssets) {
      this.showHasAssetsDialog = true;
    } else {
      this.organizationApiService
        .deleteSubOrganization(
          new DeleteSubOrganizationCommandParams({
            organizationId: organizationId,
            subOrganizationId: subOrganization.subOrganizationId,
          })
        )
        .subscribe(result => {
          if (result.success) {
            this.loadSpecificOrganization(organizationId);
          }
        });
    }
  }

  ngOnDestroy() {
    this.organizationContextSub.unsubscribe();
    this.userInfoSub.unsubscribe();
  }
}
