import { Injectable } from '@angular/core';
import _ from 'lodash';
import { IndicatorDataValueDefinition } from 'src/api-client/report-api.generated';
import { IndicatorDataValueDefinitionApiService } from 'src/app/api-client/report-api/indicator-datavalue-definition-api-service';

@Injectable()
export class IndicatorDefinitionsStateService {
  indicatorDefinitions: IndicatorDataValueDefinition[] = [];
  constructor(private indicatorDefinitionsApiService: IndicatorDataValueDefinitionApiService) {
    this.indicatorDefinitionsApiService.getAll().then(response => {
      this.indicatorDefinitions = _.orderBy(response.result, 'name');
    });
  }
}
