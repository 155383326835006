import { Component, Input, OnInit } from '@angular/core';
import { IndicatorDataValueType, TimePeriod, TimePeriodType, TopicItemType } from 'src/api-client/report-api.generated';
import { ReportPreviewStateService } from 'src/app/report-preview/report-preview-state.service';
import { numberToFormattedNumberString } from 'src/app/shared/utils/number-converters';
import { getTimeLabelFromPeriod } from 'src/app/static-data/time-labels';
import { SubstrateDataPreviewUi } from '../substrate-data-preview/substrate-data-preview.component';

export interface IndicatorViewModel {
  id: string;
  name: string;
  type: string;
  timePeriod?: TimePeriod;
  keyFigures?: IKeyfigures[];
  substrateData?: SubstrateDataPreviewUi;
  references?: string | undefined;
  contents?: IContent[];
}

export interface IKeyfigures {
  currentValue: IKeyFigureValue;
  metadata: IKeyFiguresMetaData;
  previousValue: IKeyFigureValue;
  targetValue: IKeyFigureValue;
}

interface IKeyFiguresMetaData extends ISingleKeyFiguresMetaData {
  tableColumnsMetadata?: ISingleKeyFiguresMetaData[];
}

interface ISingleKeyFiguresMetaData {
  label?: string;
  type?: string;
  unit?: string;
  groupLabel?: string;
  isVisibleInReport?: boolean;
}

interface ISingleKeyFigureValue {
  type?: string;
  value?: string | number;
  unitDetails?: string;
}

interface IKeyFigureValue extends ISingleKeyFigureValue {
  tableRows?: ITableKeyFigureValue[];
}

export interface ITableKeyFigureValue {
  values?: ISingleKeyFigureValue[];
}

export interface IContent {
  textHtml: string;
  blobUrl: string;
  altText: string;
  title: string;
  tableConfig?: any;
  rows?: any;
}

@Component({
  selector: 'esg-indicator-preview',
  templateUrl: './indicator-preview.component.html',
  styleUrls: ['./indicator-preview.component.scss'],
})
export class IndicatorPreviewComponent implements OnInit {
  @Input({ required: true }) indicator!: IndicatorViewModel;
  numericalValues?: IKeyfigures[];
  tableValues?: IKeyfigures[];
  constructor(public reportPreviewStateService: ReportPreviewStateService) {}

  get isDataValueIndicator() {
    return (
      this.indicator.type === TopicItemType.DataValueIndicator || this.indicator.type === TopicItemType.NewIndicator
    );
  }

  get isCustomPeriod() {
    return this.indicator.timePeriod?.type === TimePeriodType.Custom;
  }

  ngOnInit(): void {
    this.tableValues = this.indicator.keyFigures?.filter(value => value.metadata.type === IndicatorDataValueType.Table);
    this.numericalValues = this.indicator.keyFigures?.filter(
      value => value.metadata.type !== IndicatorDataValueType.Table
    );
  }

  getFormattedNumber(value: string | number | undefined) {
    return value || value === 0 ? numberToFormattedNumberString(Number(value), 0, 2) : '';
  }

  getFigureTimeLabel(shift: number) {
    if (this.indicator.timePeriod) {
      return getTimeLabelFromPeriod(this.indicator.timePeriod, shift);
    }
    return '';
  }
}
