import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { IOption } from 'src/app/static-data/options';
import { IndicatorTypeOptions } from 'src/app/static-data/options';
import {
  CreateDataValueIndicatorTopicItemCommandParams,
  UpdateDataValueIndicatorTopicItemCommandParams,
  CreateTextIndicatorTopicItemCommandParams,
  UpdateTextIndicatorTopicItemCommandParams,
  PredefinedIndicatorType,
  IndicatorNumericDataValueMetadata,
  DataValuePredefinedIndicator,
  TopicItemType,
  IndicatorDataValueType,
  TextPredefinedIndicator,
} from 'src/api-client/report-api.generated';
import { TopicItemApiService } from 'src/app/api-client/report-api/topic-item-api-service';
import { TopicItemContentUi } from '../topic-item-details/topic-item-details-state.service';
import { PredefinedIndicatorApiService } from 'src/app/api-client/report-api/predefined-indicator-api-service';
import { IndicatorDataValuesDefinitionApiService } from 'src/app/api-client/report-api/indicator-data-values-definition-api-service';
import { DataValueUi, IndicatorFormUi } from './indicator-form.component';
import { UnitService } from 'src/app/shared/services/unit/unit.service';
import { Subscription } from 'rxjs';
import { FormatUnitWithUnicodePipe } from 'src/app/shared/pipes/format-unit-with-unicode.pipe';

interface DefaultIndicatorParams {
  name: string;
  description: string;
  referenceStandards: string;
  type: TopicItemType;
  predefinedIndicatorId: string | undefined;
}

@Injectable()
export class IndicatorFormStateService {
  private unitOptionsSubscription?: Subscription;

  customIndicatorFc?: IndicatorFormUi = undefined;
  formgroup?: FormGroup = undefined;
  predefinedText: string = '';

  indicatorTypeOptions = IndicatorTypeOptions;
  predefinedValueOptions: IOption[] = [{ value: '', label: 'None' }];
  predefinedTextOptions: IOption[] = [{ value: '', label: 'None' }];
  activePredefinedOptions: IOption[] = [{ value: '', label: 'None' }];
  unitOptions: IOption[] = [];

  headerText = 'Create new content';
  submitText = 'Create';

  constructor(
    private topicItemApiService: TopicItemApiService,
    private predefinedIndicatorService: PredefinedIndicatorApiService,
    private valueDefinitionService: IndicatorDataValuesDefinitionApiService,
    private unitService: UnitService,
    private formatUnitWithUnicodePipe: FormatUnitWithUnicodePipe
  ) {
    this.unitOptionsSubscription = this.unitService.units$.subscribe(units => {
      this.unitOptions = Object.values(units).map(unit => ({
        value: unit.key,
        label: this.formatUnitWithUnicodePipe.transform(unit.name) || unit.name,
      }));
    });
  }

  setDefaultForm() {
    this.customIndicatorFc = {
      name: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      description: new FormControl<string>('', { nonNullable: true }),
      references: new FormControl<string>('', { nonNullable: true }),
      indicatorType: new FormControl<IOption | undefined>(this.indicatorTypeOptions[0], {
        nonNullable: true,
        validators: [Validators.required],
      }),
      dataValues: new FormArray<FormGroup<DataValueUi>>([
        new FormGroup<DataValueUi>({
          label: new FormControl('', {
            nonNullable: true,
            validators: [Validators.required],
          }),
          unit: new FormControl(undefined, {
            nonNullable: true,
          }),
        }),
      ]),
      predefinedIndicator: new FormControl<IOption | undefined>(this.activePredefinedOptions[0], {
        nonNullable: true,
      }),
    };
    this.formgroup = new FormGroup(this.customIndicatorFc);
  }

  async initForm(selectedIndicator: TopicItemContentUi | undefined) {
    this.setDefaultForm();
    await this.initPredefinedIndicators();
    this.headerText = selectedIndicator ? 'Edit indicator' : 'Create new indicator';
    this.submitText = selectedIndicator ? 'Save changes' : 'Create';

    const indicatorType = this.indicatorTypeOptions.find(option => option.value === selectedIndicator?.type);
    this.setActivePredefinedOptions(indicatorType?.value || this.indicatorTypeOptions[0].value);

    if (selectedIndicator && this.customIndicatorFc) {
      const predefinedIndicator = this.activePredefinedOptions.find(
        option => option.value === selectedIndicator.predefinedIndicatorId
      );
      this.customIndicatorFc = {
        name: new FormControl<string>(selectedIndicator.name || '', {
          nonNullable: true,
          validators: [Validators.required],
        }),

        description: new FormControl<string>(selectedIndicator.description || '', { nonNullable: true }),
        references: new FormControl<string>(selectedIndicator.referenceStandards || '', { nonNullable: true }),
        indicatorType: new FormControl<IOption | undefined>(indicatorType || this.indicatorTypeOptions[0], {
          nonNullable: true,
          validators: [Validators.required],
        }),
        dataValues: selectedIndicator.dataValues
          ? new FormArray<FormGroup<DataValueUi>>(
              selectedIndicator.dataValues.map(
                value =>
                  new FormGroup<DataValueUi>({
                    label: new FormControl(value.metadata.label || '', {
                      nonNullable: true,
                      validators: [Validators.required],
                    }),
                    unit: new FormControl<IOption | undefined>(
                      this.unitOptions.find(option => option.value === value.metadata.unit) || {
                        value: value.metadata.unit || '',
                        label: value.metadata.unit || '',
                      },
                      { nonNullable: true }
                    ),
                    visible: new FormControl(
                      value.metadata.isVisibleInReport !== undefined ? value.metadata.isVisibleInReport : true,
                      { nonNullable: true }
                    ),
                  })
              )
            )
          : new FormArray<FormGroup<DataValueUi>>([]),
        predefinedIndicator: new FormControl<IOption | undefined>(
          predefinedIndicator || this.activePredefinedOptions[0],
          {
            nonNullable: true,
          }
        ),
      };
      this.formgroup = new FormGroup(this.customIndicatorFc);
    }
  }

  async initPredefinedIndicators() {
    const response = await this.predefinedIndicatorService.getAll();
    this.predefinedValueOptions = [{ value: '', label: 'None' }];
    this.predefinedTextOptions = [{ value: '', label: 'None' }];

    response.result
      .sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      })
      .map(item => {
        if (item.type === PredefinedIndicatorType.DataValuePredefinedIndicator)
          this.predefinedValueOptions.push({ value: item.id, label: item.name });
        else if (item.type === PredefinedIndicatorType.TextPredefinedIndicator)
          this.predefinedTextOptions.push({ value: item.id, label: item.name });
      });
  }

  async submitForm(reportId: string, mainLevelId: string, topicId: string, topicItemId?: string) {
    this.formgroup?.markAsPristine();
    const indicatorType = Object.values(TopicItemType).find(
      type => type === this.customIndicatorFc?.indicatorType?.value?.value
    );
    if (!indicatorType) return;

    const indicatorParams: DefaultIndicatorParams = {
      name: this.customIndicatorFc?.name.value || '',
      description: this.customIndicatorFc?.description.value || '',
      referenceStandards: this.customIndicatorFc?.references.value || '',
      type: indicatorType,
      predefinedIndicatorId: this.customIndicatorFc?.predefinedIndicator.value?.value,
    };
    switch (indicatorType) {
      case TopicItemType.DataValueIndicator:
        const dataValueIndicatorParams = {
          ...indicatorParams,
          dataValueMetadatas:
            this.customIndicatorFc?.dataValues.value.map(
              value =>
                new IndicatorNumericDataValueMetadata({
                  label: value.label || '',
                  unit: value.unit?.value || '',
                  type: IndicatorDataValueType.Numeric,
                  isVisibleInReport: value.visible !== undefined && value.visible !== null ? value.visible : true,
                })
            ) || [],
        };
        if (topicItemId) {
          await this.topicItemApiService.updateTopicItem(
            new UpdateDataValueIndicatorTopicItemCommandParams({
              topicItemId: topicItemId,
              ...dataValueIndicatorParams,
            })
          );
        } else {
          await this.topicItemApiService.addTopicItem(
            new CreateDataValueIndicatorTopicItemCommandParams({
              reportId: reportId,
              mainLevelId: mainLevelId,
              topicId: topicId,
              ...dataValueIndicatorParams,
            })
          );
        }
        break;
      case TopicItemType.TextIndicator:
        const textIndicatorParams = {
          ...indicatorParams,
          text: this.predefinedText,
        };
        if (topicItemId) {
          await this.topicItemApiService.updateTopicItem(
            new UpdateTextIndicatorTopicItemCommandParams({
              topicItemId: topicItemId,
              ...textIndicatorParams,
            })
          );
        } else {
          await this.topicItemApiService.addTopicItem(
            new CreateTextIndicatorTopicItemCommandParams({
              reportId: reportId,
              mainLevelId: mainLevelId,
              topicId: topicId,
              ...textIndicatorParams,
            })
          );
        }
        break;
      default:
        break;
    }
  }

  async handlePredefinedIndicatorChange(option: IOption) {
    if (this.customIndicatorFc) {
      this.handleDropdownChange(this.customIndicatorFc.predefinedIndicator, option);
      this.customIndicatorFc.dataValues = new FormArray<FormGroup<DataValueUi>>([]);
      if (option.value && this.customIndicatorFc.indicatorType.value) {
        const result = await this.predefinedIndicatorService.getById(option.value);
        if (result.success) {
          this.customIndicatorFc?.name.setValue(result.result.name);
          this.customIndicatorFc?.description.setValue(result.result.guidance);
          this.customIndicatorFc?.references.setValue(result.result.referenceStandards);

          switch (true) {
            case result.result instanceof DataValuePredefinedIndicator:
              const indicator = result.result as DataValuePredefinedIndicator;
              this.customIndicatorFc.dataValues = new FormArray<FormGroup<DataValueUi>>(
                await Promise.all(
                  indicator.indicatorDataValueDefinitionIds.map(async id => {
                    const value = await this.valueDefinitionService.getById(id);
                    const metadata = value.result.dataValueMetadata as IndicatorNumericDataValueMetadata;
                    return new FormGroup<DataValueUi>({
                      label: new FormControl(metadata.label, { nonNullable: true, validators: [Validators.required] }),
                      unit: new FormControl<IOption | undefined>(
                        this.unitOptions.find(option => option.value === metadata.unit) || {
                          value: metadata.unit,
                          label: metadata.unit,
                        },
                        { nonNullable: true }
                      ),
                      visible: new FormControl(
                        {
                          value: indicator.indicatorDataValueDefinitionIds.length === 1 || metadata.isVisibleInReport,
                          disabled: indicator.indicatorDataValueDefinitionIds.length === 1,
                        },
                        { nonNullable: true }
                      ),
                    });
                  })
                )
              );
              break;
            case result.result instanceof TextPredefinedIndicator:
              this.predefinedText = (result.result as TextPredefinedIndicator).text || '';
              break;
            default:
              break;
          }
        }
      } else {
        this.customIndicatorFc.name.setValue('');
        this.customIndicatorFc.description.setValue('');
        this.customIndicatorFc.references.setValue('');
        if (this.customIndicatorFc.indicatorType.value?.value === TopicItemType.DataValueIndicator) this.addInput();
        this.formgroup = new FormGroup(this.customIndicatorFc);
      }
    }
  }

  handleIndicatorTypeChange(option: IOption) {
    if (this.customIndicatorFc) {
      this.handleDropdownChange(this.customIndicatorFc.indicatorType, option);
      this.setActivePredefinedOptions(option.value);
      if (option.value === TopicItemType.DataValueIndicator) {
        this.customIndicatorFc.dataValues = new FormArray<FormGroup<DataValueUi>>([
          new FormGroup<DataValueUi>({
            label: new FormControl('', {
              nonNullable: true,
              validators: [Validators.required],
            }),
            unit: new FormControl(undefined, {
              nonNullable: true,
            }),
          }),
        ]);
      } else {
        this.customIndicatorFc.dataValues = new FormArray<FormGroup<DataValueUi>>([]);
      }
    }
  }

  setActivePredefinedOptions(type?: string) {
    switch (type) {
      case TopicItemType.DataValueIndicator:
        this.activePredefinedOptions = this.predefinedValueOptions;
        break;
      case TopicItemType.TextIndicator:
        this.activePredefinedOptions = this.predefinedTextOptions;
        break;
      default:
        this.activePredefinedOptions = [{ value: '', label: 'None' }];
        break;
    }
  }

  addInput() {
    if (this.customIndicatorFc) {
      this.customIndicatorFc.dataValues.push(
        new FormGroup<DataValueUi>({
          label: new FormControl('', {
            nonNullable: true,
            validators: [Validators.required],
          }),
          unit: new FormControl(undefined, {
            nonNullable: true,
          }),
        })
      );
      this.customIndicatorFc.dataValues.markAsDirty();
    }
  }

  removeInput(index: number) {
    if (this.customIndicatorFc) {
      this.customIndicatorFc.dataValues.removeAt(index);
      this.customIndicatorFc.dataValues.markAsDirty();
    }
  }

  handleDropdownChange(formControl: FormControl<IOption | undefined>, option: IOption) {
    if (option !== formControl.value) {
      formControl.setValue(option);
      formControl.markAsDirty();
    }
  }

  ngOnDestroy() {
    if (this.unitOptionsSubscription) {
      this.unitOptionsSubscription.unsubscribe();
    }
  }
}
