export interface INoContentImage {
  header: string;
  text: string;
  src: string;
  width: string;
  height: string;
  alt: string;
}

export const noContentImages: INoContentImage[] = [
  {
    header: 'Great to have you back.',
    text: 'Keep up the good work :-)',
    src: '/assets/images/report/report-welcome.png',
    width: '488.75',
    height: '320',
    alt: 'Two colleagues working together in front of a monitor',
  },
  {
    header: 'Ready to crunch some numbers today, honey?',
    text: 'Please remember: There is no ESG magic, only hard work ;-)',
    src: '/assets/images/report/report-welcome2.png',
    width: '456.37',
    height: '320',
    alt: 'Three colleagues displaying figures on a whiteboard',
  },
  {
    header: 'What’s your story, morning glory?',
    text: 'Great to have you back :-)',
    src: '/assets/images/report/report-welcome3.png',
    width: '515.48',
    height: '320',
    alt: 'Woman stretching after coffee in the morning',
  },
];
