import { Component, Input, OnInit } from '@angular/core';
import { pickTextColorBasedOnBgColor } from 'src/app/shared/utils/colors';

@Component({
  selector: 'esg-dynamic-table-header-cell',
  template: `<div class="box topic" [ngStyle]="{ background: bgColor, color: fontColor }">
    {{ content }}
  </div>`,
  styleUrls: ['./dynamic-table-header-cell.component.scss'],
})
export class DynamicTableHeaderCellComponent implements OnInit {
  @Input() content?: string | undefined = '';
  @Input() bgColor: string | undefined;
  fontColor: string | undefined = 'black';

  constructor() {}

  ngOnInit(): void {
    this.fontColor = this.bgColor ? pickTextColorBasedOnBgColor(this.bgColor) : 'black';
  }
}
