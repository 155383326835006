import { Component, OnInit } from '@angular/core';
import { IndicatorFormStateService } from '../indicator-form-state.service';

@Component({
  selector: 'esg-indicator-form-inputs',
  templateUrl: './indicator-form-inputs.component.html',
  styleUrls: ['./indicator-form-inputs.component.scss'],
})
export class IndicatorFormInputsComponent implements OnInit {
  constructor(public state: IndicatorFormStateService) {}

  ngOnInit(): void {}
}
