<esg-form-dialog
  [submitText]="state.submitText"
  [submitDisabled]="!state.formgroup || !state.formgroup.valid || !state.formgroup.dirty"
  (onCancel)="onCancel.emit()"
  (onSubmit)="handleFormSubmit()">
  <div class="container">
    <header>{{ state.headerText }}</header>

    <div *ngIf="state.customIndicatorFc" class="content indicator">
      <div class="section">
        <span class="section-header"> 1. Choose indicator type </span>
        <esg-select
          [options]="state.indicatorTypeOptions"
          [value]="
            state.customIndicatorFc.indicatorType.value ? state.customIndicatorFc.indicatorType.value : undefined
          "
          [disabled]="inputTypeIsDisabled"
          (onChange)="state.handleIndicatorTypeChange($event)">
        </esg-select>
      </div>
      <div class="section">
        <span class="section-header"> 2. Choose predefined Indicator </span>
        <esg-select
          [options]="state.activePredefinedOptions"
          [value]="
            state.customIndicatorFc.predefinedIndicator.value
              ? state.customIndicatorFc.predefinedIndicator.value
              : undefined
          "
          [isPopup]="true"
          (onChange)="state.handlePredefinedIndicatorChange($event)">
        </esg-select>
      </div>
      <div class="section">
        <span class="section-header"> 3. Add details </span>
        <div class="section-columns">
          <div class="section-column">
            <esg-textbox-form
              label="INDICATOR NAME"
              placeholder="e.g. Ship Recycling"
              [fc]="state.customIndicatorFc.name"></esg-textbox-form>
            <esg-textarea
              label="ADD REFERENCES"
              [showOptionalText]="false"
              placeholder="i.e GRI 306-1"
              [fc]="state.customIndicatorFc.references"></esg-textarea>
          </div>
          <div class="section-column">
            <esg-textarea
              [fc]="state.customIndicatorFc.description"
              label="DESCRIPTION"
              placeholder="Write a short guideance of what to report here"></esg-textarea>
          </div>
        </div>
      </div>
      <div *ngIf="isValueType" class="section">
        <span class="section-header"> 4. Data Values </span>
        <esg-indicator-form-values *ngIf="hasPredefinedValues"></esg-indicator-form-values>
        <esg-indicator-form-inputs *ngIf="!hasPredefinedValues"></esg-indicator-form-inputs>
      </div>
    </div>
  </div>
</esg-form-dialog>
