import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfigurationService } from './core/app-configuration.service';

// Import MSAL and MSAL browser libraries.
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';

// Import the Angular HTTP interceptor.
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AdminModule } from './pages/admin/admin.module';
import { PagesAuthModule } from './pages-auth/layout.module';
import { DataCollectModule } from './pages/data-collect/data-collect.module';
import { AssetsModule } from './pages/assets/assets.module';
import { ReportModule } from './pages/report/report.module';
import { SupportModule } from './pages/support/support.module';
import { HomeModule } from './pages/home/home.module';
import { ReportDetailsModule } from './pages/report-details/report-details.module';
import { UiModule } from './shared/ui/ui.module';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { NotificationModule } from './shared/services/notification/notification.module';
import { ErrorHandlingInterceptor } from './core/error-handling-interceptor';
import { ReportPreviewModule } from './report-preview/report-preview.module';
import { SharedComponentsModule } from './shared/components/shared-components.module';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './auth-config';
import { ExampleModule } from './pages/example/example.module';
import { PerformanceModule } from './pages/performance/performance.module';
import { IndicatorsModule } from './pages/indicators/indicators.module';
import { SideNavigationComponent } from './app-layout/side-navigation/side-navigation.component';
import { NavbarComponent } from './app-layout/navbar/navbar.component';
import { LargeNavbarComponent } from './app-layout/large-navbar/large-navbar.component';

export function initSynchronousFactory(appConfigurationService: AppConfigurationService) {
  return async () => {
    var response = await fetch('app-configuration');
    var result = await response.json();
    appConfigurationService.init(result);
  };
}

@NgModule({
  declarations: [AppComponent, AppLayoutComponent, NavbarComponent, SideNavigationComponent, LargeNavbarComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    DataCollectModule,
    AssetsModule,
    IndicatorsModule,
    ReportModule,
    ReportDetailsModule,
    AdminModule,
    SupportModule,
    HomeModule,
    PerformanceModule,
    ExampleModule,
    AppRoutingModule,
    PagesAuthModule,
    UiModule,
    ReportPreviewModule,
    NotificationModule,
    SharedComponentsModule,
    MsalModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initSynchronousFactory,
      deps: [AppConfigurationService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [AppConfigurationService],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
      deps: [AppConfigurationService],
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [AppConfigurationService],
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlingInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
