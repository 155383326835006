import { Injectable } from '@angular/core';
import { SupportTopicApiService } from 'src/app/api-client/report-api/support-topic-api-service';
import { SupportTopicUi } from './support-page.component';

@Injectable()
export class SupportPageStateService {
  topics: SupportTopicUi[] = [];
  selectedTopic?: string;
  initialized: boolean = false;

  constructor(private supportTopicApiService: SupportTopicApiService) {}

  async init() {
    this.initialized = false;
    await this.getSupportTopics();
    this.initialized = true;
  }

  async getSupportTopics() {
    const topicResponse = await this.supportTopicApiService.all();
    this.topics = topicResponse.result.map(topic => ({
      id: topic.id,
      name: topic.name,
      count: topic.name === 'Tutorials' ? 4 : topic.topicItemIds.length,
    }));
  }
}
