import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ContentTypes } from '../content-configuration';

@Component({
  selector: 'esg-content-menu',
  templateUrl: './content-menu.component.html',
  styleUrls: ['./content-menu.component.scss'],
})
export class ContentMenuComponent implements OnInit {
  @Input() reordering: boolean = false;
  @Input() reorderHidden: boolean = false;
  @Output() onAddContent = new EventEmitter<ContentTypes>();
  @Output() onReorder = new EventEmitter();

  optionsBottom: boolean = true;

  constructor() {}

  ngOnInit(): void {}

  handleAddText(event: MouseEvent) {
    event.stopPropagation();
    this.onAddContent.emit(ContentTypes.Text);
  }

  handleAddImage(event: MouseEvent) {
    event.stopPropagation();
    this.onAddContent.emit(ContentTypes.Picture);
  }

  handleAddImportFromExcel(event: MouseEvent) {
    event.stopPropagation();
    this.onAddContent.emit(ContentTypes.ImportFromExcel);
  }

  handleReorder() {
    this.onReorder.emit();
  }
}
