import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TopicDetailsStateService } from './topic-details-state.service';
import { IMenuItem } from 'src/app/shared/ui/context-menu/context-menu.component';
import { ReportVersion, TopicItemType } from 'src/api-client/report-api.generated';
import { ReportDetailsStateService } from '../report-details-state.service';

@Component({
  selector: 'esg-topic-details',
  templateUrl: './topic-details.component.html',
  styleUrls: ['./topic-details.component.scss'],
})
export class TopicDetailsComponent implements OnInit {
  topicItemType = TopicItemType;
  showAddTopicItemDialog = false;
  showRemoveTopicItemDialog = false;
  showAddKeyFiguresDialog = false;
  removeTopicItemMessage = 'Once you delete the content\nthere is no turning back.';
  topicSub?: Subscription;
  detailsSub?: Subscription;
  userContextOpen = false;

  constructor(
    public state: TopicDetailsStateService,
    private reportState: ReportDetailsStateService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.topicSub = this.route.params.subscribe(params => {
      const selectedTopicId = params['topicid'];
      if (selectedTopicId) {
        this.reportState.clearTopicDetails();
        this.reportState.setTopicDetails(selectedTopicId);
        this.state.clearTopicItems();
        this.reportState.reportData &&
          this.reportState.selectedTopic &&
          this.state.init(
            this.reportState.reportData.id,
            this.reportState.selectedTopic.mainLevelId,
            this.reportState.selectedTopic.id,
            this.reportState.selectedTopic.name,
            this.reportState.reportData.timePeriod
          );
      } else if (!selectedTopicId && this.reportState.selectedTopic) this.reportState.clearTopicDetails();
    });
    this.detailsSub = this.route.fragment.subscribe(selectedTopicItemId => {
      if (selectedTopicItemId) {
        if (selectedTopicItemId !== this.state.selectedTopicItem?.id) {
          this.state.clearTopicItemDetails();
          this.state.selectedTopicItemId = selectedTopicItemId;
          this.showAddKeyFiguresDialog = true;
          this.state.setTopicItemDetails(selectedTopicItemId);
        } else this.showAddKeyFiguresDialog = true;
      } else this.state.clearTopicItemDetails();
    });
  }

  ngOnDestroy(): void {
    this.topicSub?.unsubscribe();
    this.detailsSub?.unsubscribe();
  }

  handleAddTopicItem(selectedType: TopicItemType) {
    this.state.setSelectedContentType(selectedType);
    this.showAddTopicItemDialog = true;
  }

  handleEditTopicItem(selectedType: TopicItemType) {
    this.state.setSelectedContentType(selectedType);
    this.showAddKeyFiguresDialog = false;
    this.showAddTopicItemDialog = true;
  }

  handleDialogClose() {
    this.showAddTopicItemDialog = false;
    this.showRemoveTopicItemDialog = false;
    if (this.state.selectedTopicItemId && this.state.selectedTopicItem) this.showAddKeyFiguresDialog = true;
  }

  handleSubmitAddTopicItem() {
    this.showAddTopicItemDialog = false;
    this.state.loadTopicTopicItems();
  }

  handleSubmitEditTopicItem() {
    this.showAddTopicItemDialog = false;
    this.showAddKeyFiguresDialog = true;
    this.state.loadTopicTopicItems();
    this.state.selectedTopicItemId && this.state.setTopicItemDetails(this.state.selectedTopicItemId);
  }

  handleSelectTopicItem(topicItemId: string) {
    this.router.navigate(['/report', this.state.reportId, this.state.topicId], { fragment: topicItemId });
  }

  handleTopicItemDetailsClose() {
    this.state.clearTopicItemDetails();
    this.showAddKeyFiguresDialog = false;
    this.state.loadTopicTopicItems();
    this.router.navigate(['/report', this.state.reportId, this.state.topicId]);
  }

  handleDeleteTopicItem() {
    this.showRemoveTopicItemDialog = true;
  }

  handleDeleteTopicItemSubmit(topicItemId: string | undefined) {
    if (topicItemId) {
      this.state.removeTopicItem(topicItemId);
      this.showRemoveTopicItemDialog = false;
      this.showAddKeyFiguresDialog = false;
      this.router.navigate(['/report', this.state.reportId, this.state.topicId]);
    }
  }

  handleOpenContextMenu(event: Event) {
    event.stopPropagation();
    this.userContextOpen = !this.userContextOpen;
  }

  userContextMenuProvider = (): IMenuItem[] => {
    return this.reportState.reportData?.reportVersion === ReportVersion.V1
      ? [
          {
            id: 'create',
            label: 'Create Indicator',
            onClick: () => {
              this.handleAddTopicItem(TopicItemType.DataValueIndicator);
              this.userContextOpen = false;
            },
          },
          {
            id: 'add',
            label: 'Add Article',
            onClick: () => {
              this.handleAddTopicItem(TopicItemType.Article);
              this.userContextOpen = false;
            },
          },
        ]
      : [
          {
            id: 'create-new',
            label: 'Add Indicator',
            onClick: () => {
              this.handleAddTopicItem(TopicItemType.NewIndicator);
              this.userContextOpen = false;
            },
          },
          {
            id: 'create-new',
            label: 'Create Text Disclosure',
            onClick: () => {
              this.handleAddTopicItem(TopicItemType.TextDisclosure);
              this.userContextOpen = false;
            },
          },
        ];
  };
  topicItemMenuProvider = (): IMenuItem[] => [
    {
      id: 'edit',
      label: 'Edit',
      onClick: () => this.handleEditTopicItem(this.state.getSelectedContentType() || TopicItemType.Article),
    },
    { id: 'delete', label: 'Delete', onClick: () => this.handleDeleteTopicItem() },
  ];
}
