import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import { CurrencyClient, GetAllCurrenciesQueryResponse } from '../../../api-client/report-api.generated';

@Injectable({
  providedIn: 'root',
})
export class CurrencyApiService {
  client: CurrencyClient;

  constructor(readonly httpClient: HttpClient, readonly appSettings: AppConfigurationService) {
    this.client = new CurrencyClient(httpClient, appSettings.ReportApiBaseUrl);
  }

  async getAllCurrencies(): Promise<GetAllCurrenciesQueryResponse> {
    const observable$ = this.client.getAllCurrencies().pipe(
      catchError(error => {
        console.log(error.errors);
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }
}
