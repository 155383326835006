import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import {
  IndicatorDataValueSeriesClient,
  GetAllIndicatorDataValueSeriesQueryResponse,
  CalculateIndicatorDataValueSeriesCommandResponse,
  GetIndicatorDataValueSeriesByIdQueryResponse,
  UpdateIndicatorDataValueSeriesTargetCommandParams,
  UpdateIndicatorDataValueSeriesTargetCommandResponse,
  GetDataValueSeriesTargetQueryResponse,
  DeleteIndicatorDataValueSeriesCommandResponse,
  CreateDefaultIndicatorDataValueSeriesCommandParams,
  CreateDefaultIndicatorDataValueSeriesCommandResponse,
  UpdateIndicatorDataValueSeriesTimeRangeCommandResponse,
  UpdateIndicatorDataValueSeriesTimeRangeCommandParams,
  TimePeriodType,
} from '../../../api-client/report-api.generated';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IndicatorDataValuesSeriesApiService {
  client: IndicatorDataValueSeriesClient;

  constructor(readonly httpClient: HttpClient, readonly appSettings: AppConfigurationService) {
    this.client = new IndicatorDataValueSeriesClient(httpClient, appSettings.ReportApiBaseUrl);
  }

  async createKpi(
    organizationId: string,
    definitionId: string
  ): Promise<CreateDefaultIndicatorDataValueSeriesCommandResponse> {
    const observable$ = this.client.createDefaultIndicatorDataValueSeries(
      new CreateDefaultIndicatorDataValueSeriesCommandParams({
        organizationId: organizationId,
        indicatorDataValueDefinitionId: definitionId,
      })
    );
    return await lastValueFrom(observable$);
  }
  async updateKpiTimePerdiod(
    id: string,
    fromYear: number,
    toYear: number,
    timePeriodType: TimePeriodType
  ): Promise<UpdateIndicatorDataValueSeriesTimeRangeCommandResponse> {
    const observable$ = this.client.updateIndicatorDataValueSeriesTimeRange(
      new UpdateIndicatorDataValueSeriesTimeRangeCommandParams({
        id,
        fromYear,
        toYear,
        timePeriodType,
      })
    );
    return await lastValueFrom(observable$);
  }
  async getAllForCurrentContextOrganization(): Promise<GetAllIndicatorDataValueSeriesQueryResponse> {
    const observable$ = this.client.getIndicatorDataValueSeriesByCurrentContextOganization();
    return await lastValueFrom(observable$);
  }

  async getById(id: string): Promise<GetIndicatorDataValueSeriesByIdQueryResponse> {
    const observable$ = this.client.getIndicatorDataValueSeriesById(id);
    return await lastValueFrom(observable$);
  }

  async calculate(id: string): Promise<CalculateIndicatorDataValueSeriesCommandResponse> {
    const observable$ = this.client.calculateIndicatorDataValueSeries(id);
    return await lastValueFrom(observable$);
  }

  async remove(id: string): Promise<DeleteIndicatorDataValueSeriesCommandResponse> {
    const observable$ = this.client.deleteIndicatorDataValueSeries(id);
    return await lastValueFrom(observable$);
  }

  async getTarget(id: string): Promise<GetDataValueSeriesTargetQueryResponse> {
    const observable$ = this.client.getIndicatorDataValueSeriesTargetById(id);
    return await lastValueFrom(observable$);
  }

  async updateTarget(
    body: UpdateIndicatorDataValueSeriesTargetCommandParams
  ): Promise<UpdateIndicatorDataValueSeriesTargetCommandResponse> {
    const observable$ = this.client.updateIndicatorDataValueSeriesTarget(body);
    return await lastValueFrom(observable$);
  }
}
