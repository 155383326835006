import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IReport, ReportVersion } from 'src/api-client/report-api.generated';
import { ReportDetailsStateService } from '../report-details-state.service';
import { DeleteMainLevelData } from './main-level-item/main-level-item.component';
import { EditTopicData, DeleteTopicData, DeactivateTopicData } from './topic-item/topic-item.component';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';

@Component({
  selector: 'esg-report-structure',
  templateUrl: './report-structure.component.html',
  styleUrls: ['./report-structure.component.scss'],
})
export class ReportStructureComponent implements OnInit {
  reportVersionEnum = ReportVersion;
  newMainLevel?: FormControl<string | null>;
  @Input({ required: true }) reportData!: IReport;
  @Output() onSelectTopic = new EventEmitter<string>();
  @Output() addMainLevel = new EventEmitter<string>();
  @Output() onCreatePDF = new EventEmitter();
  @Output() onCreateSummaryPDF = new EventEmitter();
  @Output() onCalculateIndicators = new EventEmitter();

  removeMainLevelData?: DeleteMainLevelData = undefined;
  removeMainLevelTitle: string = 'Are you sure you want to\ndelete main topic?';
  removeMainLevelMessage: string =
    'Once you delete this main topic, you delete all its\ncontent and there is no turning back.';

  removeTopicData?: DeleteTopicData = undefined;
  removeTopicTitle: string = 'Are you sure you want to\ndelete topic?';
  removeTopicMessage: string = 'Once you delete this topic, you delete all its\ncontent and there is no turning back.';

  createPdfContextMenuOpen = false;
  templateFormDialogOpen = false;
  reportFormDialogOpen = false;

  hanldeOpenCreatePdfContextMenu(event: Event) {
    event.stopPropagation();
    this.createPdfContextMenuOpen = !this.createPdfContextMenuOpen;
  }
  handleCloseCreatePdfContextMenu() {
    this.createPdfContextMenuOpen = false;
  }

  get useNewMainNavigation() {
    return this.appConfig.NewNavbarEnabled;
  }

  constructor(
    private router: Router,
    public state: ReportDetailsStateService,
    private notificationService: NotificationService,
    private appConfig: AppConfigurationService
  ) {}

  ngOnInit(): void {}

  handleAddMainLevel(event: Event) {
    event.stopPropagation();
    this.newMainLevel = new FormControl<string>('', [Validators.required]);
  }

  handleDeleteMainLevel(mainLevel: DeleteMainLevelData) {
    this.removeMainLevelTitle = 'Are you sure you want to delete ' + mainLevel.name + '?';
    this.removeMainLevelData = mainLevel;
  }

  handleDeleteMainLevelSubmit() {
    if (this.removeMainLevelData) {
      this.state.deleteMainLevel(this.removeMainLevelData.id);
      if (this.removeMainLevelData.id === this.state.selectedTopic?.mainLevelId) {
        this.router.navigate(['/report', this.state.reportData?.id]);
        this.state.selectedTopic = undefined;
      }
      this.removeMainLevelData = undefined;
    }
  }

  handleDeactivateTopic(data: DeactivateTopicData) {
    this.state.deactivateTopic(data);
  }

  handleSelectTopic(topicId: string) {
    this.onSelectTopic.emit(topicId);
  }

  handleSubmitAddNewMainLevel() {
    if (this.newMainLevel?.value) {
      this.addMainLevel.emit(this.newMainLevel?.value);
      this.newMainLevel = undefined;
    } else {
      this.newMainLevel = undefined;
    }
  }

  handleEditTopic(t: EditTopicData) {
    this.state.editTopicName(t.mainLevelId, t.topicId, t.updatedName);
  }

  handleDeleteTopic(t: DeleteTopicData) {
    this.removeTopicTitle = 'Are you sure you want to delete ' + t.topicName + '?';
    this.removeTopicData = t;
  }

  handleDeleteTopicSubmit() {
    if (this.removeTopicData) {
      this.state.deleteTopic(this.removeTopicData.mainLevelId, this.removeTopicData.topicId);
      if (this.removeTopicData.topicId === this.state.selectedTopic?.id) {
        this.router.navigate(['/report', this.state.reportData?.id]);
        this.state.selectedTopic = undefined;
      }

      this.removeTopicData = undefined;
    }
  }

  dropMainLevel(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.reportData.mainLevels || [], event.previousIndex, event.currentIndex);
    this.state.moveMainLevel(
      this.reportData?.mainLevels?.[event.currentIndex].id || '',
      event.previousIndex,
      event.currentIndex
    );
  }

  dropTopic(event: CdkDragDrop<any[]>, mainLevel: any) {
    moveItemInArray(mainLevel.topics || [], event.previousIndex, event.currentIndex);
    this.state.moveTopic(
      mainLevel.topics?.[event.currentIndex].id || '',
      mainLevel.id,
      event.previousIndex,
      event.currentIndex
    );
  }

  handleReportSettings() {
    this.reportFormDialogOpen = true;
  }

  handleCloseReportFormDialog() {
    this.reportFormDialogOpen = false;
  }

  handleSaveReportAsTemplate() {
    this.templateFormDialogOpen = true;
  }

  handleSavedAsTemplateSuccess() {
    this.templateFormDialogOpen = false;
    this.notificationService.showSuccess('Report saved as a template successfully.');
  }

  handleCloseTemplateFormDialog() {
    this.templateFormDialogOpen = false;
  }

  handleHtmlReport() {
    window.open(`/preview/${this.reportData.id}`, '_blank');
  }

  handleCalculateIndicators() {
    this.onCalculateIndicators.emit();
  }

  handleCreatePdfReport() {
    this.onCreatePDF.emit();
    this.createPdfContextMenuOpen = false;
  }

  handleCreatePdfExecutiveSummaryReport() {
    this.onCreateSummaryPDF.emit();
    this.createPdfContextMenuOpen = false;
  }
}
