import { Injectable } from '@angular/core';
import {
  AssetType,
  OrganizationAssetVm,
  UpdateAssetCommandParams,
  Asset,
  CreateAssetCommandParams,
  UpdateAssetBasePropertiesCommandParams,
} from 'src/api-client/report-api.generated';
import { AssetApiService } from 'src/app/api-client/report-api/asset-api-service';
import { IOption } from 'src/app/static-data/options';
import { OrganizationApiService } from 'src/app/api-client/report-api/organization-api-service';
import { AppInfoService } from 'src/app/core/app-info.service';
import { AssetUi } from './asset-list.component';
import { CountryApiService } from 'src/app/api-client/report-api/country-api-service';
import { AssetsMenuStateService } from '../assets-menu/assets-menu-state.service';
import { Subscription } from 'rxjs';
import { SectorApiService } from 'src/app/api-client/report-api/sector-api-service';
import {
  DataRecordOutput,
  ExcelImportValidationMessage,
  FieldConfig,
  ImportDataOutput,
  validateExcelOptionColumn,
} from 'src/app/shared/components/data-import-from-excel/data-import-from-excel-state.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { AssetCategoryRoutes } from '../assets-page.component';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';

@Injectable()
export class AssetListStateService {
  private assetFromDialogSub!: Subscription;

  assetDetailsOpen: boolean = false;
  removeDialogOpen: boolean = false;
  assetCategoryRoutes = AssetCategoryRoutes;
  assets: AssetUi[] = [];
  assetType?: AssetType;
  selectedAsset?: AssetUi;
  organizationId?: string;
  organizationOptions: IOption[] = [];
  countryOptions: IOption[] = [];
  sectorOptions: IOption[] = [];
  organizationCountryMap = new Map<string, string>();
  loading: boolean = false;
  updating: boolean = false;

  excelTitle = 'Assets';
  importFromExcelEnabled = false;
  importFromExcelDialog = false;
  importingInProgress = false;
  importingInProgressLabel = 'Importing...';
  importFromExcelFieldConfigs: FieldConfig[] = [
    { propertyName: 'name', title: 'Vessel', type: 'string' },
    { propertyName: 'organization', title: 'Organization', type: 'string' },
    { propertyName: 'versionValidFrom', title: 'Version valid from', type: 'date', optional: true },
  ];

  defaultVersionDate: Date = new Date(Date.UTC(2020, 0, 1));

  get useNewMainNavigation() {
    return this.appConfig.NewNavbarEnabled;
  }

  constructor(
    protected appInfo: AppInfoService,
    protected appConfig: AppConfigurationService,
    protected assetApiService: AssetApiService,
    protected countryApiService: CountryApiService,
    protected organizationApiService: OrganizationApiService,
    protected sectorApiService: SectorApiService,
    protected notificationService: NotificationService,
    public assetsMenuState: AssetsMenuStateService
  ) {
    this.assetFromDialogSub = this.assetsMenuState.newAssetSubject$.subscribe(type => {
      this.handleNewAssetFromDialog(type);
    });
  }

  async initByOrganization(organizationId: string) {
    this.loading = true;
    this.organizationId = organizationId;
    await Promise.all([this.getAssets(), this.getOrganizationList(), this.getCountries(), this.getSectors()]);
    this.loading = false;
  }

  async getAssets() {
    const response = await this.assetApiService.getAssetsByOrganizationAndType(undefined, AssetType.Organization);
    const result = response.result as OrganizationAssetVm[];
    this.assets = result.map(asset => ({
      id: asset.id,
      name: asset.name,
      organizationName: asset.orgOrSuborgName,
      versionValidFrom: asset.versionValidFrom,
      recordsCount: asset.recordCount,
      firstRecordDate: asset.firstRecordDate,
      lastRecordDate: asset.lastRecordDate,
    }));
  }

  async getOrganizationList() {
    if (this.organizationId) {
      const response = await this.organizationApiService.getSubOrganizationList(this.organizationId);
      this.organizationOptions = response.result.map(org => {
        this.organizationCountryMap.set(
          org.subOrganizationId ? org.subOrganizationId : org.organizationId,
          org.countryCode
        );

        return {
          value: org.subOrganizationId ? org.subOrganizationId : org.organizationId,
          label: org.name,
        };
      });
    }
  }

  async getCountries() {
    const response = await this.countryApiService.getAllCountries();
    this.countryOptions = response.result.map(country => ({ value: country.code, label: country.name }));
  }

  async getSectors() {
    const response = await this.sectorApiService.getAll();
    this.sectorOptions = response.result.map(s => ({ value: s.type, label: s.name }));
  }

  async formSubmit(params: CreateAssetCommandParams) {
    if (this.selectedAsset) {
      const selected = this.selectedAsset;
      this.setSelectedAsset(undefined);

      const updateParams = new UpdateAssetBasePropertiesCommandParams({
        id: selected.id,
        name: params.name,
        subOrganizationId: params.subOrganizationId,
      });
      const response = await this.assetApiService.updateAssetBaseProperties(updateParams);
      if (response.success) {
        this.getAssets();
      } else {
        this.notificationService.showError(
          `Unable to update ${this.assetType}`,
          response.validationErrors?.join('\n') || response.message || 'Unknown error'
        );
      }
    } else {
      const createParams = new CreateAssetCommandParams({
        name: params.name,
        organizationId: this.organizationId || '',
        subOrganizationId: params.subOrganizationId,
        versionValidFrom: this.defaultVersionDate,
      });
      this.updating = true;
      const response = await this.assetApiService.createAsset(createParams);
      if (response.success) {
        await this.getAssets();
        this.assetsMenuState.loadAssetTypeCounters();
      } else {
        this.notificationService.showError(
          `Unable to add ${this.assetType}`,
          response.validationErrors?.join('\n') || response.message || 'Unknown error'
        );
      }
      this.updating = false;
    }
  }

  async getNewestAssetVersionId(assetId: string) {
    const { result } = await this.assetApiService.getAssetVersions(assetId);

    if (!result || !result.length) {
      return undefined;
    }
    const assetVersions = result.sort((a, b) => b.versionValidFrom.getTime() - a.versionValidFrom.getTime());
    return assetVersions[0];
  }

  setSelectedAsset(asset?: AssetUi) {
    this.selectedAsset = asset;
  }

  getAssetDetails(asset: AssetUi) {
    this.setSelectedAsset(asset);
    this.assetDetailsOpen = true;
  }

  handleCloseAssetDetails() {
    this.setSelectedAsset(undefined);
    this.assetDetailsOpen = false;
    this.getAssets();
  }

  handleInitateDelete() {
    this.removeDialogOpen = true;
  }

  handleCloseDeleteDialog() {
    this.removeDialogOpen = false;
    if (!this.assetDetailsOpen) {
      this.setSelectedAsset(undefined);
    }
  }

  async handleDeleteAssetSubmit(id: string) {
    this.removeDialogOpen = false;
    this.assetDetailsOpen = false;
    this.setSelectedAsset(undefined);
    const response = await this.assetApiService.deleteAsset(id);
    if (response.success) {
      this.assets = this.assets.filter(asset => asset.id !== id);
    }
  }

  async handleNewAssetFromDialog(type: AssetType) {
    if (type === this.assetType) {
      this.updating = true;
      await this.getAssets();
      this.updating = false;
    }
  }

  async importDataFromExcel(data: ImportDataOutput) {
    this.importingInProgress = true;
    this.importingInProgressLabel = 'Importing...';
    const validationMessages = this.validateOptionColumns(data.records);

    if (validationMessages.length) {
      validationMessages.forEach(message => {
        this.notificationService.showError(message.title, message.description);
      });
      this.importingInProgress = false;
      return;
    }

    var recordsToAdd = data.records.length;
    var recordsAdded = 0;
    this.importingInProgressLabel = `Imported ${recordsAdded} of ${recordsToAdd}`;
    try {
      const addRecordPromises = data.records.map(async (record, index) => {
        const result = await this.createAssetFromExcel(record);
        if (!result.success) {
          this.notificationService.showError(
            `${this.assetType} from row ${index + 1} not added`,
            result.validationErrors?.join('\n') || result.message || 'Unknown error'
          );
        }
        ++recordsAdded;
        this.importingInProgressLabel = `Imported ${recordsAdded} of ${recordsToAdd}`;
        return result;
      });

      await Promise.all(addRecordPromises);

      await this.getAssets();
      this.importingInProgress = false;
      this.importingInProgressLabel = '';
      this.importDataFromExcelClose();
    } catch (e) {
      this.importingInProgress = false;
      this.importingInProgressLabel = '';
    }
  }

  importDataFromExcelClose() {
    this.importFromExcelDialog = false;
  }

  handleOnImportAssets() {
    this.importFromExcelDialog = true;
  }

  createAssetFromExcel(asset: DataRecordOutput) {
    const organizationOption = this.getExcelOptionColumn(
      'organization',
      this.organizationOptions,
      asset,
      'Organization'
    );

    const body = new CreateAssetCommandParams({
      name: asset.name?.value || '',
      organizationId: this.organizationId || '',
      subOrganizationId: this.organizationId === organizationOption.value ? undefined : organizationOption.value,
      versionValidFrom: asset.versionValidFrom?.value || this.defaultVersionDate,
    });
    return this.assetApiService.createAsset(body);
  }

  validateOptionColumns(records: DataRecordOutput[]): ExcelImportValidationMessage[] {
    let validationMessages: ExcelImportValidationMessage[] = [];
    validationMessages = validateExcelOptionColumn(
      validationMessages,
      'organization',
      this.organizationOptions,
      records,
      'Organization'
    );
    return validationMessages;
  }

  getExcelOptionColumn(
    propertyName: string,
    options: IOption[],
    record: DataRecordOutput,
    optionLabel: string
  ): IOption {
    const valueInUpperCase = record[propertyName]?.value?.trim().toUpperCase();
    const findOption = options.find(
      o => o.label.toUpperCase() === valueInUpperCase || o.value.toUpperCase() === valueInUpperCase
    );

    if (!findOption) {
      this.notificationService.showError(
        'Not mapped select option',
        `Not mapped select option for ${optionLabel} column in ${record[propertyName]?.rowNo} row`
      );
      throw Error();
    }
    return findOption;
  }

  ngOnDestroy() {
    this.assetFromDialogSub.unsubscribe();
  }
}
