<div class="tab-container" [class.single]="tabs.length === 1" [style.paddingLeft.px]="tabIndent">
  <ng-container *ngFor="let tab of tabs">
    <a
      *ngIf="tab.link"
      class="tab-item"
      [class.disabled]="tab.disabled"
      [routerLink]="tab.link"
      routerLinkActive="active"
      (click)="handleOnTabClick(tab.value)"
      >{{ tab.label ? tab.label : tab.value }}
      <span *ngIf="tabs.length > 1" class="tab-underline"></span>
    </a>
    <div
      *ngIf="!tab.link"
      class="tab-item"
      [class.disabled]="tab.disabled"
      [class.active]="tab.value === activeTab"
      (click)="handleOnTabClick(tab.value)">
      {{ tab.label ? tab.label : tab.value }}
      <span *ngIf="tabs.length > 1" class="tab-underline"></span>
    </div>
  </ng-container>
</div>
