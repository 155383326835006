import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-paperplane-icon',
  template: `<svg
    attr.width="{{ size }}"
    attr.height="{{ size }}"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.02826 13.0769C1.08578 12.8323 1.20082 12.5542 1.37337 12.2539L3.13344 9.08434C3.24848 8.88416 3.36351 8.7507 3.46705 8.66173C3.58208 8.58388 3.73163 8.5394 3.91569 8.52827L14.0274 8.16127C14.0274 8.16127 14.131 8.13903 14.1655 8.11678C14.2 8.08342 14.2115 8.03893 14.2115 7.99445C14.2115 7.94996 14.1885 7.90548 14.154 7.87211C14.1195 7.83875 14.0735 7.82763 14.0159 7.82763L3.90419 7.49398C3.70862 7.49398 3.54757 7.43838 3.44404 7.36053C3.34051 7.27156 3.22547 7.1381 3.12194 6.93791L1.33886 3.69046C1.17781 3.40131 1.07428 3.14551 1.01676 2.90084C0.959241 2.66729 1.05127 2.43374 1.26984 2.22244C1.4424 2.06674 1.66097 1.98889 1.91405 2.00001C2.17863 2.00001 2.44322 2.07786 2.71931 2.20019L14.062 7.09361C14.2115 7.16034 14.3495 7.22707 14.4761 7.30492C14.6026 7.38277 14.6947 7.4495 14.7752 7.52735C14.9362 7.67193 15.0053 7.83875 15.0053 8.00557C15.0053 8.18351 14.9247 8.33921 14.7752 8.49491C14.6947 8.57276 14.5911 8.65061 14.4761 8.71734C14.361 8.78407 14.223 8.8508 14.0735 8.91752L2.78833 13.7776C2.46623 13.911 2.17863 13.9889 1.92555 14C1.66097 14.0111 1.4539 13.9333 1.28134 13.7665C1.06277 13.5552 0.982248 13.3216 1.03977 13.0658L1.02826 13.0769Z"
      attr.fill="{{ color }} " />
  </svg> `,
  styles: [],
  host: {
    '[style.width]': 'sizePx',
    '[style.height]': 'sizePx',
  },
})
export class PaperplaneIconComponent {
  @Input() size: string = '16';
  @Input() color: string = '#183642';

  sizePx = this.size + 'px';
}
