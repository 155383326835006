import { Component } from '@angular/core';
import { IKpiSeries, PerformancePageStateService } from './performance-page-state.service';
import { IMenuItem } from 'src/app/shared/ui/context-menu/context-menu.component';
import { numberToFormattedNumberString } from 'src/app/shared/utils/number-converters';

@Component({
  selector: 'esg-performance-page',
  templateUrl: './performance-page.component.html',
  styleUrls: ['./performance-page.component.scss'],
  providers: [PerformancePageStateService],
})
export class PerformancePageComponent {
  constructor(public state: PerformancePageStateService) {}

  ngOnInit() {
    this.state.init();
  }

  chartTrackBy(index: number, chart: IKpiSeries) {
    return chart.id;
  }
}
