import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminPageComponent } from './admin-page.component';
import { RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { ReactiveFormsModule } from '@angular/forms';
import { OrganizationsComponent } from './organizations/organizations.component';
import { UsersComponent } from './users/users.component';
import { ProfileComponent } from './profile/profile.component';
import { UiModule } from '../../shared/ui/ui.module';
import { OrganizationFormComponent } from './organizations/organization-form/organization-form.component';
import { OrganizationListComponent } from './organizations/organization-list/organization-list.component';
import { OrganizationDetailsComponent } from './organizations/organization-details/organization-details.component';
import { UserFormComponent } from './users/user-form/user-form.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { UserDetailsComponent } from './users/user-details/user-details.component';
import { AppLayoutComponent } from 'src/app/app-layout/app-layout.component';
import { LabelValueComponent } from './profile/label-value/label-value.component';
import { OrganizationTbodyComponent } from './organizations/organization-list/organization-tbody/organization-tbody.component';
import { SubOrganizationRowComponent } from './organizations/organization-list/sub-organization-row/sub-organization-row.component';
import { SubOrganizationFormComponent } from './organizations/organization-list/sub-organization-form/sub-organization-form.component';
import { PredefinedIndicatorsComponent } from './predefined-indicators/predefined-indicators.component';
import { TableModule } from 'src/app/shared/table/table.module';
import { PredefinedIndicatorDetailsComponent } from './predefined-indicators/predefined-indicator-details/predefined-indicator-details.component';
import { DirectiveModule } from 'src/app/shared/directives/directive.module';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { DataCollectCategoriesComponent } from './data-collect-categories/data-collect-categories.component';
import { DataCollectCategoryDetailsComponent } from './data-collect-categories/data-collect-category-details/data-collect-category-details.component';
import { SuperAdminGuard } from '../../core/guards/super-admin-guard';
import { AdminGuard } from '../../core/guards/admin-guard';
import { AllOrganizationsComponent } from './organizations/all-organizations/all-organizations.component';
import { AllUsersComponent } from './users/all-users/all-users.component';
import { RegionsComponent } from './regions/regions.component';
import { KeyboardShortcutsDirective } from 'src/app/shared/directives/keyboard-shortcuts.directive';
import { AdminIndicatorLibraryComponent } from './admin-indicator-library/admin-indicator-library.component';
import { IndicatorDefinitionDetailsComponent } from './admin-indicator-library/indicator-definition-details/indicator-definition-details.component';
import { AccessTokenPageComponent } from './access-token/access-token-page.component';
import { IndicatorDefinitionTableComponent } from './admin-indicator-library/indicator-definition-table/indicator-definition-table.component';
import { FormatUnitWithUnicodePipe } from 'src/app/shared/pipes/format-unit-with-unicode.pipe';
import { UnitsComponent } from './units/units.component';
import { FormatUnitWithHtmlPipe } from 'src/app/shared/pipes/format-unit-with-html.pipe';

@NgModule({
  declarations: [
    AdminPageComponent,
    OrganizationsComponent,
    AllOrganizationsComponent,
    UsersComponent,
    AllUsersComponent,
    ProfileComponent,
    OrganizationFormComponent,
    OrganizationListComponent,
    OrganizationDetailsComponent,
    UserFormComponent,
    UserListComponent,
    UserDetailsComponent,
    LabelValueComponent,
    OrganizationTbodyComponent,
    SubOrganizationRowComponent,
    SubOrganizationFormComponent,
    PredefinedIndicatorsComponent,
    PredefinedIndicatorDetailsComponent,
    DataCollectCategoriesComponent,
    DataCollectCategoryDetailsComponent,
    RegionsComponent,
    AdminIndicatorLibraryComponent,
    IndicatorDefinitionDetailsComponent,
    AccessTokenPageComponent,
    IndicatorDefinitionTableComponent,
    UnitsComponent,
  ],
  imports: [
    CommonModule,
    UiModule,
    SharedComponentsModule,
    ReactiveFormsModule,
    TableModule,
    DirectiveModule,
    KeyboardShortcutsDirective,
    FormatUnitWithUnicodePipe,
    FormatUnitWithHtmlPipe,
    RouterModule.forChild([
      {
        path: `admin`,
        component: AppLayoutComponent,
        canActivate: [MsalGuard],
        children: [
          {
            path: ``,
            component: AdminPageComponent,
            children: [
              { path: '', redirectTo: 'profile', pathMatch: 'full' },
              { path: 'access-token', component: AccessTokenPageComponent },
              { path: 'profile', component: ProfileComponent },
              { path: 'organization', component: OrganizationsComponent, canActivate: [AdminGuard] },
              { path: 'regions', component: RegionsComponent, canActivate: [AdminGuard] },
              { path: 'users', component: UsersComponent, canActivate: [AdminGuard] },
              { path: 'all-organizations', component: AllOrganizationsComponent, canActivate: [SuperAdminGuard] },
              { path: 'all-users', component: AllUsersComponent, canActivate: [SuperAdminGuard] },
              {
                path: 'predefined-indicators',
                component: PredefinedIndicatorsComponent,
                canActivate: [SuperAdminGuard],
              },
              { path: 'categories', component: DataCollectCategoriesComponent, canActivate: [SuperAdminGuard] },
              { path: 'indicator-library', component: AdminIndicatorLibraryComponent, canActivate: [SuperAdminGuard] },
              { path: 'units', component: UnitsComponent, canActivate: [SuperAdminGuard] },
            ],
          },
        ],
      },
    ]),
  ],
})
export class AdminModule {}
