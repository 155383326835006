<table>
  <thead>
    <tr>
      <th>NAME</th>
      <th>UNIT</th>
      <th>VISIBLE IN REPORT</th>
    </tr>
  </thead>
  <tbody *ngIf="state.customIndicatorFc?.dataValues?.controls">
    <tr *ngFor="let group of state.customIndicatorFc?.dataValues?.controls">
      <td>{{ group.controls.label.value || '' }}</td>
      <td>{{ group.controls.unit.value?.label || group.controls.unit.value?.value || '' }}</td>
      <td>
        <esg-toggle-switch
          *ngIf="group.controls.visible"
          [fc]="group.controls.visible"
          (onCheck)="handleOnToggle()"></esg-toggle-switch>
      </td>
    </tr>
  </tbody>
</table>
