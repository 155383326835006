import { IUploadedPhoto } from '../shared/ui/upload-picture/upload-picture.component';
import { ContentActionsTypes, ContentDataTypes, ContentStatesTypes, ContentTypes } from './content-configuration';
import { IPictureContentData } from './content-item/picture/picture.component';

export class DynamicContent {
  constructor(
    public contentId: string,
    public contentType: ContentTypes,
    public actions: ContentActionsTypes,
    public states: ContentStatesTypes,
    public data?: ContentDataTypes
  ) {}
}

export const DynamicComponentFactory = (actions: {
  uploadPhoto: (componentId: string) => (file?: File) => void;
  getUploadedPhoto: (blobName: string) => IUploadedPhoto;
  generateText?: (question: string) => Promise<string>;
}) => ({
  [ContentTypes.Text]: (id: string, content?: string) => {
    return new DynamicContent(
      id,
      ContentTypes.Text,
      {
        generateText: actions.generateText,
      },
      {},
      content ? JSON.parse(content) : undefined
    );
  },
  [ContentTypes.Picture]: (id: string, content?: string) => {
    const componentContent: IPictureContentData = content ? JSON.parse(content) : undefined;
    const uploadedPhoto =
      componentContent && componentContent.blobName ? actions.getUploadedPhoto(componentContent.blobName) : undefined;
    return new DynamicContent(
      id,
      ContentTypes.Picture,
      {
        uploadPhoto: actions.uploadPhoto(id),
      },
      { uploadedPhoto },
      componentContent
    );
  },
  [ContentTypes.ImportFromExcel]: (id: string, content?: string) => {
    return new DynamicContent(id, ContentTypes.ImportFromExcel, {}, {}, content ? JSON.parse(content) : undefined);
  },
});
