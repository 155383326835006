<div class="container">
  <div class="feed" *ngFor="let topic of state.mainTopicPreviewUiState.topics">
    <div *ngIf="reportState.isWideDesktop" class="side-column"></div>
    <div
      class="content"
      [class.not-desktop]="!reportState.isDesktop"
      [class.narrow]="reportState.isNarrow"
      [style.scroll-margin-top.px]="state.changeContentOn - state.topicPaddingTop - 1"
      [style.padding-top.px]="state.topicPaddingTop"
      [id]="topic.id">
      <span
        *ngIf="topic.id === state.activeTopicId"
        esgIntersectionObserver
        [rootMargin]="state.contentRootmargin"
        [threshold]="0"
        [debounceTime]="25"
        (onInit)="state.onTopicInit()"
        (onIntersection)="state.handleTopicChange($event)"
        class="observed observed-topic"></span>
      <span class="topic-title"
        ><span class="mark" [style.background]="reportState.brandColor || ''"></span>{{ topic.name }}</span
      >
      <div class="topic-item" *ngFor="let topicItem of topic.indicatorViewModels">
        <span
          *ngIf="topicItem.id === state.activeTopicItemId"
          esgIntersectionObserver
          [rootMargin]="state.contentRootmargin"
          [threshold]="0"
          [debounceTime]="50"
          (onIntersection)="state.handleTopicItemChange($event)"
          class="observed"></span>
        <esg-indicator-preview
          [id]="topicItem.id"
          [style.scroll-margin-top.px]="state.changeContentOn - 1"
          [indicator]="topicItem"></esg-indicator-preview>
      </div>
    </div>
    <div *ngIf="reportState.isDesktop" class="side-column">
      <span
        *ngIf="topic.id === state.activeTopicId"
        esgIntersectionObserver
        [rootMargin]="state.tocRootmargin"
        [threshold]="0"
        (onIntersection)="state.handleOnTocIntersection($event)"
        class="observed"></span>
      <esg-table-of-contents
        *ngIf="topic.id === state.activeTopicId && state.topicItemTabs && state.topicItemTabs.items.length"
        class="toc"
        [class.fixed]="state.fixedToc && reportState.stickyTabs"
        [list]="state.topicItemTabs"
        (onItemClick)="state.onContentLinkClick($event)"></esg-table-of-contents>
    </div>
  </div>
</div>
