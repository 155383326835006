import { TableCell } from 'pdfmake/interfaces';
import { formatNumbersInString } from 'src/app/shared/utils/number-converters';

export interface ISubstrateData {
  tHead: ITr[];
  tBody: ITr[];
  tFoot: ITr[];
}

interface ITr {
  items: ITrItem[];
}

interface ITrItem {
  rowSpan?: number;
  colSpan?: number;
  content?: string;
  isNumber: boolean;
}

export const generateSubstrateData = function (substrateData: ISubstrateData, colSpan: number = 1): TableCell {
  const tableBody: TableCell[][] = [];
  const tHeadRows = generateSubstrateDataRows(substrateData.tHead, true);
  const tBodyRows = generateSubstrateDataRows(substrateData.tBody);
  tableBody.push(...tHeadRows);
  tableBody.push(...tBodyRows);

  return {
    colSpan: colSpan,
    border: [false, false, false, false],
    table: {
      widths: '*',
      body: tableBody,
    },
    layout: {
      hLineColor: '#DDDDDD',
      vLineColor: '#DDDDDD',
      hLineWidth: function () {
        return 0.5;
      },
      vLineWidth: function () {
        return 0.5;
      },
      paddingRight: function () {
        return 0;
      },
      paddingLeft: function () {
        return 0;
      },
      paddingTop: function () {
        return 0;
      },
      paddingBottom: function () {
        return 0;
      },
    },
  };
};

const generateSubstrateDataRows = function (rows: ITr[], isHeader: boolean = false): TableCell[][] {
  const tableRows: TableCell[][] = [];
  for (let i = 0; i < rows.length; i++) {
    const row: TableCell[] = [];

    for (let j = 0; j < rows[i].items.length; j++) {
      const item = rows[i].items[j];
      const cell: TableCell = {
        text: !isHeader && item.isNumber ? formatNumbersInString(item.content || ' ', 0, 2) : item.content || ' ',
        colSpan: item.colSpan || 1,
        rowSpan: item.rowSpan && i + item.rowSpan - 1 < rows.length ? item.rowSpan : 1,
        alignment: isHeader && i === 0 ? 'center' : item.isNumber ? 'right' : 'left',
        style: isHeader ? 'indicatorHeading' : 'indicatorValue',
        margin: !isHeader ? [4, 8, 4, 8] : i === 0 ? [4, 6, 4, 6] : [4, 10, 4, 10],
        border: [j > 0, !isHeader || i > 0, j < rows[i].items.length - 1, true],
      };
      row.push(cell);

      // Add empty cells to new rows based on rowSpan
      if (item.rowSpan && item.rowSpan > 1 && i + item.rowSpan - 1 < rows.length) {
        for (let k = 1; k < item.rowSpan; k++) {
          rows[i + k].items.splice(j, 0, { isNumber: false, content: '' });
        }
      }

      // Add empty cells to new columns based on colSpan
      if (item.colSpan && item.colSpan > 1) {
        for (let k = 1; k < item.colSpan; k++) {
          row.push({ text: '' });
        }
      }
    }

    tableRows.push(row);
  }

  return tableRows;
};
