import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IOption } from 'src/app/static-data/options';

@Component({
  selector: 'esg-report-card',
  templateUrl: './report-card.component.html',
  styleUrls: ['./report-card.component.scss'],
})
export class ReportCardComponent {
  @Input() backgroundPictureUrl?: string;
  @Input({ required: true }) title!: string;
  @Input() organizationName?: string;
  @Input() lastModifiedData?: Date;
  @Input({ required: true }) status!: IOption;
  @Input() oldVersion?: boolean;
  @Output() onClick = new EventEmitter();
  @Output() onConvert = new EventEmitter();
  @Output() onRemove = new EventEmitter();

  handleRemove(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    if (this.onRemove.subscribe.length) {
      this.onRemove.emit();
    }
  }

  handleConvert(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.onConvert.emit();
  }
}
