<esg-dot-flashing *ngIf="state.loading" class="loader" label="Loading assets"></esg-dot-flashing>
<esg-asset-table
  *ngIf="!state.loading"
  [assets]="state.assets"
  [selectedAsset]="state.selectedAsset"
  [organizationId]="state.organizationId"
  [organizationOptions]="state.organizationOptions"
  [countryOptions]="state.countryOptions"
  [organizationCountryMap]="state.organizationCountryMap"
  [isUpdating]="state.updating"
  (onDetailsClick)="state.getAssetDetails($event)"
  (onSelectedAssetChange)="state.setSelectedAsset($event)"
  (onDelete)="state.handleInitateDelete()"
  (onFormSubmit)="state.formSubmit($event)">
</esg-asset-table>
<esg-details-dialog
  [open]="state.assetDetailsOpen"
  [options]="menuProvider()"
  (onClose)="state.handleCloseAssetDetails()">
</esg-details-dialog>
<esg-confirm-dialog
  *ngIf="state.removeDialogOpen && state.selectedAsset"
  [message]="'Once you delete this asset, you delete all its\n content, and there is no turning back.'"
  cancelText="No, keep it"
  (onCancel)="state.handleCloseDeleteDialog()"
  (onSubmit)="deleteSubmit(state.selectedAsset.id)">
</esg-confirm-dialog>
