import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { UserRole } from 'src/api-client/report-api.generated';
import { TemplateApiService } from 'src/app/api-client/report-api/template-api-service';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import { AppInfoService } from 'src/app/core/app-info.service';
import { IUploadedPhoto } from 'src/app/shared/ui/upload-picture/upload-picture.component';
import { TemplateStateService } from './template-form-state.service';

export interface ITemplateData {
  id?: string;
  name?: string;
  global: boolean;
  longDescription: FormControl;
  shortDescription: FormControl;
  reportId?: string;
  coverPhoto?: IUploadedPhoto;
}

@Component({
  selector: 'esg-template-form',
  templateUrl: './template-form.component.html',
  styleUrls: ['./template-form.component.scss'],
  providers: [TemplateStateService],
})
export class TemplateFormComponent implements OnInit {
  submitted = false;
  @Input() reportId?: string;
  @Input() templateId?: string;
  @Output() onClose = new EventEmitter();
  @Output() onSubmit = new EventEmitter();

  @ViewChild('main') public main!: ElementRef<HTMLElement>;

  hasAccessToMarkTemplateAsGlobal = false;
  canCreateAsGlobalTemplate = false;
  templateData: ITemplateData = {
    id: '',
    name: '',
    global: false,
    longDescription: new FormControl(''),
    shortDescription: new FormControl(''),
    reportId: '',
    coverPhoto: undefined,
  };
  coverPhotoValue = new FormControl<IUploadedPhoto | undefined>(undefined);

  textAreaStyles = {
    height: '163px',
    'font-size': '14px',
    color: '#333333',
  };

  tmpCoverPhoto: any;

  showStickyDivider = false;

  constructor(
    public state: TemplateStateService,
    private domSanitizer: DomSanitizer,
    private templateApiService: TemplateApiService,
    private appConfig: AppConfigurationService,
    private appInfo: AppInfoService
  ) {}

  ngOnInit(): void {
    this.templateData!.reportId = this.reportId;
    this.templateData!.id = this.templateId;

    if (this.templateData.id) {
      this.templateApiService.getTemplate(this.templateData.id).subscribe(result => {
        this.templateData.name = result.title;
        this.templateData.global = result.global;
        this.templateData.shortDescription.setValue(result.shortDescription);
        this.templateData.longDescription.setValue(result.description);
        this.tmpCoverPhoto = result.coverPhoto;
        if (result.coverPhoto?.blobName) {
          this.templateData.coverPhoto = {
            ...result.coverPhoto,
            url: this.getCoverPhotoUrl(result.coverPhoto?.blobName),
            fileName: result.coverPhoto?.fileName || result.coverPhoto?.blobName,
          };
          this.coverPhotoValue.setValue(this.templateData.coverPhoto);
        }
        this.templateData.reportId = result.exportedFromReportId;
      });
    }

    this.appInfo.userInfo.subscribe(userInfo => {
      this.hasAccessToMarkTemplateAsGlobal =
        userInfo?.role === UserRole.SuperAdmin && userInfo?.organizationContextIsGlobal;

      if (this.hasAccessToMarkTemplateAsGlobal && this.templateData.reportId) {
        this.getCanCreateAsGlobalTemplate(this.templateData.reportId);
      }
    });
  }

  getCoverPhotoUrl(blobName?: string) {
    return blobName ? `${this.appConfig.ReportApiBaseUrl}/report/cover-photo/${blobName}` : '';
  }

  get templateDataValid() {
    return (
      this.templateData?.reportId &&
      this.templateData?.name &&
      this.templateData?.longDescription.value &&
      this.templateData?.shortDescription.value &&
      this.templateData?.coverPhoto &&
      this.globalCheckboxValid
    );
  }

  get globalCheckboxValid() {
    if (this.templateData.global && !this.canCreateAsGlobalTemplate) return false;
    else return true;
  }

  async getCanCreateAsGlobalTemplate(reportId: string) {
    const response = await this.templateApiService.canCreateAsGlobalTemplate(reportId);
    this.canCreateAsGlobalTemplate = response.result;
  }

  handleUploadCoverPhoto(file: File) {
    var objectUrl = URL.createObjectURL(file);
    var coverPhoto: IUploadedPhoto = {
      url: this.domSanitizer.bypassSecurityTrustResourceUrl(objectUrl),
      fileName: file.name,
      file: {
        data: file,
        fileName: file.name,
      },
    };
    this.templateData!.coverPhoto = coverPhoto;
  }

  handleStickyDivider(tabEntry: IntersectionObserverEntry) {
    if (tabEntry.rootBounds?.bottom) {
      this.showStickyDivider = tabEntry.boundingClientRect.bottom > tabEntry.rootBounds.bottom;
    } else this.showStickyDivider = false;
  }

  handleNameChange(value: string) {
    this.templateData.name = value;
  }

  handleGlobalClick() {
    this.templateData = {
      ...this.templateData,
      global: !this.templateData.global,
    };
  }

  handleShortDescriptionChange(evt: any) {
    this.templateData.shortDescription = evt.target.value;
  }
  handleSaveTemplate() {
    const { coverPhoto, name, shortDescription, longDescription, global, reportId } = this.templateData;
    if (name && shortDescription && shortDescription && reportId) {
      if (this.submitted) return;
      this.submitted = true;
      if (this.templateData.id) {
        if (!coverPhoto?.file && coverPhoto?.url) {
          // photo didn't changed
          this.state.getBlobFromImgUrl(coverPhoto?.url.toString()).then(blobData => {
            coverPhoto.file = {
              data: blobData,
              fileName: coverPhoto.fileName,
            };
            this.templateApiService
              .update(
                this.templateData.id || '',
                name,
                shortDescription.value,
                longDescription.value,
                global,
                coverPhoto.file
              )
              .subscribe(res => {
                this.handleOnSubmit();
              });
          });
        } else {
          // photo changed
          this.templateApiService
            .update(
              this.templateData.id || '',
              name,
              shortDescription.value,
              longDescription.value,
              global,
              coverPhoto?.file
            )
            .subscribe(res => {
              this.handleOnSubmit();
            });
        }
      } else {
        this.templateApiService
          .saveReportAsTemplate(name, shortDescription.value, longDescription.value, reportId, global, coverPhoto?.file)
          .subscribe(res => {
            this.handleOnSubmit();
          });
      }
    }
  }

  handleOnSubmit() {
    this.onSubmit.emit();
  }

  handleCancel() {
    this.onClose.emit();
  }

  handleClearPhoto(uploadedPhoto: IUploadedPhoto) {
    this.templateData.coverPhoto = undefined;
  }
}
