import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { AppConfigurationService } from './core/app-configuration.service';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(logLevel, message);
  if (logLevel === LogLevel.Error) {
    // window.location.reload();
  }
}

export function MSALInstanceFactory(appConfiguration: AppConfigurationService): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: appConfiguration.B2CClientId,
      authority: appConfiguration.B2CSignInAuthority,
      redirectUri: appConfiguration.AppBaseUrl,
      postLogoutRedirectUri: '/',
      knownAuthorities: [appConfiguration.B2CAuthorityDomain],
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },

    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false,
      },
    },
  });
}

// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/msal-interceptor.md
export function MSALInterceptorConfigFactory(appConfiguration: AppConfigurationService): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: new Map<string, Array<string> | null>([
      ['/api/report/app/app-info', null],
      ['/api/report/access-token/validate-token', null],
      ['/api/report/report/complete/token-auth', null],
      ['/api/report', [appConfiguration.B2CWebApiScopeUrl]],
    ]),
    authRequest: {},
  };
}

// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/msal-guard.md
export function MSALGuardConfigFactory({}: AppConfigurationService): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
  };
}
