import { Component, Input } from '@angular/core';
import {
  CellValueType,
  CreateIndicatorDataCommandParams,
  IColumnDefinition,
  IRowDefinition,
  IndicatorTableCellValue,
  IndicatorTableValue,
  IndicatorTableValueDefinition,
  IndicatorTableValueRow,
  IndicatorValueType,
  TimePeriod,
  TimePeriodType,
  UpdateIndicatorDataCommandParams,
} from 'src/api-client/report-api.generated';
import {
  GenericColumnType,
  GenericColumnUi,
  GenericRowUi,
  mapCellValueTypeToGenericType,
} from 'src/app/shared/table/generic-table/generic-table.types';
import { formattedStringToNumber } from 'src/app/shared/utils/number-converters';
import { IOption } from 'src/app/static-data/options';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { IndicatorRecordForm, IndicatorRecordsComponent } from '../indicator-records.component';
import { ITimePeriodData } from 'src/app/shared/components/time-period/time-period.component';
import { IndicatorRecordData } from '../indicator-data-state.service';

@Component({
  selector: 'esg-indicator-table-records',
  templateUrl: './indicator-table-records.component.html',
  styleUrl: './indicator-table-records.component.scss',
})
export class IndicatorTableRecordsComponent extends IndicatorRecordsComponent {
  @Input({ required: true }) tableDefinition!: IndicatorTableValueDefinition;
  @Input() currencyOptions: IOption[] = [];
  @Input() unitOptions: IOption[] = [];

  columns: GenericColumnUi[] = [];
  columnTypeMap: Map<string, GenericColumnType> = new Map();
  columnOptionsMap: Map<string, IOption[]> = new Map();
  columnUnitMap: Map<string, string | undefined> = new Map();

  stickyHeaders: boolean = false;

  get rootObserverMargin() {
    return `${-this.intersectionObserverRootMargin}px 0px 0px 0px`;
  }

  ngOnInit(): void {
    const tableDefinitionHasRows = this.tableDefinition.rows && this.tableDefinition.rows.length > 0;

    this.columns =
      this.tableDefinition.columns.map((col: IColumnDefinition, index) => ({
        header: col.header,
        description: col.description,
        columnId: col.columnId,
        type:
          tableDefinitionHasRows && index === 0
            ? GenericColumnType.StaticText
            : mapCellValueTypeToGenericType(col.type),
        width: undefined,
        unit: col.unit,
        options: col.selectorOptions?.map(option => ({ value: option.value.toString(), label: option.label })),
      })) || [];

    this.columns.forEach(column => {
      this.columnTypeMap.set(column.columnId, column.type);
      this.columnUnitMap.set(column.columnId, column.unit);
      if (column.options) {
        this.columnOptionsMap.set(column.columnId, column.options);
      }
    });

    this.indicatorRecords = this.indicatorRecords.map(record => ({
      ...record,
      assetName:
        this.assetOptions.flatMap(group => group.options).find(opt => opt.value === record.assetId)?.label || '',
    }));
  }

  handleOnIniateAddRecord(event: Event) {
    event.stopPropagation();
    if (!this.isAddEnabled) return;
    if (this.editRecordId) this.handleCloseForm();
    if (!this.showPeriodSelector) this.startDateOpen = true;
    this.setAddRecordForm();
  }

  setAddRecordForm() {
    const newRecord = this.getEmptyTableValue();
    this.recordFc = {
      startDate: new FormControl<Date>(new Date(), {
        validators: this.showPeriodSelector ? null : [Validators.required],
        nonNullable: true,
      }),
      endDate: new FormControl<Date>(new Date(), {
        validators: this.indicatorRecordDuration === this.recordDurationEnum.DateFromTo ? [Validators.required] : null,
        nonNullable: true,
      }),
      timePeriod: new FormControl<ITimePeriodData>(this.defaultTimeperiod, {
        validators: this.showPeriodSelector ? [Validators.required] : null,
        nonNullable: true,
      }),
      asset: new FormControl<IOption | undefined>(this.getDefaultAssetOption(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      tableValue: new FormControl<GenericRowUi[]>(newRecord, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      description: new FormControl<string>('', { nonNullable: true }),
      singleValue: new FormControl<string>('', { nonNullable: true }),
    };

    this.recordFormgroup = new FormGroup<IndicatorRecordForm>(this.recordFc);
  }

  handleOnInitateEditRecord(event: Event, id: string) {
    event.stopPropagation();
    this.editRecordId = id;
    this.setEditRecordForm(id);
  }

  setEditRecordForm(id: string) {
    const record = this.indicatorRecords.find(record => record.id === id);
    if (record) {
      this.recordFc = {
        startDate: new FormControl<Date>(record.timePeriod.customStart || new Date(), {
          validators: this.showPeriodSelector ? null : [Validators.required],
          nonNullable: true,
        }),
        endDate: new FormControl<Date>(record.timePeriod.customEnd || new Date(), {
          validators:
            this.indicatorRecordDuration === this.recordDurationEnum.DateFromTo ? [Validators.required] : null,
          nonNullable: true,
        }),
        timePeriod: new FormControl<ITimePeriodData>(
          record.timePeriod?.type ? record.timePeriod : this.defaultTimeperiod,
          {
            validators: this.showPeriodSelector ? [Validators.required] : null,
            nonNullable: true,
          }
        ),
        asset: new FormControl<IOption | undefined>(
          this.assetOptions.flatMap(group => group.options).find(opt => opt.value === record.assetId) ||
            this.getDefaultAssetOption(),
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        tableValue: new FormControl<GenericRowUi[]>(
          record.tableValue ? this.convertTableValueToGenericRowUi(record.tableValue) : this.getEmptyTableValue(),
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        description: new FormControl<string>('', { nonNullable: true }),
        singleValue: new FormControl<string>('', { nonNullable: true }),
      };
      this.recordFormgroup = new FormGroup<IndicatorRecordForm>(this.recordFc);
    }
  }

  handleOnSubmit() {
    if (this.startDateOpen || this.endDateOpen) return;

    this.hasSubmitted = true;

    if (this.recordFormgroup?.valid && this.recordFc && this.recordFc.asset.value?.value) {
      const timePeriod = this.showPeriodSelector
        ? new TimePeriod(this.recordFc.timePeriod.value)
        : new TimePeriod({
            type: TimePeriodType.Custom,
            customStart: this.recordFc.startDate.value,
            customEnd:
              this.indicatorRecordDuration === this.recordDurationEnum.SingleDate
                ? this.recordFc.startDate.value
                : this.recordFc.endDate.value,
          });

      const record = {
        indicatorId: this.indicatorId,
        description: '',
        timePeriod: timePeriod,
        assetId: this.recordFc.asset.value.value,
        valueType: IndicatorValueType.Table,
        tableValue: new IndicatorTableValue({
          rows: this.recordFc.tableValue.value.map(
            row =>
              new IndicatorTableValueRow({
                rowId: row.rowId,
                cells: row.values.map(
                  cell =>
                    new IndicatorTableCellValue({
                      columnId: cell.columnId,
                      value: formattedStringToNumber(cell.value?.toString() || '') || 0,
                      unit: cell.unit,
                    })
                ),
              })
          ),
        }),
      };

      if (this.editRecordId) {
        this.onUpdate.emit(
          new UpdateIndicatorDataCommandParams({
            id: this.editRecordId,
            ...record,
          })
        );
      } else {
        this.handleCloseForm();
        this.onCreate.emit(new CreateIndicatorDataCommandParams(record));
      }
    }
  }

  handleTableValueChange(row: GenericRowUi) {
    if (this.recordFc) {
      this.recordFc.tableValue.setValue(this.recordFc.tableValue.value.map(r => (r.rowId === row.rowId ? row : r)));
      this.recordFc.tableValue.markAsDirty();
    }
  }

  trackByRecordId(index: number, record: IndicatorRecordData): string {
    return record.id;
  }

  handleStickyHeaders(observerEntry: IntersectionObserverEntry) {
    this.stickyHeaders = observerEntry.intersectionRatio === 0;
  }

  private getEmptyTableValue(): GenericRowUi[] {
    return this.tableDefinition.rows && this.tableDefinition.rows.length > 0
      ? this.tableDefinition.rows.map((row: IRowDefinition) => ({
          rowId: row.rowId,
          values: this.tableDefinition.columns.map((column, index) => {
            if (index === 0) {
              return {
                columnId: column.columnId,
                value: row.header,
                unit: row.unit,
              };
            } else {
              return {
                columnId: column.columnId,
                value: this.getDefaultValueForColumn(column),
              };
            }
          }),
        }))
      : [
          {
            rowId: uuidv4(),
            values: this.tableDefinition.columns.map(column => {
              return {
                columnId: column.columnId,
                value: this.getDefaultValueForColumn(column),
              };
            }),
          },
        ];
  }

  private getDefaultValueForColumn(column: IColumnDefinition): string | number {
    if (column.type === CellValueType.Selector && column.selectorOptions && column.selectorOptions.length > 0) {
      return column.selectorOptions[0].value;
    }
    return '';
  }

  private convertTableValueToGenericRowUi(tableValue: IndicatorTableValue): GenericRowUi[] {
    return tableValue.rows.map(row => ({
      rowId: row.rowId,
      values: row.cells.map((cell, index) => {
        if (index === 0 && this.tableDefinition.rows.length > 0) {
          const rowDef = this.tableDefinition.rows.find(r => r.rowId === row.rowId);
          return {
            columnId: cell.columnId,
            value: rowDef?.header || cell.value || '',
            unit: rowDef?.unit || cell.unit,
          };
        } else {
          return {
            columnId: cell.columnId,
            value: cell.value ?? '',
            unit: cell.unit,
          };
        }
      }),
    }));
  }
}
