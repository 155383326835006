import {
  CalculateCalculatedDataCommandParams,
  CalculateCalculatedDataCommandResponse,
  CalculatedDataClient,
  GetByIndicatorIdCalculatedDataQueryResponse,
  GetSubstrateDataQueryResponse,
  TimePeriodType,
} from '../../../api-client/report-api.generated';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';

@Injectable({
  providedIn: 'root',
})
export class CalculatedDataApiService {
  client: CalculatedDataClient;

  constructor(readonly httpClient: HttpClient, readonly appSettings: AppConfigurationService) {
    this.client = new CalculatedDataClient(httpClient, appSettings.ReportApiBaseUrl);
  }

  async createIndicatorData(indicatorId: string, year: number): Promise<CalculateCalculatedDataCommandResponse> {
    const observable$ = this.client.calculateCalculatedData(
      new CalculateCalculatedDataCommandParams({ indicatorId, year })
    );
    return await lastValueFrom(observable$);
  }

  async getByIndicatorIdCalculatedData(
    indicatorId: string,
    periodType: TimePeriodType,
    yearFrom?: number,
    yearTo?: number
  ): Promise<GetByIndicatorIdCalculatedDataQueryResponse> {
    const observable$ = this.client.getByIndicatorIdCalculatedData(indicatorId, yearFrom, yearTo, periodType);
    return await lastValueFrom(observable$);
  }

  async getCalculatedDataSubstrateData(
    indicatorId: string,
    year: number,
    periodType: TimePeriodType,
    period: number
  ): Promise<GetSubstrateDataQueryResponse> {
    const observable$ = this.client.getSubstrateData(indicatorId, year, periodType, period);
    return await lastValueFrom(observable$);
  }
}
