import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import {
  IndicatorDataValueDefinitionClient,
  GetByIdIndicatorDataValueDefinitionQueryResponse,
  GetAllIndicatorDataValueDefinitionsQueryResponse,
} from '../../../api-client/report-api.generated';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IndicatorDataValuesDefinitionApiService {
  client: IndicatorDataValueDefinitionClient;

  constructor(readonly httpClient: HttpClient, readonly appSettings: AppConfigurationService) {
    this.client = new IndicatorDataValueDefinitionClient(httpClient, appSettings.ReportApiBaseUrl);
  }

  async getAll(): Promise<GetAllIndicatorDataValueDefinitionsQueryResponse> {
    const observable$ = this.client.getAllIndicatorDataValueDefinitions();
    return await lastValueFrom(observable$);
  }

  async getById(id: string): Promise<GetByIdIndicatorDataValueDefinitionQueryResponse> {
    const observable$ = this.client.getIndicatorDataValueDefinitionById(id);
    return await lastValueFrom(observable$);
  }
}
