import { Injectable } from '@angular/core';
import {
  Component,
  UpdateTopicItemDataValues,
  UpdateTopicItemDataValuesCommandParams,
  DataCollectorConnection,
  IndicatorDataValueType,
  IndicatorNumericDataValue,
  UpdateDataCollectorConnectionCommandParams,
} from 'src/api-client/report-api.generated';
import { TopicItemApiService } from 'src/app/api-client/report-api/topic-item-api-service';
import { formattedStringToNumber } from 'src/app/shared/utils/number-converters';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { IIndicatorValueConnection, IIndicatorValueRow } from './indicator-values/indicator-values.component';
import { SubstrateDataUi } from './substrate-data/substrate-data.component';

export interface TopicItemContentUi {
  id: string;
  name: string;
  visible: boolean;
  description?: string;
  referenceStandards?: string;
  calculationDate?: Date;
  type?: string;
  predefinedIndicatorId?: string;
  dataValues?: DataValuesUi[];
  substrateData?: SubstrateDataUi;
  components?: Component[];
}

export interface DataValuesUi {
  indicatorId?: string;
  currentValue: DataValueUi;
  metadata: DataValueMetaDataUi;
  previousValue: DataValueUi;
  targetValue: DataValueUi;
  isCurrentValueConnected: boolean;
  isPreviousValueConnected: boolean;
  substrateData?: SubstrateDataUi;
}

interface DataSingleValueMetaDataUi {
  label?: string;
  type?: string;
  unit?: string;
  unitDescription?: string;
  groupLabel?: string;
  isVisibleInReport?: boolean;
}

interface DataValueMetaDataUi extends DataSingleValueMetaDataUi {
  tableColumnsMetadata?: DataSingleValueMetaDataUi[];
}

interface DataSingleValueUi {
  type?: string;
  value?: string | number;
  unitDetails?: string;
  errorMessage?: string;
}

export interface DataValueUi extends DataSingleValueUi {
  tableRows?: DataTableValueRowUi[];
}

export interface DataTableValueRowUi {
  values?: DataSingleValueUi[];
}

@Injectable()
export class TopicItemDetailsStateService {
  lastUpdated?: Date;
  numericalValues?: DataValuesUi[];
  tableValues?: DataValuesUi[];
  substrateData?: SubstrateDataUi;
  visibile = false;
  calculateIsHovered: boolean = false;

  constructor(private topicItemApiService: TopicItemApiService, private notificationService: NotificationService) {}

  setIndicatorValues(values: DataValuesUi[]) {
    this.tableValues = values.filter(value => value.metadata.type === IndicatorDataValueType.Table);
    this.numericalValues = values.filter(value => value.metadata.type !== IndicatorDataValueType.Table);
  }

  handleOnValueSubmit(indicatorValue: IIndicatorValueRow, topicItemId: string, values: DataValuesUi[]) {
    const targetValue = new IndicatorNumericDataValue({
      type: IndicatorDataValueType.Numeric,
      value: formattedStringToNumber(indicatorValue.targetValue),
    });
    const currentValue = new IndicatorNumericDataValue({
      type: IndicatorDataValueType.Numeric,
      value: formattedStringToNumber(indicatorValue.currentValue),
    });
    const previousValue = new IndicatorNumericDataValue({
      type: IndicatorDataValueType.Numeric,
      value: formattedStringToNumber(indicatorValue.previousValue),
    });
    this.topicItemApiService
      .setDataValues(
        new UpdateTopicItemDataValuesCommandParams({
          topicItemId: topicItemId,
          dataValues: values?.map((val, index) =>
            index === indicatorValue.index
              ? new UpdateTopicItemDataValues({
                  currentValue: currentValue,
                  previousValue: previousValue,
                  targetValue: targetValue,
                })
              : new UpdateTopicItemDataValues({
                  currentValue: val.currentValue as IndicatorNumericDataValue,
                  previousValue: val.previousValue as IndicatorNumericDataValue,
                  targetValue: val.targetValue as IndicatorNumericDataValue,
                })
          ),
        })
      )
      .subscribe(result => {
        if (result.success && indicatorValue.index < values.length) {
          values[indicatorValue.index].targetValue = targetValue;
          values[indicatorValue.index].currentValue = currentValue;
          values[indicatorValue.index].previousValue = previousValue;
        }
      });
  }

  handleOnSetDataCollectorConnection(
    topicItemId: string,
    values: DataValuesUi[],
    indicatorValueConnection: IIndicatorValueConnection
  ) {
    this.topicItemApiService
      .setDataCollectorConnection(
        new UpdateDataCollectorConnectionCommandParams({
          topicItemId: topicItemId,
          dataCollectorConnections: values?.map((val, index) =>
            index === indicatorValueConnection.index
              ? new DataCollectorConnection({
                  isPreviousValueConnected: indicatorValueConnection.isPreviousValueConnected,
                  isCurrentValueConnected: indicatorValueConnection.isCurrentValueConnected,
                })
              : new DataCollectorConnection({
                  isPreviousValueConnected: val.isPreviousValueConnected,
                  isCurrentValueConnected: val.isCurrentValueConnected,
                })
          ),
        })
      )
      .subscribe(result => {
        if (result.success && indicatorValueConnection.index < values.length) {
          if (
            (!values[indicatorValueConnection.index].isCurrentValueConnected &&
              indicatorValueConnection.isCurrentValueConnected) ||
            (!values[indicatorValueConnection.index].isPreviousValueConnected &&
              indicatorValueConnection.isPreviousValueConnected)
          ) {
            this.handleCalculate(topicItemId, values);
          }
          values[indicatorValueConnection.index].isPreviousValueConnected =
            indicatorValueConnection.isPreviousValueConnected;
          values[indicatorValueConnection.index].isCurrentValueConnected =
            indicatorValueConnection.isCurrentValueConnected;
        }
      });
  }

  initCalculationData(lastUpdated: Date | undefined, substrateData: SubstrateDataUi | undefined) {
    this.lastUpdated = lastUpdated;
    this.substrateData = substrateData;
  }

  setCalculationIsHovered(hover: boolean) {
    this.calculateIsHovered = hover;
  }

  handleCalculate(topicItemId: string, values: DataValuesUi[]) {
    return this.topicItemApiService.calculateIndicator(topicItemId).subscribe(result => {
      this.lastUpdated = result.result.calculationDate;
      result.result.results.forEach((calc, index) => {
        let current = calc.calculatedValue as DataValueUi;
        let previous = calc.previousPeriodCalculatedValue as DataValueUi;
        const isCurrentValueConnected = values[index].isCurrentValueConnected;
        const isPreviousValueConnected = values[index].isPreviousValueConnected;

        if (isCurrentValueConnected) {
          if (calc.calculationProblem) {
            values[index].currentValue.value = 'Inconsistent data';
            if (calc.calculationInfo) {
              this.notificationService.showWarning('Inconsistent data in current period', calc.calculationInfo);
            }
          } else {
            if (current.type === IndicatorDataValueType.Table) {
              const currentValue = values[index].currentValue;
              currentValue.tableRows = current.tableRows;
              values[index] = { ...values[index], currentValue: currentValue };
            } else {
              values[index].currentValue.value = current.value;
              values[index].currentValue.unitDetails = current.unitDetails;
            }
          }
        }

        if (isPreviousValueConnected) {
          if (calc.calculationProblem) {
            values[index].previousValue.value = 'Inconsistent data';
            if (calc.previousPeriodCalculationInfo) {
              this.notificationService.showWarning(
                'Inconsistent data in previous period',
                calc.previousPeriodCalculationInfo
              );
            }
          } else {
            if (previous.type === IndicatorDataValueType.Table) {
              const previousValue = values[index].previousValue;
              previousValue.tableRows = previous.tableRows;
              values[index] = { ...values[index], previousValue: previousValue };
            } else {
              values[index].previousValue.value = previous.value;
            }
          }
        }

        if (isCurrentValueConnected || isPreviousValueConnected) {
          values[index].substrateData = calc.substrateData;
        }
        this.setIndicatorValues(values);
      });
    });
  }

  async handleCalculateNewIndicator(topicItemId: string) {
    var response = await this.topicItemApiService.refreshTopicItemNewIndicatorValues(topicItemId);
    console.log(response);
  }
}
