import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReportTemplate, ReportVersion } from 'src/api-client/report-api.generated';
import { ReportAppApiService } from 'src/app/api-client/report-api/app-api-service';
import { TemplateApiService } from 'src/app/api-client/report-api/template-api-service';
import { TemplateType } from '../report-form.component';

@Component({
  selector: 'esg-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss'],
})
export class TemplateListComponent {
  @Input() version?: ReportVersion;
  @Output() onSelectTemplate = new EventEmitter<any>();
  templates: ReportTemplate[] = [];
  isLoading: boolean = true;
  blank: TemplateType = TemplateType.Custom;
  fromTemplate: TemplateType = TemplateType.Existing;

  constructor(public appApiService: ReportAppApiService, private templateApiService: TemplateApiService) {
    this.templateApiService.getAllTemplates(true).subscribe(result => {
      this.templates = result.result.filter(x => x.reportVersion === this.version) || [];
      this.isLoading = false;
    });
  }
}
