import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IOption } from 'src/app/static-data/options';
import { AssetListStateService } from './asset-list-state.service';
import { IMenuItem } from 'src/app/shared/ui/context-menu/context-menu.component';
import { Subscription } from 'rxjs';
import { AppInfoService } from 'src/app/core/app-info.service';
import { CreateAssetCommandParams, Permission, RiskCategory } from 'src/api-client/report-api.generated';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';

export interface AssetUi {
  id: string;
  name: string;
  orgId?: string;
  type?: string;
  subType?: string;
  organizationName: string;
  versionValidFrom: Date;
  country?: string;
  sector?: string;
  recordsCount: number;
  firstRecordDate?: Date;
  lastRecordDate?: Date;
  socialRisk?: RiskCategory;
  environmentalRisk?: RiskCategory;
}

export interface AssetForm {
  name: FormControl<string | null>;
  orgId?: FormControl<string | null>;
  type?: FormControl<IOption | undefined | null>;
  subType?: FormControl<IOption | undefined | null>;
  organization: FormControl<IOption | undefined | null>;
  country?: FormControl<IOption | undefined | null>;
  sector?: FormControl<IOption | undefined | null>;
}

@Component({
  selector: 'esg-asset-list',
  templateUrl: './asset-list.component.html',
  styleUrls: ['./asset-list.component.scss'],
  providers: [AssetListStateService],
})
export class AssetListComponent {
  private userInfoSub!: Subscription;
  private organizationContextSub!: Subscription;

  get useNewMainNavigation() {
    return this.appConfig.NewNavbarEnabled;
  }

  constructor(
    private appInfo: AppInfoService,
    private appConfig: AppConfigurationService,
    public state: AssetListStateService
  ) {}

  ngOnInit() {
    this.userInfoSub = this.appInfo.userInfo$.subscribe(userInfo => {
      if (userInfo && !this.appInfo.hasPermission(Permission.Change_Current_Context_Organization)) {
        this.state.initByOrganization(userInfo.organizationId);
      }
    });

    this.organizationContextSub = this.appInfo.organizationContext$.subscribe(organizationId => {
      if (organizationId) {
        this.state.initByOrganization(organizationId);
      }
    });
  }

  formSubmit(params: CreateAssetCommandParams) {
    this.state.formSubmit(params);
  }

  deleteSubmit(id: string) {
    this.state.handleDeleteAssetSubmit(id);
  }

  menuProvider = (): IMenuItem[] => [
    { id: 'delete', label: 'Delete', onClick: () => this.state.handleInitateDelete() },
  ];

  ngOnDestroy() {
    this.userInfoSub.unsubscribe();
    this.organizationContextSub.unsubscribe();
  }
}
