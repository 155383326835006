import { Injectable } from '@angular/core';
import { UserApiService } from 'src/app/api-client/report-api/user-api-service';
import {
  CreateUserCommandParams,
  UpdateUserCommandParams,
  DeleteUserCommandParams,
  UserRole,
  Permission,
} from 'src/api-client/report-api.generated';
import { IOption } from 'src/app/static-data/options';
import { OrganizationApiService } from 'src/app/api-client/report-api/organization-api-service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { AppInfoService } from 'src/app/core/app-info.service';
import { Subscription } from 'rxjs';

export interface IUser {
  id?: string;
  organizationId?: string;
  organizationName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  role?: string;
}

@Injectable()
export class UsersStateService {
  private organizationContextSub!: Subscription;
  protected userInfoSub!: Subscription;

  users: IUser[] = [];

  selectedUser?: IUser;
  organizationOptions: IOption[] = [];

  hasAddPermission: boolean = false;
  hasEditPermission: boolean = false;
  hasDeletePermission: boolean = false;
  isSuperAdmin: boolean = false;

  constructor(
    private userApiService: UserApiService,
    private organizationApiService: OrganizationApiService,
    private notification: NotificationService,
    private appInfo: AppInfoService
  ) {
    this.getPermissions();
    this.initData();
  }

  initData() {
    this.organizationContextSub = this.appInfo.organizationContext$.subscribe(organizationId => {
      if (organizationId) {
        this.loadUsers(organizationId);
        this.loadOrganizationOptions(organizationId);
      }
    });
  }

  getPermissions() {
    this.userInfoSub = this.appInfo.userInfo$.subscribe(result => {
      this.isSuperAdmin = result?.role === UserRole.SuperAdmin;
      this.hasAddPermission = this.appInfo.hasPermission(Permission.Report_User_Create);
      this.hasEditPermission = this.appInfo.hasPermission(Permission.Report_User_Update);
      this.hasDeletePermission = this.appInfo.hasPermission(Permission.Report_User_Delete);
    });
  }

  loadUsers(organizationId?: string) {
    this.userApiService.getUsersFilteredBy(organizationId).subscribe(result => {
      this.users =
        result.result?.map<IUser>(user => ({
          id: user.id,
          organizationId: user.organizationId,
          organizationName: user.organizationName || '',
          firstName: user.firstName || '',
          lastName: user.lastName || '',
          email: user.email || '',
          role: user.role || '',
        })) || [];
    });
  }

  async loadOrganizationOptions(organizationId?: string) {
    var organizationResult = await this.organizationApiService.getOrganizationsAndSuborganizationsFilteredBy(
      organizationId
    );
    this.organizationOptions = (organizationResult.result || []).map<IOption>(o => ({
      value: o.id || '',
      label: o.name || '',
    }));
  }

  async setUserDetails(userId: string) {
    var userDetailsPromise = this.userApiService.getUserDetailsById(userId);
    var userDetails = await userDetailsPromise;
    this.selectedUser = {
      id: userDetails.result?.id,
      firstName: userDetails.result?.firstName,
      lastName: userDetails.result?.lastName,
      email: userDetails.result?.email,
      organizationId: userDetails.result?.organizationId,
      organizationName: userDetails.result?.organizationName,
      role: userDetails.result?.role,
    };
  }

  addUser(data: IUser) {
    if (this.hasAddPermission) {
      const userRole = Object.values(UserRole).find(role => role === data.role);

      if (!data.firstName || !data.lastName || !data.email || !userRole) {
        throw Error('No valid user data');
      }

      this.userApiService
        .createUser(
          new CreateUserCommandParams({
            organizationId: data.organizationId,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            role: userRole,
          })
        )
        .subscribe(async result => {
          if (result.success) {
            this.users.push({
              id: result.result || '',
              organizationId: data.organizationId,
              organizationName: data.organizationName,
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              role: data.role,
            });
            await this.appInfo.loadUserInfo();
          }
        });
    }
  }

  editUser(data: IUser) {
    if (this.hasEditPermission) {
      if (data.id && data.firstName && data.lastName && data.organizationId && data.role) {
        this.userApiService
          .updateUser(
            new UpdateUserCommandParams({
              id: data.id,
              organizationId: data.organizationId,
              firstName: data.firstName,
              lastName: data.lastName,
              role: UserRole[data.role as UserRole],
            })
          )
          .subscribe(async result => {
            if (result.success) {
              this.selectedUser = data;
              this.users = this.users.map<IUser>(user =>
                user.id !== data.id
                  ? user
                  : {
                      ...data,
                    }
              );
              await this.appInfo.loadUserInfo();
            } else {
              this.notification.showError('You are not authorized to change role');
            }
          });
      } else {
        throw Error('User editing not possible. Input data not valid.');
      }
    }
  }

  deleteUser(userId: string) {
    if (this.hasDeletePermission) {
      this.userApiService.deleteUser(new DeleteUserCommandParams({ id: userId })).subscribe(result => {
        if (result.success) {
          this.users = this.users.filter(user => user.id !== userId);
          this.notification.showSuccess('Success', 'User removed successfully');
        }
      });
    }
  }

  ngOnDestroy() {
    this.organizationContextSub.unsubscribe();
    this.userInfoSub.unsubscribe();
  }
}
