import { Component } from '@angular/core';
import { IndicatorDefinitionsStateService } from './indicator-definitions-state.service';

@Component({
  selector: 'esg-indicators-definitions',
  templateUrl: './indicators-definitions.component.html',
  styleUrls: ['./indicators-definitions.component.scss'],
  providers: [IndicatorDefinitionsStateService],
})
export class IndicatorsDefinitionsComponent {
  constructor(public state: IndicatorDefinitionsStateService) {}
}
