import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subscription, debounceTime } from 'rxjs';
import {
  IColumnDefinition,
  IRowDefinition,
  CellValueType,
  IndicatorTableValueDefinition,
  DynamicRowsDataSource,
  RowsType,
  RowDefinition,
} from 'src/api-client/report-api.generated';
import {
  GenericColumnType,
  GenericColumnUi,
  GenericRowUi,
} from 'src/app/shared/table/generic-table/generic-table.types';
import { IOption } from 'src/app/static-data/options';
import { KeyboardShortcutsDirective } from 'src/app/shared/directives/keyboard-shortcuts.directive';
import { IndicatorDefinitionApiService } from 'src/app/api-client/report-api/indicator-definition-api-service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';

interface IndicatorJsonTableInput {
  columns: IColumnDefinition[];
  rows: IRowDefinition[];
  dynamicRowsDataSource?: DynamicRowsDataSource;
}

@Component({
  selector: 'esg-indicator-definition-table',
  templateUrl: './indicator-definition-table.component.html',
  styleUrls: ['./indicator-definition-table.component.scss'],
  host: {
    '(onEnterKey)': 'handleOnEnter()',
  },
  hostDirectives: [{ directive: KeyboardShortcutsDirective, outputs: ['onEnterKey'] }],
})
export class IndicatorDefinitionTableComponent implements OnChanges {
  private jsonSubscription?: Subscription;
  @Input() tableDefinition?: IndicatorTableValueDefinition;
  @Output() onSubmit = new EventEmitter<IndicatorTableValueDefinition>();
  @Output() onCancel = new EventEmitter();

  columns: GenericColumnUi[] = [];
  rows: GenericRowUi[] = [];
  dynamicRowsDataSource?: DynamicRowsDataSource;

  jsonTable = new FormControl<string>('', { nonNullable: true, validators: [Validators.required] });
  jsonInputStyle = { color: '#00EFFF', background: '#000000', border: 'none', resize: 'none', padding: '40px 60px' };
  inputHasBeenFocused: boolean = false;

  currencyFavouriteOptions: IOption[] = [
    {
      value: 'USD',
      label: 'USD',
    },
    {
      value: 'EUR',
      label: 'EUR',
    },
    {
      value: 'GBP',
      label: 'GBP',
    },
    {
      value: 'NOK',
      label: 'NOK',
    },
  ];

  constructor(
    private indicatorDefinitionsApiService: IndicatorDefinitionApiService,
    private notificationService: NotificationService
  ) {}

  handleOnCancel() {
    this.onCancel.emit();
  }

  handleOnEnter() {
    if (!this.jsonTable.valid || !this.jsonTable.dirty) {
      this.onCancel.emit();
    } else {
      this.handleOnSubmit();
    }
  }

  handleOnSubmit() {
    try {
      const parsedJson = JSON.parse(this.jsonTable.value);
      const newTableDefinition = IndicatorTableValueDefinition.fromJS(parsedJson);
      const dynamicRowsDataSource = Object.values(DynamicRowsDataSource).find(
        value => value === newTableDefinition.dynamicRowsDataSource
      );
      const rowsType = dynamicRowsDataSource ? RowsType.Dynamic : RowsType.Static;

      this.onSubmit.emit(
        new IndicatorTableValueDefinition({
          ...newTableDefinition,
          dynamicRowsDataSource: dynamicRowsDataSource,
          editableSchema: this.tableDefinition?.editableSchema ?? true,
          tableLabel: this.tableDefinition?.tableLabel ?? '',
          rowsType: rowsType,
        })
      );
    } catch (error) {
      this.jsonTable.setErrors({ invalidJson: error });
    }
  }

  ngOnInit() {
    this.jsonSubscription = this.jsonTable.valueChanges.pipe(debounceTime(500)).subscribe(value => {
      this.onJsonInputChange(value);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tableDefinition && this.tableDefinition) {
      this.updateJson(this.tableDefinition);
      this.convertDefinitionToTable(this.tableDefinition);
    }
  }

  updateJson(tableDefinition: IndicatorTableValueDefinition) {
    const newFormat: IndicatorJsonTableInput = {
      columns: tableDefinition.columns,
      rows: tableDefinition.rows,
      dynamicRowsDataSource: tableDefinition.dynamicRowsDataSource,
    };
    const jsonString = JSON.stringify(newFormat, null, 2);
    this.jsonTable.setValue(jsonString, { emitEvent: false });
    this.clearJsonInputError();
  }

  async onJsonInputChange(value: string) {
    try {
      const newTableDefinition: IndicatorTableValueDefinition = IndicatorTableValueDefinition.fromJS(JSON.parse(value));
      if (this.dynamicRowsDataSource !== newTableDefinition.dynamicRowsDataSource) {
        this.dynamicRowsDataSource = newTableDefinition.dynamicRowsDataSource;
        if (this.dynamicRowsDataSource && Object.values(DynamicRowsDataSource).includes(this.dynamicRowsDataSource)) {
          const dynamicRows = await this.getDynamicRowsFromDataSource(this.dynamicRowsDataSource);
          if (dynamicRows) {
            newTableDefinition.rows = dynamicRows;
            this.updateJson(newTableDefinition);
          }
        }
      }
      this.convertDefinitionToTable(newTableDefinition);
      this.clearJsonInputError();
    } catch (error) {
      this.jsonTable.setErrors({ invalidJson: error });
    }
  }

  convertDefinitionToTable(tableDefinition: IndicatorTableValueDefinition) {
    const tableDefinitionHasRows = tableDefinition.rows && tableDefinition.rows.length > 0;
    this.columns =
      tableDefinition.columns.map((col: IColumnDefinition, index) => ({
        header: col.header,
        description: col.description,
        columnId: col.columnId,
        type:
          tableDefinitionHasRows && index === 0
            ? GenericColumnType.StaticText
            : this.mapCellValueTypeToGenericType(col.type),
        width: undefined,
        unit: col.unit,
        options: col.selectorOptions?.map(option => ({ value: option.value.toString(), label: option.label })),
      })) || [];

    this.rows = tableDefinitionHasRows
      ? tableDefinition.rows.map((row: IRowDefinition) => ({
          rowId: row.rowId,
          values: this.columns.map((column, index) => {
            if (index === 0) {
              return {
                columnId: column.columnId,
                value: row.header,
                unit: row.unit || column.unit,
              };
            } else {
              let defaultValue: string | number | undefined = '';
              if (column.type === GenericColumnType.Numeric) {
                defaultValue = '';
              } else if (column.type === GenericColumnType.Selector && column.options && column.options.length > 0) {
                defaultValue = column.options[0].value;
              }
              return {
                columnId: column.columnId,
                value: defaultValue,
                unit: column.unit,
              };
            }
          }),
        }))
      : [
          {
            rowId: 'example',
            values: this.columns.map((column, index) => {
              let defaultValue: string | number | undefined = '';
              if (column.type === GenericColumnType.Numeric) {
                defaultValue = '';
              } else if (column.type === GenericColumnType.Selector && column.options && column.options.length > 0) {
                defaultValue = column.options[0].value;
              }
              return {
                columnId: column.columnId,
                value: defaultValue,
                unit: column.unit,
              };
            }),
          },
        ];
  }

  async getDynamicRowsFromDataSource(
    dynamicRowsDataSource: DynamicRowsDataSource
  ): Promise<RowDefinition[] | undefined> {
    try {
      const response = await this.indicatorDefinitionsApiService.getDynamicRows(dynamicRowsDataSource);
      return response.result;
    } catch (error) {
      this.notificationService.showError('Failed to get row from data source');
      return undefined;
    }
  }

  private clearJsonInputError() {
    this.jsonTable.setErrors(null);
  }

  private mapCellValueTypeToGenericType(type: CellValueType): GenericColumnType {
    switch (type) {
      case CellValueType.StaticText:
        return GenericColumnType.Generic;
      case CellValueType.Numeric:
        return GenericColumnType.Numeric;
      case CellValueType.Selector:
        return GenericColumnType.Selector;
      case CellValueType.Currency:
        return GenericColumnType.Currency;
      default:
        return GenericColumnType.Generic;
    }
  }

  ngOnDestroy() {
    this.jsonSubscription?.unsubscribe();
  }
}
