<div class="empty-item"></div>
<div class="menu-item">
  <div class="tile" [routerLink]="['key-figures']" (click)="handleOnClose()">
    Key Figures (Home Page) <esg-chevron></esg-chevron>
  </div>
</div>
<div class="menu-item" *ngFor="let mainLevel of mainLevels">
  <div class="tile" (click)="mainLevel.active = !mainLevel.active">
    {{ mainLevel.name }}
    <esg-icon-add *ngIf="!mainLevel.active"> </esg-icon-add>
    <esg-icon-minus *ngIf="mainLevel.active"></esg-icon-minus>
  </div>

  <ng-container *ngIf="mainLevel.active">
    <div class="topic-container" *ngFor="let topic of mainLevel.topics">
      <div class="topic" (click)="topic.active = !topic.active">
        {{ topic.name }}
        <esg-icon-add *ngIf="!topic.active"> </esg-icon-add>
        <esg-icon-minus *ngIf="topic.active"></esg-icon-minus>
      </div>

      <ng-container *ngIf="topic.active">
        <div
          class="indicator"
          *ngFor="let indicator of topic.topicItems"
          (click)="indicator.active = !indicator.active; handleOnClose()"
          [routerLink]="[mainLevel.id, topic.id]"
          [fragment]="indicator.id">
          {{ indicator.name }} <esg-chevron> </esg-chevron>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
