<esg-form-dialog
  [submitText]="submitText"
  [submitDisabled]="!formgroup.valid || !formgroup.dirty"
  (onCancel)="onCancel.emit()"
  (onSubmit)="handleSubmit()">
  <div class="container">
    <header>{{ headerText }}</header>
    <div class="content">
      <div class="left">
        <esg-textbox-form label="FIRST NAME" placeholder="e.g. Greta" [fc]="fc.firstNameFormControl"></esg-textbox-form>
        <esg-textbox-form label="LAST NAME" placeholder="e.g Thunberg" [fc]="fc.lastNameFormControl"></esg-textbox-form>
        <esg-textbox-form
          label="EMAIL"
          placeholder="e.g smallsomething@getalife.com"
          [fc]="fc.emailFormControl"></esg-textbox-form>
      </div>
      <div class="right">
        <esg-select
          label="ORGANIZATION"
          [options]="organizationOptions"
          [value]="fc.organizationIdControl.value ? fc.organizationIdControl.value : undefined"
          [disabled]="fc.organizationIdControl.disabled"
          [isPopup]="true"
          (onChange)="handleOrganizationChange($event)">
        </esg-select>
        <esg-select
          label="ROLE"
          [options]="userRoleOptions"
          [value]="fc.roleFormControl.value ? fc.roleFormControl.value : undefined"
          [isPopup]="true"
          (onChange)="handleRoleChange($event)">
        </esg-select>
      </div>
    </div>
  </div>
</esg-form-dialog>
