import { Content, TableCell } from 'pdfmake/interfaces';
import {
  IndicatorDataValueType,
  TimePeriod,
  TimePeriodType,
  TopicItemType,
} from '../../../../api-client/report-api.generated';
import { getTimeLabelFromPeriod } from '../../../static-data/time-labels';
import { numberToFormattedNumberString } from 'src/app/shared/utils/number-converters';
import { generateSubstrateData, ISubstrateData } from './substrate-data-pdf';
import { pickTextColorBasedOnBgColor } from 'src/app/shared/utils/colors';
import { IKeyfigures, IMainLevel } from './types';

export interface keyFiguresTable {
  mainTopic?: string;
  keyFigures?: keyFigureRows[];
}

export interface keyFigureRows {
  topicItem?: string;
  references?: string;
  rows: keyFigureRow[];
}

export interface keyFigureRow {
  label?: string;
  unit?: string;
  target?: number;
  current?: number;
  previous?: number;
}

export const mapKeyFiguresToRow = function (keyFigures: IKeyfigures): keyFigureRow {
  return {
    label: keyFigures.metadata.label,
    unit: keyFigures.currentValue.unitDetails || keyFigures.metadata.unit,
    target:
      keyFigures.targetValue.value || keyFigures.targetValue.value === 0
        ? Number(keyFigures.targetValue.value)
        : undefined,
    current:
      keyFigures.currentValue.value || keyFigures.currentValue.value === 0
        ? Number(keyFigures.currentValue.value)
        : undefined,
    previous:
      keyFigures.previousValue.value || keyFigures.previousValue.value === 0
        ? Number(keyFigures.previousValue.value)
        : undefined,
  };
};

export const getKeyFigureFromMainLevels = function (mainLevels: IMainLevel[]): keyFiguresTable[] {
  const table: keyFiguresTable[] = [];
  mainLevels.map(async mainTopic => {
    const keyFigures: keyFigureRows[] = [];
    const topics = mainTopic.topics || [];
    topics.map(async topic => {
      const topicItems = topic.topicItems || [];
      topicItems.map(async topicItem => {
        let referenceStandards = topicItem.referenceStandards;
        if (topicItem.type === TopicItemType.NewIndicator) {
          referenceStandards = Array.from(
            new Set(
              topicItem.dataValues?.flatMap(value =>
                value.referenceStandards?.replace(/(\r\n|\n|\r)/gm, '\n').split('\n')
              )
            )
          ).join('\n');
        }

        const keyFigure = {
          topicItem: topicItem.name,
          rows:
            topicItem.dataValues
              ?.filter(
                value => value.metadata.type === IndicatorDataValueType.Numeric && value.metadata.isVisibleInReport
              )
              .map(value => mapKeyFiguresToRow(value)) || [],
          references: referenceStandards,
        };
        if (keyFigure.rows.length) keyFigures.push(keyFigure);
      });
    });
    keyFigures.length && table.push({ mainTopic: mainTopic.name, keyFigures: keyFigures });
  });
  return table;
};

export const generateIndicatorKeyFigures = function (
  keyFigures: keyFigureRows,
  substrateData?: ISubstrateData,
  timePeriod?: TimePeriod
): Content {
  const references = keyFigures.references?.replace(/(\r\n|\n|\r)/gm, ', ');
  const isCustomPeriod = timePeriod?.type === TimePeriodType.Custom;
  const tableBody: TableCell[][] = [
    [
      {
        text: '',
        border: [false, false, false, true],
      },
      {
        text: 'UNIT',
        border: [false, false, false, true],
        style: 'indicatorHeading',
        margin: 4,
      },
      {
        text:
          isCustomPeriod || !timePeriod ? 'TARGET' : 'TARGET ' + getTimeLabelFromPeriod(timePeriod, 1).toUpperCase(),
        color: '#23D468',
        alignment: 'right',
        border: [false, false, false, true],
        style: 'indicatorHeading',
        margin: 4,
      },
      {
        text: isCustomPeriod || !timePeriod ? 'CURRENT' : getTimeLabelFromPeriod(timePeriod, 0).toUpperCase(),
        color: '#00B2FF',
        alignment: 'right',
        border: [false, false, false, true],
        style: 'indicatorHeading',
        margin: 4,
      },
      {
        text: isCustomPeriod || !timePeriod ? 'PREVIOUS' : getTimeLabelFromPeriod(timePeriod, -1).toUpperCase(),
        alignment: 'right',
        border: [false, false, false, true],
        style: 'indicatorHeading',
        margin: 4,
      },
    ],
  ];

  if (isCustomPeriod) {
    tableBody.push([
      {
        text: '',
        border: [false, false, false, true],
      },
      {
        text: '',
        border: [false, false, false, true],
      },
      {
        text: getTimeLabelFromPeriod(timePeriod, 1),
        alignment: 'right',
        border: [false, false, false, true],
        style: 'indicatorReference',
        margin: [4, 4, 4, 2],
      },
      {
        text: getTimeLabelFromPeriod(timePeriod, 0),
        alignment: 'right',
        border: [false, false, false, true],
        style: 'indicatorReference',
        margin: [4, 4, 4, 2],
      },
      {
        text: getTimeLabelFromPeriod(timePeriod, -1),
        alignment: 'right',
        border: [false, false, false, true],
        style: 'indicatorReference',
        margin: [4, 4, 4, 2],
      },
    ]);
  }

  keyFigures.rows.map(row => {
    tableBody.push([
      {
        text: row.label || '',
        border: [false, false, false, true],
        style: 'indicatorValue',
        margin: [4, 8, 4, 8],
      },
      {
        text: row.unit || '',
        italics: true,
        border: [false, false, false, true],
        style: 'indicatorValue',
        margin: [4, 8, 4, 8],
      },
      {
        text: row.target !== undefined ? numberToFormattedNumberString(row.target, 0, 2) : ' ',
        alignment: 'right',
        border: [false, false, false, true],
        style: 'indicatorValue',
        margin: [4, 8, 4, 8],
      },
      {
        text: row.current !== undefined ? numberToFormattedNumberString(row.current, 0, 2) : ' ',
        alignment: 'right',
        bold: true,
        border: [false, false, false, true],
        style: 'indicatorValue',
        margin: [4, 8, 4, 8],
      },
      {
        text: row.previous !== undefined ? numberToFormattedNumberString(row.previous, 0, 2) : ' ',
        alignment: 'right',
        border: [false, false, false, true],
        style: 'indicatorValue',
        margin: [4, 8, 4, 8],
      },
    ]);
  });

  if (substrateData) {
    tableBody.push([generateSubstrateData(substrateData, 5), {}, {}, {}, {}]);
  }

  tableBody.push([
    {
      text: references || ' ',
      colSpan: 5,
      margin: [4, 8, 4, 8],
      border: [false, false, false, false],
      style: 'indicatorReference',
    },
    {},
    {},
    {},
    {},
  ]);

  return {
    margin: [122, 0, 14, 20],
    table: {
      widths: ['*', 'auto', '17%', '17%', '17%'],
      body: tableBody,
    },
    layout: {
      hLineColor: '#DDDDDD',
      vLineColor: '#DDDDDD',
      hLineWidth: function () {
        return 0.5;
      },
      vLineWidth: function () {
        return 0;
      },
      paddingRight: function () {
        return 0;
      },
      paddingLeft: function () {
        return 0;
      },
      paddingTop: function () {
        return 0;
      },
      paddingBottom: function () {
        return 0;
      },
    },
  };
};

export const generateKeyFiguresTable = function (
  keyFiguresTables: keyFiguresTable[],
  timePeriod?: TimePeriod,
  organizationName?: string,
  tableHeadColor?: string
): Content[] {
  const isCustomPeriod = timePeriod?.type === TimePeriodType.Custom;
  const tableBody: TableCell[][] = [
    [
      { text: '', bold: true, border: [false, false, false, false], style: 'keyFiguresTableCell' },
      { text: '', bold: true, border: [false, false, false, false], style: 'keyFiguresTableCell' },
      { text: 'Unit', bold: true, border: [false, false, false, false], style: 'keyFiguresTableCell' },
      {
        text: isCustomPeriod || !timePeriod ? 'Target' : 'Target ' + getTimeLabelFromPeriod(timePeriod, 1),
        bold: true,
        border: [false, false, false, false],
        style: 'keyFiguresTableCell',
        alignment: 'right',
      },
      {
        text: isCustomPeriod || !timePeriod ? 'Current' : getTimeLabelFromPeriod(timePeriod, 0),
        bold: true,
        border: [false, false, false, false],
        style: 'keyFiguresTableCell',
        alignment: 'right',
      },
      {
        text: isCustomPeriod || !timePeriod ? 'Previous' : getTimeLabelFromPeriod(timePeriod, -1),
        bold: true,
        border: [false, false, false, false],
        style: 'keyFiguresTableCell',
        alignment: 'right',
      },
      {
        text: 'Reference',
        bold: true,
        border: [true, false, false, false],
        style: 'keyFiguresTableCell',
      },
    ],
  ];

  if (isCustomPeriod) {
    tableBody.push([
      {
        text: '',
        border: [false, true, false, true],
        style: 'keyFiguresTableCell',
      },
      {
        text: '',
        border: [false, true, false, true],
        style: 'keyFiguresTableCell',
      },
      {
        text: '',
        border: [false, true, false, true],
        style: 'keyFiguresTableCell',
      },
      {
        text: getTimeLabelFromPeriod(timePeriod, 1),
        border: [false, true, false, true],
        style: 'keyFiguresTableCell',
        alignment: 'right',
        italics: true,
      },
      {
        text: getTimeLabelFromPeriod(timePeriod, 0),
        border: [false, true, false, true],
        style: 'keyFiguresTableCell',
        alignment: 'right',
        italics: true,
      },
      {
        text: getTimeLabelFromPeriod(timePeriod, -1),
        border: [false, true, false, true],
        style: 'keyFiguresTableCell',
        alignment: 'right',
        italics: true,
      },
      {
        text: '',
        border: [true, true, false, true],
        style: 'keyFiguresTableCell',
      },
    ]);
  }

  keyFiguresTables.map(table => {
    tableBody.push([
      {
        text: table.mainTopic,
        border: [false, true, false, true],
        style: 'keyFiguresTableCell',
        bold: true,
        colSpan: 7,
        fillColor: tableHeadColor ? tableHeadColor : '#bedde0',
        color: tableHeadColor ? pickTextColorBasedOnBgColor(tableHeadColor) : 'black',
      },
    ]);
    table.keyFigures?.map(figures => {
      figures.rows.map(indictorValues =>
        tableBody.push([
          {
            text: figures.topicItem || '',
            border: [false, true, false, true],
            style: 'keyFiguresTableCell',
            rowSpan: figures.rows.length,
            colSpan: figures.rows.length > 1 ? 1 : 2,
          },
          {
            text: indictorValues.label || '',
            border: [false, true, false, true],
            style: 'keyFiguresTableCell',
            italics: true,
          },
          {
            text: indictorValues.unit || '',
            border: [false, true, false, true],
            style: 'keyFiguresTableCell',
            italics: true,
          },
          {
            text: indictorValues.target !== undefined ? numberToFormattedNumberString(indictorValues.target, 0, 2) : '',
            border: [false, true, false, true],
            style: 'keyFiguresTableCell',
            alignment: 'right',
          },
          {
            text:
              indictorValues.current !== undefined ? numberToFormattedNumberString(indictorValues.current, 0, 2) : '',
            border: [false, true, false, true],
            bold: true,
            style: 'keyFiguresTableCell',
            alignment: 'right',
          },
          {
            text:
              indictorValues.previous !== undefined ? numberToFormattedNumberString(indictorValues.previous, 0, 2) : '',
            border: [false, true, false, true],
            style: 'keyFiguresTableCell',
            alignment: 'right',
          },
          {
            text: figures.references || '',
            border: [true, true, false, true],
            style: 'keyFiguresTableCell',
            italics: true,
            rowSpan: figures.rows.length,
          },
        ])
      );
    });
  });

  return [
    {
      text: organizationName?.toUpperCase() || '',
      style: 'mainTopicTitleLeft',
      margin: [23, 0, 0, 11],
      pageBreak: 'before',
    },
    {
      text: isCustomPeriod || !timePeriod ? 'Key Figures' : 'Key Figures ' + getTimeLabelFromPeriod(timePeriod, 0),
      style: 'keyFigureTitle',
      margin: [23, 0, 0, 20],
    },
    {
      table: {
        headerRows: 1,
        widths: ['15%', '15%', '10%', '15%', '15%', '15%', '15%'],
        body: tableBody,
      },
      margin: [23, 0, 0, 0],
      layout: {
        hLineColor: '#CCCCCC',
        vLineColor: '#CCCCCC',
        hLineWidth: function () {
          return 0.5;
        },
        vLineWidth: function () {
          return 0.5;
        },
      },
    },
  ];
};
