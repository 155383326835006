<esg-form-dialog
  [submitText]="state.submitText"
  [submitDisabled]="!state.formgroup || !state.formgroup.valid || !state.formgroup.dirty"
  (onCancel)="onCancel.emit()"
  (onSubmit)="handleFormSubmit()">
  <div class="container">
    <header>{{ state.headerText }}</header>
    <div *ngIf="state.articleFc" class="content article">
      <span class="description">This will create an article for adding content that is not indicators.</span>
      <esg-textbox-form
        class="title"
        label="TITLE"
        placeholder="Placeholder"
        [fc]="state.articleFc.title"></esg-textbox-form>
    </div>
  </div>
</esg-form-dialog>
