<esg-dialog class="auto" (onClose)="handleOnCancel()" (keydown.enter)="!submitDisabled && handleOnSubmit()">
  <div #container class="container">
    <div class="content">
      <ng-content></ng-content>
    </div>

    <div
      class="scroll-observer"
      esgIntersectionObserver
      [root]="container"
      [threshold]="0"
      rootMargin="0px 0px -103px 0px"
      (onIntersection)="handleStickyDivider($event)"></div>
    <div class="footer" [class.expand-line]="expandStickyDivider">
      <esg-button class="tertiary" (onClick)="handleOnCancel()">Cancel</esg-button>
      <esg-button [disabled]="submitDisabled" (onClick)="handleOnSubmit()">{{ submitText }} </esg-button>
    </div>
  </div>
</esg-dialog>
