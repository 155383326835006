<div *ngIf="state.predefinedIndicator" class="content">
  <h2 class="title">{{ state.predefinedIndicator.name }}</h2>

  <div
    class="section-header"
    *ngIf="state.predefinedIndicator.type === predefinedIndicatorType.DataValuePredefinedIndicator">
    1. Details
  </div>
  <div class="two-columns">
    <div>
      <esg-textarea
        label="REFERENCE STANDARDS"
        placeholder="i.e GRI 305-1"
        [fc]="state.predefinedIndicator.referenceStandards"
        [heigth]="69"></esg-textarea>
    </div>
    <div>
      <esg-textarea
        label="GUIDANCE"
        placeholder="Add a guidance text here..."
        [fc]="state.predefinedIndicator.guidance"
        [heigth]="69"></esg-textarea>
    </div>
  </div>

  <ng-container *ngIf="state.predefinedIndicator.type === predefinedIndicatorType.DataValuePredefinedIndicator">
    <div class="section-header">2. Calculated data values</div>

    <div class="two-columns">
      <esg-dropdown-multiple-form
        [fc]="state.predefinedIndicator.dataValues"
        [options]="state.predefinedIndicatorTypeOptions"></esg-dropdown-multiple-form>
    </div>
    <div class="section-header">3. Substrate data</div>

    <div class="two-columns">
      <esg-checkbox-form
        label="Show substrate data"
        [fc]="state.predefinedIndicator.showSubstrateData"></esg-checkbox-form>
    </div>
  </ng-container>
</div>
