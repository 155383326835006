import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import {
  AssetClient,
  CreateAssetCommandParams,
  CreateAssetCommandResponse,
  UpdateAssetCommandParams,
  UpdateAssetCommandResponse,
  GetAssetTypesByOrganizationQueryResponse,
  AssetType,
  GetAssetsByOrganizationAndAvailableForLeasingQueryResponse,
  DeleteAssetVersionCommandParams,
  UpdateAssetBasePropertiesCommandParams,
  UpdateAssetBasePropertiesCommandResponse,
} from '../../../api-client/report-api.generated';

@Injectable({
  providedIn: 'root',
})
export class AssetApiService {
  client: AssetClient;

  constructor(readonly httpClient: HttpClient, readonly appSettings: AppConfigurationService) {
    this.client = new AssetClient(httpClient, appSettings.ReportApiBaseUrl);
  }

  async createAsset(body: CreateAssetCommandParams): Promise<CreateAssetCommandResponse> {
    const observable$ = this.client.createAsset(body).pipe(
      catchError(error => {
        console.log(error.errors);
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  async updateAsset(body: UpdateAssetCommandParams): Promise<UpdateAssetCommandResponse> {
    const observable$ = this.client.updateAsset(body).pipe(
      catchError(error => {
        console.log(error.errors);
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  async updateAssetBaseProperties(
    body: UpdateAssetBasePropertiesCommandParams
  ): Promise<UpdateAssetBasePropertiesCommandResponse> {
    const observable$ = this.client.updateAssetBaseProperties(body).pipe(
      catchError(error => {
        console.log(error.errors);
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  async getAssetsByOrganizationAndType(subOrganizationId: string | undefined, assetType: AssetType) {
    const observable$ = this.client.getAssetsByOrganizationAndType(subOrganizationId, assetType).pipe(
      catchError(error => {
        console.log(error.errors);
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  async getAssetsByOrganizationAndAvailableForLeasing(): Promise<GetAssetsByOrganizationAndAvailableForLeasingQueryResponse> {
    const observable$ = this.client.getAssetsByOrganizationAndAvailableForLeasing().pipe(
      catchError(error => {
        console.log(error.errors);
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  async deleteAsset(id: string) {
    const observable$ = this.client.deleteAsset(id).pipe(
      catchError(error => {
        console.log(error.errors);
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  async deleteAssetVersion(id: string, versionId: string) {
    const observable$ = this.client
      .deleteAssetVersion(new DeleteAssetVersionCommandParams({ assetId: id, versionId: versionId }))
      .pipe(
        catchError(error => {
          console.log(error.errors);
          return throwError(() => error);
        })
      );
    return await lastValueFrom(observable$);
  }

  async getAssetTypesByOrganization(
    subOrganizationId: string | undefined
  ): Promise<GetAssetTypesByOrganizationQueryResponse> {
    const observable$ = this.client.getAssetTypesByOrganization(subOrganizationId).pipe(
      catchError(error => {
        console.log(error.errors);
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  async getAssetById(id: string, versionId: string) {
    const observable$ = this.client.getAssetById(id, versionId).pipe(
      catchError(error => {
        console.log(error.errors);
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  async getAssetVersions(id: string) {
    const observable$ = this.client.getAssetVersionsById(id).pipe(
      catchError(error => {
        console.log(error.errors);
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  async getAllAssets() {
    const observable$ = this.client.getAllAssets().pipe(
      catchError(error => {
        console.log(error.errors);
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  async getAllAssetOptions() {
    const observable$ = this.client.getAssetList();
    return await lastValueFrom(observable$);
  }
}
