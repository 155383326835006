import { Component, Input, SimpleChanges } from '@angular/core';
import { IndicatorDataValueType, TimePeriod } from 'src/api-client/report-api.generated';
import { getTimeLabelFromPeriod } from 'src/app/static-data/time-labels';
import { formatNumbersInString } from 'src/app/shared/utils/number-converters';
import { IKeyfigures, ITableKeyFigureValue } from '../indicator-preview/indicator-preview.component';

interface CalculationTableUi {
  tHead: TrUi[];
  tBody: TrUi[];
}

interface TrUi {
  items: TrItemUi[];
}

interface TrItemUi {
  rowSpan?: number;
  colSpan?: number;
  content?: string;
  unit?: string;
  isNumber: boolean;
  backgroundColor?: string;
}

@Component({
  selector: 'esg-calculation-table',
  templateUrl: './calculation-table.component.html',
  styleUrls: ['./calculation-table.component.scss'],
})
export class CalculationTableComponent {
  @Input({ required: true }) calculatedData!: IKeyfigures;
  @Input() references?: string;
  @Input() timePeriod?: TimePeriod;

  calculatedTable?: CalculationTableUi;

  getFormattedNumbers = formatNumbersInString;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.calculatedData && changes.calculatedData.currentValue) {
      const labelTable: CalculationTableUi = this.createLabelTable(this.calculatedData);
      const currentTable: CalculationTableUi = this.createValueTable(
        this.calculatedData,
        this.calculatedData.currentValue.tableRows || [],
        0,
        '#f4f4f4'
      );
      const previousTable: CalculationTableUi = this.createValueTable(
        this.calculatedData,
        this.calculatedData.previousValue.tableRows || [],
        -1
      );

      this.calculatedTable = this.combineTables(labelTable, currentTable, previousTable);
    }
  }

  getFigureTimeLabel(shift: number) {
    if (this.timePeriod) {
      return getTimeLabelFromPeriod(this.timePeriod, shift);
    }
    return '';
  }

  combineTables(
    labelTable: CalculationTableUi,
    currentTable: CalculationTableUi,
    previousTable: CalculationTableUi
  ): CalculationTableUi {
    const combinedTable: CalculationTableUi = { tHead: [], tBody: [] };

    for (let i = 0; i < labelTable.tHead.length; i++) {
      const currentRow = i < currentTable.tHead.length ? currentTable.tHead[i] : { items: [] };
      const previousRow = i < previousTable.tHead.length ? previousTable.tHead[i] : { items: [] };

      const combinedRow: TrUi = {
        items: [...labelTable.tHead[i].items, ...currentRow.items, ...previousRow.items],
      };

      combinedTable.tHead.push(combinedRow);
    }

    for (let i = 0; i < labelTable.tBody.length; i++) {
      const currentRow =
        i < currentTable.tBody.length
          ? currentTable.tBody[i]
          : i < previousTable.tBody.length
          ? { items: previousTable.tBody[i].items.map(item => ({ content: '-', isNumber: item.isNumber })) }
          : { items: [] };
      const previousRow =
        i < previousTable.tBody.length
          ? previousTable.tBody[i]
          : i < currentTable.tBody.length
          ? { items: currentTable.tBody[i].items.map(item => ({ content: '-', isNumber: item.isNumber })) }
          : { items: [] };
      const combinedRow: TrUi = {
        items: [...labelTable.tBody[i].items, ...currentRow.items, ...previousRow.items],
      };

      combinedTable.tBody.push(combinedRow);
    }

    return combinedTable;
  }

  createLabelTable(data: IKeyfigures) {
    const tHead: TrUi[] = [{ items: [{ content: '', isNumber: false }] }];

    if (data.metadata.tableColumnsMetadata?.length) {
      const hasGroupLabel = data.metadata.tableColumnsMetadata.some(column => column.groupLabel);

      if (hasGroupLabel) {
        tHead.push({ items: [{ content: '', isNumber: false }] });
      }

      const firstColumn = data.metadata.tableColumnsMetadata[0];

      tHead.push({
        items: [
          {
            content: firstColumn.label || '',
            isNumber: firstColumn.type === IndicatorDataValueType.Numeric,
          },
        ],
      });
    }

    const tableRows = data.currentValue.tableRows ? data.currentValue.tableRows : data.previousValue.tableRows;

    const tBody: TrUi[] = (tableRows || []).map(row => ({
      items: row?.values?.length
        ? [
            {
              content: (row.values[0]?.value || '').toString(),
              isNumber: row.values[0]?.type === IndicatorDataValueType.Numeric,
            },
          ]
        : [],
    }));

    return { tHead, tBody };
  }

  createValueTable(data: IKeyfigures, tableRows: ITableKeyFigureValue[], shift: number, backgroundColor?: string) {
    const tHead: TrUi[] = [];

    tHead.push({
      items: [
        {
          content: this.getFigureTimeLabel(shift),
          isNumber: false,
          colSpan: data.metadata.tableColumnsMetadata?.length ? data.metadata.tableColumnsMetadata.length - 1 : 1,
          backgroundColor: backgroundColor,
        },
      ],
    });

    const units: string[] = [];
    const groups: string[] = [];
    if (data.metadata.tableColumnsMetadata?.length) {
      const groupItems: TrItemUi[] = [];
      let currentGroup = undefined;
      let groupColSpan = 1;
      const items: TrItemUi[] = [];
      for (let i = 1; i < data.metadata.tableColumnsMetadata.length; i++) {
        if (data.metadata.tableColumnsMetadata[i].groupLabel !== currentGroup) {
          if (currentGroup) {
            groupItems.push({
              content: currentGroup,
              isNumber: false,
              colSpan: groupColSpan,
              backgroundColor: backgroundColor,
            });
          }
          currentGroup = data.metadata.tableColumnsMetadata[i].groupLabel;
          groupColSpan = 1;
        } else if (currentGroup) {
          groupColSpan++;
        }

        units.push(
          data.metadata.tableColumnsMetadata[i].unit === 'Number'
            ? ''
            : data.metadata.tableColumnsMetadata[i].unit || ''
        );
        groups.push(data.metadata.tableColumnsMetadata[i].groupLabel || '');

        items.push({
          content: data.metadata.tableColumnsMetadata[i].label || '',
          isNumber: data.metadata.tableColumnsMetadata[i].type === IndicatorDataValueType.Numeric,
          backgroundColor: backgroundColor,
        });
      }

      if (groupColSpan > 1)
        groupItems.push({
          content: currentGroup,
          isNumber: false,
          colSpan: groupColSpan,
          backgroundColor: backgroundColor,
        });

      if (groupItems.length) tHead.push({ items: groupItems });

      tHead.push({ items });
    }

    const tBody: TrUi[] = [];

    for (const row of tableRows) {
      const items: TrItemUi[] = [];

      if (row.values) {
        for (let j = 1; j < row.values.length; j++) {
          items.push({
            content: row.values[j]?.value?.toString(),
            unit: row.values[j]?.unitDetails || units[j - 1],
            isNumber: row.values[j]?.type === IndicatorDataValueType.Numeric,
            backgroundColor: backgroundColor,
          });
        }
      }
      tBody.push({ items });
    }
    return { tHead, tBody };
  }
}
