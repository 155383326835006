<ng-content select="[handle]"></ng-content>
<span class="type"><ng-content select="[typeIcon]"></ng-content></span>
<img class="image" *ngIf="imgSrc" [src]="imgSrc" />
<span class="title">{{ text }}</span>
<button class="remove" (click)="handleDelete()">
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.16116 15H10.845C11.7314 15 12.3264 14.4154 12.3698 13.5164L12.7975 4.37584H13.5413C13.7955 4.37584 14 4.15581 14 3.89807C14 3.64032 13.7955 3.42658 13.5413 3.42658H10.7087V2.46475C10.7087 1.55321 10.126 1 9.17149 1H6.81612C5.86157 1 5.27893 1.55321 5.27893 2.46475V3.42658H2.46488C2.21694 3.42658 2 3.64032 2 3.89807C2 4.1621 2.21694 4.37584 2.46488 4.37584H3.20868L3.63636 13.5227C3.67975 14.4216 4.2624 15 5.16116 15ZM6.26446 2.52762C6.26446 2.16929 6.5124 1.93669 6.8843 1.93669H9.10331C9.47521 1.93669 9.72314 2.16929 9.72314 2.52762V3.42658H6.26446V2.52762ZM5.24793 14.0507C4.89463 14.0507 4.6281 13.7804 4.6095 13.4095L4.17562 4.37584H11.7996L11.3905 13.4095C11.3781 13.7867 11.1116 14.0507 10.7459 14.0507H5.24793ZM6.16529 12.9758C6.40083 12.9758 6.55579 12.8249 6.54959 12.6048L6.35744 5.87831C6.35124 5.65828 6.19628 5.5137 5.97314 5.5137C5.7376 5.5137 5.58264 5.66457 5.58884 5.8846L5.77479 12.6048C5.78099 12.8312 5.93595 12.9758 6.16529 12.9758ZM8 12.9758C8.23554 12.9758 8.40289 12.8249 8.40289 12.6048V5.8846C8.40289 5.66457 8.23554 5.5137 8 5.5137C7.76446 5.5137 7.60331 5.66457 7.60331 5.8846V12.6048C7.60331 12.8249 7.76446 12.9758 8 12.9758ZM9.84091 12.9758C10.064 12.9758 10.219 12.8312 10.2252 12.6048L10.4112 5.8846C10.4174 5.66457 10.2624 5.5137 10.0269 5.5137C9.80372 5.5137 9.64876 5.65828 9.64256 5.8846L9.45661 12.6048C9.45041 12.8249 9.60537 12.9758 9.84091 12.9758Z"
      fill="#999999" />
  </svg>
</button>
