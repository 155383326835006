import { Injectable } from '@angular/core';
import { OrganizationsStateService } from '../organizations-state.service';
import { Permission } from 'src/api-client/report-api.generated';

@Injectable()
export class AllOrganizationsStateService extends OrganizationsStateService {
  getPermissions() {
    this.userInfoSub = this.appInfoState.userInfo$.subscribe(() => {
      this.hasAddPermission = this.appInfoState.hasPermission(Permission.Report_Organization_Create);
      this.hasEditPermission = this.appInfoState.hasPermission(Permission.Report_Organization_Update);
      this.hasDeletePermission = this.appInfoState.hasPermission(Permission.Report_Organization_Delete);
    });
  }

  initData() {
    this.getCountries();
    this.loadOrganizations();
  }

  ngOnDestroy() {
    this.userInfoSub.unsubscribe();
  }
}
