<esg-records-table
  [headerCells]="headerCells"
  [bodyRows]="dataRecordsRows"
  [newlyAddedRowsIds]="newlyAddedRowsIds"
  [readOnly]="readOnly"
  [addOnly]="addOnly"
  [isAdding]="recordFormgroup !== undefined && !editRecordId"
  [editRecordId]="editRecordId"
  [stickyHeaderTopOffset]="stickyHeaderTopOffset"
  [intersectionObserverRootMargin]="intersectionObserverRootMargin"
  [stickyHeaderPadding]="stickyHeaderPadding"
  (onAdding)="handleOnIniateAddRecord()"
  (onFormClose)="handleCloseForm()"
  (onEditing)="handleOnInitateEditRecord($event)"
  (onSubmit)="handleOnSubmit()"
  (onDelete)="handleOnDelete($event)">
  <ng-container *ngIf="recordFc">
    <td class="datepicker-cell" [class.focus]="startDateOpen">
      <esg-datepicker
        *ngIf="recordFc.startDate"
        class="input-text"
        [fc]="recordFc.startDate"
        variant="ghost-table"
        [maxDate]="recordFc.endDate.value || undefined"
        [inputIndent]="15"
        [autoFocus]="startDateOpen"
        (onOpen)="startDateOpen = true"
        (onClose)="onStartDateClose()"></esg-datepicker>
    </td>
    <td class="datepicker-cell" [class.focus]="endDateOpen">
      <span class="date-divider" [class.focus]="startDateOpen"><span class="triangle"></span></span>
      <esg-datepicker
        *ngIf="recordFc.endDate"
        class="input-text"
        [fc]="recordFc.endDate"
        variant="ghost-table"
        [minDate]="recordFc.startDate.value || undefined"
        [inputIndent]="13"
        [autoFocus]="endDateOpen"
        (onOpen)="endDateOpen = true"
        (onClose)="endDateOpen = false"></esg-datepicker>
    </td>
    <td class="input-cell" [class.disabled]="isAssetsDisabled">
      <esg-table-select
        *ngIf="recordFc.asset"
        class="input-dropdown"
        [isPopup]="addOnly"
        [groupedOptions]="assetOptions"
        placeholder="Select Asset"
        [fitLongestLabel]="false"
        [value]="recordFc.asset.value || undefined"
        [disabled]="isAssetsDisabled"
        (onChange)="handleDropdownChange(recordFc.asset, $event)">
      </esg-table-select>
    </td>
  </ng-container>
</esg-records-table>
