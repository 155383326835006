<esg-form-dialog
  submitText="Set Target"
  [submitDisabled]="state.targetValues === state.initTargetValues"
  (onCancel)="onClose.emit()"
  (onSubmit)="handleSetTarget()">
  <div class="content-container">
    <div class="title">Set Target</div>
    <div class="content">
      <div class="left-section">
        <div class="base">
          <div class="control">
            <div class="label">Base year</div>
            <esg-textbox-form
              placeholder="e.g 2008"
              [fc]="state.fc.baseYear"
              [maxLength]="4"
              type="digit"
              (onEnter)="state.fc.baseYear.markAsTouched()"
              (onChange)="state.fc.baseYear.setValue($event)"
              [valid]="!state.fc.baseYear.dirty || !state.baseYearValidationMessage"></esg-textbox-form>
            <div class="error-message-container">
              <span *ngIf="state.baseYearValidationMessage" class="unvalid">{{ state.baseYearValidationMessage }}</span>
            </div>
          </div>
          <div class="control" style="margin-bottom: 40px">
            <div class="label">Base year value</div>
            <esg-textbox-form
              placeholder="e.g 200"
              [fc]="state.fc.baseYearValue"
              type="digit"
              [valid]="!state.fc.baseYearValue.dirty || state.fc.baseYearValue.valid"
              (onChange)="state.fc.baseYearValue.setValue($event)"></esg-textbox-form>
          </div>
        </div>
        <div class="target">
          <div class="control">
            <div class="label">Target year</div>
            <esg-textbox-form
              placeholder="e.g 2030"
              [fc]="state.fc.targetYear"
              [maxLength]="4"
              type="digit"
              (onEnter)="state.fc.targetYear.markAsTouched()"
              (onChange)="state.fc.targetYear.setValue($event)"
              [valid]="!state.fc.baseYear.dirty || !state.targetYearValidationMessage"></esg-textbox-form>
            <div class="error-message-container">
              <span *ngIf="state.targetYearValidationMessage" class="unvalid">
                {{ state.targetYearValidationMessage }}
              </span>
            </div>
          </div>
          <div class="target-value-line">
            <div class="control">
              <div class="label">Target %</div>
              <esg-select [formControl]="state.fc.targetTrendType" [options]="state.targetTrendOptions"></esg-select>
            </div>
            <div class="control">
              <div class="label"></div>
              <esg-textbox-form
                placeholder="e.g 50"
                [showPercent]="true"
                [fc]="state.fc.targetPerecentChange"
                [valid]="!state.fc.targetPerecentChange.dirty || state.fc.targetPerecentChange.valid"
                type="digit"
                (onChange)="state.fc.targetPerecentChange.setValue($event)"></esg-textbox-form>
            </div>
            <span>or</span>
            <div class="control">
              <div class="label">Target value</div>
              <esg-textbox-form
                placeholder="e.g 130.57"
                [fc]="state.fc.targetValue"
                [valid]="!state.fc.targetValue.dirty || state.fc.targetValue.valid"
                type="digit"
                (onChange)="state.fc.targetValue.setValue($event)"></esg-textbox-form>
            </div>
          </div>
        </div>
        <div class="cla-line">
          <esg-button size="small" class="tertiary" (onClick)="state.generateTargetValues()">
            <span>Generate target years</span>
            <esg-arrow-right color="#0071E3" size="24"></esg-arrow-right>
          </esg-button>
        </div>
      </div>
      <div class="line-between"></div>
      <div class="right-section">
        <table>
          <tr>
            <th>Year</th>
            <th>Value</th>
          </tr>
          <tr *ngFor="let target of state.targetValues">
            <td>{{ target.year }}</td>
            <td>
              {{ target.value }}
              <span
                [innerHTML]="indicatorUnit.name === 'Number' ? '' : (indicatorUnit.name | formatUnitWithHtml)"></span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</esg-form-dialog>
