<div *ngIf="topicItem" class="top-content" [class.fade]="componentsState.fade">
  <div class="topic-info">
    <div *ngIf="!topicItem.visible" class="deactivated-msg">Deactivated. This Item will not show in report</div>
    <esg-header-with-guidance
      *ngIf="topicItem.description"
      [label]="topicItem.name ? topicItem.name : ''"
      [guidance]="topicItem.description"></esg-header-with-guidance>
    <h2 *ngIf="!topicItem.description">{{ topicItem.name || '' }}</h2>
  </div>
</div>

<div *ngIf="topicItem && isNumericIndicator" class="key-figures" [class.fade]="componentsState.fade">
  <div class="first-line">
    <div *ngIf="isCalculatedIndicator" class="right-toolbar">
      <button
        (click)="handleCalculateIndicator()"
        (mouseenter)="!isCalculationDisabled && state.setCalculationIsHovered(true)"
        (mouseleave)="!isCalculationDisabled && state.setCalculationIsHovered(false)"
        [disabled]="isCalculationDisabled">
        <esg-cloud-update-icon
          *ngIf="!state.calculateIsHovered"
          [color]="isCalculationDisabled ? '#CCCCCC' : '#0071E3'"></esg-cloud-update-icon>
        <esg-cloud-update-filled-icon *ngIf="state.calculateIsHovered" color="#0071E3"></esg-cloud-update-filled-icon>
        Update all {{ isNewIndicator ? 'indicators' : 'from Data Collect' }}
      </button>
      <span class="last-update" [class.hidden]="!isCalculationDisabled && !state.lastUpdated">
        {{
          isCalculationDisabled
            ? 'No values connected'
            : 'Last updated ' + (state.lastUpdated | date : 'dd. MMM yyyy HH:mm' || '')
        }}
      </span>
    </div>
  </div>
  <esg-indicator-values
    *ngIf="state.numericalValues && state.numericalValues.length"
    class="numerical-values"
    [indicatorValues]="state.numericalValues"
    [timePeriod]="timePeriod"
    [isCalculated]="isCalculatedIndicator"
    (onSubmit)="state.handleOnValueSubmit($event, this.topicItem.id, state.numericalValues)"
    (onSetConnection)="state.handleOnSetDataCollectorConnection(this.topicItem.id, state.numericalValues, $event)"
    (onGoToDatacollect)="handleGoToDatacollect()"></esg-indicator-values>

  <div
    *ngIf="state.tableValues && state.tableValues.length"
    class="table-values"
    [class.extra-margin]="state.numericalValues && state.numericalValues.length">
    <ng-container *ngFor="let table of state.tableValues">
      <esg-calculation-table
        *ngIf="table.metadata.isVisibleInReport"
        [calculatedData]="table"
        [timePeriod]="timePeriod"></esg-calculation-table>
    </ng-container>
  </div>
</div>

<div *ngIf="showReferences" class="bottom-content" [class.fade]="componentsState.fade">
  <div class="reference-standards">
    <span class="header">
      <esg-framework-icon></esg-framework-icon>
      Reference Standards
    </span>
    <span class="standards">{{ formattedReferenceStandards }}</span>
  </div>
</div>
<div
  class="content-menu-observer"
  esgIntersectionObserver
  [root]="hostRef.nativeElement.parentNode"
  rootMargin="-56px 0px 0px 0px"
  (onIntersection)="handleStickyComponentMenu($event)"></div>
<esg-content
  [contentItems]="componentsState.componentsList"
  [stickyMenu]="stickyComponentsToolbar"
  [stickyTopMargin]="56"
  [loading]="componentsState.loading"
  (onAddContent)="componentsState.handleOnAddComponent($event)"
  (onMoveContent)="componentsState.moveComponent($event)"
  (onDeleteContent)="componentsState.deleteComponent($event)"
  (onReorder)="handleOnReorder($event)"
  [onChange]="componentsState.onDataChangeComponent"></esg-content>
<esg-dot-flashing *ngIf="!topicItem" class="loader" label="We are loading content details"></esg-dot-flashing>
