<esg-form-dialog
  [submitText]="submitText"
  [submitDisabled]="!formgroup.valid && !formgroup.dirty"
  (onCancel)="onCancel.emit()"
  (onSubmit)="onSubmit.emit()">
  <div class="container">
    <header>{{ header }}</header>

    <div class="content">
      <div class="section">
        <span class="section-header"> 1. Choose time period for the profile </span>
        <esg-time-period
          [timePeriod]="timePeriodFc.value"
          [numberOfYearsBefore]="2"
          (onChange)="handleTimePeriodChange($event)"></esg-time-period>
      </div>
      <div *ngIf="!hideInputType" class="section">
        <span class="section-header"> 2. Choose profile type </span>
        <esg-select
          [options]="inputOptions"
          [value]="inputTypeFc.value"
          [disabled]="inputTypeFc.disabled"
          [isPopup]="true"
          (onChange)="handleInputTypeChange($event)">
        </esg-select>
      </div>
    </div>
  </div>
</esg-form-dialog>
