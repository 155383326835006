<div class="container">
  <div class="content">
    <ng-container *ngIf="!state.isLoadingKpis">
      <div class="add-panel">
        <div class="new-kpi" esg-on-click-outside (onClickOutside)="state.newKpiContextMenu(false)">
          <esg-add-button
            label="Add new KPI"
            variant="filled"
            size="standard"
            (onClick)="state.newKpiContextMenu(true)"></esg-add-button>
          <div *ngIf="state.newKpiContextMenuOpen" class="new-kpi-options">
            <div class="option" *ngFor="let item of state.newKpiOptions" (click)="state.newKpiSelected(item.value)">
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!!state.kpis.length">
        <esg-kpi-chart *ngFor="let kpiData of state.kpis" [kpiData]="kpiData"></esg-kpi-chart>
      </ng-container>
      <div *ngIf="!state.kpis.length" class="no-kpi-added">
        <span>You have yet not created any KPIs.</span>
        <span>Please create a new KPI.</span>
      </div>
    </ng-container>
    <esg-dot-flashing *ngIf="state.isLoadingKpis" class="loader" label="Loading KPIs"></esg-dot-flashing>
  </div>
</div>

<esg-set-target-dialog
  *ngIf="!!state.targetDialogForKpiId"
  [kpiId]="state.targetDialogForKpiId"
  (onClose)="state.closeTargetDialog()"
  (onUpdate)="state.onUpdatedTarget($event)">
</esg-set-target-dialog>
