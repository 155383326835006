import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownArrowComponent } from '../icons/dropdown-arrow.component';
import { CheckmarkComponent } from '../icons/checkmark.component';
import { ArrowRightComponent } from '../icons/arrow-right.component';
import { ArrowRightLongComponent } from '../icons/arrow-right-long.component';
import { ArrowDownComponent } from '../icons/arrow-down.component';
import { EllipsisHorizontalComponent } from '../icons/ellipsis-horizontal.component';
import { EllipsisVerticalComponent } from '../icons/ellipsis-vertical.component';
import { CopyLinkComponent } from '../icons/copy-link.component';
import { ChevronComponent } from '../icons/chevron.component';
import { SaveTemplateComponent } from '../icons/save-template.component';
import { TextIconComponent } from '../icons/text-icon.component';
import { ImageIconComponent } from '../icons/image-icon.component';
import { LogoutIconComponent } from '../icons/logout-icon.component';
import { DocumentIconComponent } from '../icons/document-icon.component';
import { PreviewIconComponent } from './preview-icon.component';
import { GroupIconComponent } from './group-icon.component';
import { AddIconComponent } from './add-icon.component';
import { MinusIconComponent } from './minus-icon.component';
import { CloseIconComponent } from './close-icon.component';
import { ClearIconComponent } from './clear-icon.component';
import { ExtLinkIconComponent } from './ext-link-icon.component';
import { SettingsIconComponent } from './settings-icon.component';
import { UpdateIconComponent } from './update.component';
import { ImportFromExcelIconComponent } from './import-from-excel-icon.component';
import { DragIconComponent } from './drag-icon.component';
import { InfoIconFilledComponent } from './info-icon-filled.component';
import { InfoIconComponent } from './info-icon.component';
import { CloudLockedIconComponent } from './cloud-locked-icon.component';
import { CloudUpdateIconComponent } from './cloud-update-icon.component';
import { CloudUpdateFilledIconComponent } from './cloud-update-filled-icon.component';
import { CloudDisconnectedIconComponent } from './cloud-disconnected-icon.component';
import { DeleteIconComponent } from './delete-icon.component';
import { BotIconComponent } from './bot-icon.component';
import { LockIconComponent } from './lock-icon.component';
import { NoLockIconComponent } from './no-lock-icon.component';
import { PaperplaneIconComponent } from './paperplane-icon.component';
import { MenuIconComponent } from './menu-icon.component';
import { ShareIconComponent } from './share-icon.component';
import { ExpanderIconComponent } from './expanders.component';
import { DeleteXmarkIconComponent } from './delete-xmark.component';
import { MoveIconComponent } from './move.component';
import { FrameworkIconComponent } from './framework-icon.component';
import { ChartIconComponent } from './chart-icon.component';
import { IconTargetComponent } from './target.component';
import { DoubleChevronComponent } from './double-chevron.component';
import { HomeIconComponent } from './home-icon.component';
import { RegionIconComponent } from './region-icon.component';
import { ExcelIconComponent } from './excel-icon.component';
import { DownloadIconComponent } from './download-icon.component';
import { OrganizationIconComponent } from './organization-icon.component';
import { SuborgIconComponent } from './suborg-icon.component';
import { DashboardIconComponent } from './dashboard-icon.component';
import { ReportIconComponent } from './report-icon.component';
import { SupportIconComponent } from './support-icon.component';
import { NewIconComponent } from './new-icon.component';
import { HistoryIconComponent } from './history-icon.component';
import { CheckCircleIconComponent } from './check-circle-icon.component';
import { RecordsIconComponent } from './records-icon.component';
import { AssetIconComponent } from './asset-icon.component';
import { WindowIconComponent } from './window-icon.component';
import { PlayIconComponent } from './play-icon.component';
import { KpiIconComponent } from './kpi-icon.component';
import { IndLibraryIconComponent } from './ind-library.component';
import { EnvironmentIconComponent } from './environment-icon.component';
import { SocialIconComponent } from './social-icon.component';
import { GovernanceIconComponent } from './governance-icon.component';
import { StackIconComponent } from './stack-icon.component';
import { RawDataIconComponent } from './raw-data-icon.component';
import { WarningIconComponent } from './warning-icon.component';
import { ColumnStackIconComponent } from './column-stack-icon.component';
import { CompanyIconComponent } from './company-icon.component';
import { CalculationIconComponent } from './calculation-icon.component';
import { ConvertIconComponent } from './convert-icon.component';

@NgModule({
  declarations: [
    DropdownArrowComponent,
    CheckmarkComponent,
    ArrowRightComponent,
    ArrowRightLongComponent,
    ArrowDownComponent,
    EllipsisHorizontalComponent,
    EllipsisVerticalComponent,
    CopyLinkComponent,
    ChevronComponent,
    SaveTemplateComponent,
    TextIconComponent,
    ImageIconComponent,
    LogoutIconComponent,
    DocumentIconComponent,
    PreviewIconComponent,
    AddIconComponent,
    GroupIconComponent,
    MinusIconComponent,
    CloseIconComponent,
    ClearIconComponent,
    ExtLinkIconComponent,
    SettingsIconComponent,
    UpdateIconComponent,
    ImportFromExcelIconComponent,
    DragIconComponent,
    InfoIconFilledComponent,
    InfoIconComponent,
    CloudLockedIconComponent,
    CloudUpdateIconComponent,
    CloudUpdateFilledIconComponent,
    CloudDisconnectedIconComponent,
    DeleteIconComponent,
    BotIconComponent,
    LockIconComponent,
    NoLockIconComponent,
    PaperplaneIconComponent,
    ShareIconComponent,
    MenuIconComponent,
    ExpanderIconComponent,
    DeleteXmarkIconComponent,
    MoveIconComponent,
    FrameworkIconComponent,
    ChartIconComponent,
    IconTargetComponent,
    DoubleChevronComponent,
    HomeIconComponent,
    RegionIconComponent,
    ExcelIconComponent,
    DownloadIconComponent,
    OrganizationIconComponent,
    SuborgIconComponent,
    DashboardIconComponent,
    ReportIconComponent,
    SupportIconComponent,
    HistoryIconComponent,
    NewIconComponent,
    CheckCircleIconComponent,
    RecordsIconComponent,
    AssetIconComponent,
    WindowIconComponent,
    PlayIconComponent,
    KpiIconComponent,
    IndLibraryIconComponent,
    EnvironmentIconComponent,
    SocialIconComponent,
    GovernanceIconComponent,
    StackIconComponent,
    RawDataIconComponent,
    WarningIconComponent,
    ColumnStackIconComponent,
    CompanyIconComponent,
    CalculationIconComponent,
    ConvertIconComponent,
  ],
  imports: [CommonModule],
  exports: [
    DropdownArrowComponent,
    CheckmarkComponent,
    ArrowRightComponent,
    ArrowRightLongComponent,
    ArrowDownComponent,
    EllipsisHorizontalComponent,
    EllipsisVerticalComponent,
    CopyLinkComponent,
    ChevronComponent,
    SaveTemplateComponent,
    TextIconComponent,
    ImageIconComponent,
    LogoutIconComponent,
    DocumentIconComponent,
    PreviewIconComponent,
    AddIconComponent,
    GroupIconComponent,
    MinusIconComponent,
    CloseIconComponent,
    ClearIconComponent,
    ExtLinkIconComponent,
    SettingsIconComponent,
    UpdateIconComponent,
    ImportFromExcelIconComponent,
    DragIconComponent,
    InfoIconFilledComponent,
    InfoIconComponent,
    CloudLockedIconComponent,
    CloudUpdateIconComponent,
    CloudUpdateFilledIconComponent,
    CloudDisconnectedIconComponent,
    DeleteIconComponent,
    BotIconComponent,
    LockIconComponent,
    NoLockIconComponent,
    PaperplaneIconComponent,
    ShareIconComponent,
    MenuIconComponent,
    ExpanderIconComponent,
    DeleteXmarkIconComponent,
    MoveIconComponent,
    FrameworkIconComponent,
    ChartIconComponent,
    IconTargetComponent,
    DoubleChevronComponent,
    HomeIconComponent,
    RegionIconComponent,
    ExcelIconComponent,
    DownloadIconComponent,
    OrganizationIconComponent,
    SuborgIconComponent,
    DashboardIconComponent,
    ReportIconComponent,
    SupportIconComponent,
    HistoryIconComponent,
    NewIconComponent,
    CheckCircleIconComponent,
    RecordsIconComponent,
    AssetIconComponent,
    WindowIconComponent,
    PlayIconComponent,
    KpiIconComponent,
    IndLibraryIconComponent,
    EnvironmentIconComponent,
    SocialIconComponent,
    GovernanceIconComponent,
    StackIconComponent,
    RawDataIconComponent,
    WarningIconComponent,
    ColumnStackIconComponent,
    CompanyIconComponent,
    CalculationIconComponent,
    ConvertIconComponent,
  ],
})
export class IconModule {}
