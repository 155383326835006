import { Component } from '@angular/core';
import { RecordForm, RecordFormTableComponent } from '../../../shared/record-form-table/record-form-table.component';
import {
  ConsumptionValueType,
  CreateOrganizationBusinessAirTravelDataRecordCommandParams,
  DataRecordType,
  DistanceType,
  InputType,
  OrganizationBusinessAirTravelDataRecord,
  UpdateOrganizationBusinessAirTravelDataRecordCommandParams,
} from 'src/api-client/report-api.generated';
import { formatDate } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConsumptionValueTypeOptions, DistanceTypeOptions, IOption } from 'src/app/static-data/options';
import { formattedStringToNumber } from 'src/app/shared/utils/number-converters';
import { RecordHeaderCell } from 'src/app/shared/components/records-table/records-table.component';

interface AirTravelRecordForm extends RecordForm {
  consumptionValueType: FormControl<IOption>;
  distanceType: FormControl<IOption>;
  numberOfFlights: FormControl<string>;
  consumptionValue: FormControl<string>;
  cO2Measured_kg: FormControl<string>;
}

@Component({
  selector: 'esg-air-travel-form-table',
  templateUrl: './air-travel-form-table.component.html',
  styleUrls: ['../../../shared/record-form-table/record-form-table.component.scss'],
  providers: [{ provide: RecordFormTableComponent, useExisting: AirTravelFormTableComponent }],
})
export class AirTravelFormTableComponent extends RecordFormTableComponent {
  headerCells: RecordHeaderCell[] = [
    { columnId: 'period', label: 'Period', description: 'From – To Date', number: false },
    { columnId: 'asset', label: 'Asset', description: '', number: false },
    { columnId: 'consumptionValueType', label: 'Method', description: ' ', number: false },
    { columnId: 'distanceType', label: 'HAUL TYPE', description: 'Distance', number: false },
    {
      columnId: 'numberOfFlights',
      label: 'Flights',
      description: 'Number',
      number: true,
    },
    { columnId: 'consumptionValue', label: 'Consumption', description: 'Km or Kg', number: true },
    { columnId: 'cO2Measured_kg', label: 'Measured', description: 'KgCO2', number: true },
  ];

  recordFc?: AirTravelRecordForm = undefined;
  recordFormgroup?: FormGroup<AirTravelRecordForm> = undefined;

  distanceTypeOptions: IOption[] = DistanceTypeOptions;
  consumptionValueTypeOptions: IOption[] = ConsumptionValueTypeOptions;

  getUnitByType(type: ConsumptionValueType) {
    switch (type) {
      case ConsumptionValueType.Distance:
        return 'Km';
      case ConsumptionValueType.Fuel:
        return 'Kg';
      default:
        return '';
    }
  }

  get getPlaceholderByType() {
    if (this.recordFc) {
      switch (this.recordFc.consumptionValueType.value.value) {
        case ConsumptionValueType.Distance:
          return 'Kilometres';
        case ConsumptionValueType.Fuel:
          return 'Kilograms';
        default:
          return 'Number';
      }
    }
    return 'Number';
  }

  setRecordsRows() {
    this.dataRecordsRows = (this.dataRecords as OrganizationBusinessAirTravelDataRecord[]).map(record => {
      const measured = record.cO2Measured_kg?.toString() || '';
      return {
        id: record.id,
        cells: [
          {
            columnId: 'period',
            value:
              formatDate(record.startDate, 'dd. MMM yyyy', 'en_US') +
              ' - ' +
              formatDate(record.endDate, 'dd. MMM yyyy', 'en_US'),
            number: false,
          },
          {
            columnId: 'asset',
            value: this.assetOptions.flatMap(group => group.options).find(c => c.value === record.assetId)?.label || '',
            number: false,
          },
          { columnId: 'consumptionValueType', value: measured ? '-' : record.consumptionValueType, number: false },
          {
            columnId: 'distanceType',
            value: measured ? '-' : record.distanceType,
            number: false,
          },
          { columnId: 'numberOfFlights', value: measured ? '' : record.numberOfFlights.toString(), number: true },
          {
            columnId: 'consumptionValue',
            value: measured ? '' : record.consumptionValue.toString(),
            number: true,
            unit: measured ? '' : this.getUnitByType(record.consumptionValueType),
          },
          {
            columnId: 'cO2Measured_kg',
            value: measured,
            number: true,
          },
        ],
      };
    });
  }

  setAddRecordForm() {
    this.recordFc = {
      startDate: new FormControl<Date>(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      endDate: new FormControl<Date>(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      asset: new FormControl<IOption | undefined>(this.getDefaultAssetOption(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      consumptionValueType: new FormControl<IOption>(this.consumptionValueTypeOptions[0], {
        validators: [Validators.required],
        nonNullable: true,
      }),
      distanceType: new FormControl<IOption>(this.distanceTypeOptions[0], {
        validators: [Validators.required],
        nonNullable: true,
      }),
      numberOfFlights: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
      consumptionValue: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
      cO2Measured_kg: new FormControl<string>('', { nonNullable: true }),
    };
    this.recordFormgroup = new FormGroup(this.recordFc);
  }

  setEditRecordForm(id: string) {
    const airRecord = this.dataRecords.find(record => record.id === id) as OrganizationBusinessAirTravelDataRecord;
    const measured = airRecord.cO2Measured_kg?.toString() || '';
    if (airRecord) {
      this.recordFc = {
        startDate: new FormControl<Date>(airRecord.startDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        endDate: new FormControl<Date>(airRecord.endDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        asset: new FormControl<IOption | undefined>(
          this.assetOptions.flatMap(group => group.options).find(opt => opt.value === airRecord.assetId) ||
            this.getDefaultAssetOption(),
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        consumptionValueType: new FormControl<IOption>(
          {
            value:
              this.consumptionValueTypeOptions.find(type => type.value === airRecord.consumptionValueType) ||
              this.consumptionValueTypeOptions[0],
            disabled: measured ? true : false,
          },
          { nonNullable: true, validators: [Validators.required] }
        ),
        distanceType: new FormControl<IOption>(
          {
            value:
              this.distanceTypeOptions.find(type => type.value === airRecord.distanceType) ||
              this.distanceTypeOptions[0],
            disabled: measured ? true : false,
          },
          {
            nonNullable: true,
            validators: [Validators.required],
          }
        ),
        numberOfFlights: new FormControl<string>(
          { value: airRecord.numberOfFlights.toString(), disabled: measured ? true : false },
          {
            nonNullable: true,
            validators: [Validators.required],
          }
        ),
        consumptionValue: new FormControl<string>(
          { value: airRecord.consumptionValue.toString(), disabled: measured ? true : false },
          {
            nonNullable: true,
            validators: [Validators.required],
          }
        ),
        cO2Measured_kg: new FormControl<string>(measured, {
          nonNullable: true,
        }),
      };
      this.recordFormgroup = new FormGroup(this.recordFc);
    }
  }

  getParams():
    | CreateOrganizationBusinessAirTravelDataRecordCommandParams
    | UpdateOrganizationBusinessAirTravelDataRecordCommandParams
    | undefined {
    const consumptionValueType = Object.values(ConsumptionValueType).find(
      type => type === this.recordFc?.consumptionValueType.value.value
    );
    const distanceType = Object.values(DistanceType).find(type => type === this.recordFc?.distanceType.value.value);

    if (this.recordFormgroup?.valid && this.recordFc && this.recordFc?.asset.value?.value) {
      const businessTravel = {
        type: DataRecordType.OrganizationBusinessAirTravel,
        assetId: this.recordFc.asset.value.value,
        inputType: InputType.Manual,
        startDate: this.recordFc.startDate.value || new Date(),
        endDate: this.recordFc.endDate.value || new Date(),
        consumptionValueType: consumptionValueType || ConsumptionValueType.Distance,
        distanceType: distanceType || DistanceType.Medium,
        numberOfFlights: formattedStringToNumber(this.recordFc.numberOfFlights.value) || 0,
        consumptionValue: formattedStringToNumber(this.recordFc.consumptionValue.value) || 0,
        cO2Measured_kg: formattedStringToNumber(this.recordFc.cO2Measured_kg.value),
      };
      if (this.editRecordId) {
        return new UpdateOrganizationBusinessAirTravelDataRecordCommandParams({
          id: this.editRecordId,
          ...businessTravel,
        });
      } else {
        return new CreateOrganizationBusinessAirTravelDataRecordCommandParams(businessTravel);
      }
    }
    return undefined;
  }

  onMeasuredChange(value: string) {
    if (this.recordFc) {
      this.recordFc.cO2Measured_kg.setValue(value);
      if (value) {
        this.recordFc.consumptionValueType.disable();
        this.recordFc.distanceType.disable();
        this.recordFc.numberOfFlights.disable();
        this.recordFc.consumptionValue.disable();
      } else {
        this.recordFc.consumptionValueType.enable();
        this.recordFc.distanceType.enable();
        this.recordFc.numberOfFlights.enable();
        this.recordFc.consumptionValue.enable();
      }
    }
  }
}
