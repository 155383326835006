import { Component } from '@angular/core';
import { ReportDetailsStateService } from '../report-details-state.service';
import { noContentImages } from 'src/app/static-data/no-content-images';

@Component({
  selector: 'esg-no-topic-selected',
  templateUrl: './no-topic-selected.component.html',
  styleUrls: ['./no-topic-selected.component.scss'],
})
export class NoTopicSelectedComponent {
  noContentImage = noContentImages[Math.floor(Math.random() * noContentImages.length)];

  constructor(public reportState: ReportDetailsStateService) {}
}
