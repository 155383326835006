import { Component, Input } from '@angular/core';
import { IMenuItem } from 'src/app/shared/ui/context-menu/context-menu.component';
import { IKpiSeries, PerformancePageStateService } from '../performance-page-state.service';
import { numberToFormattedNumberString } from 'src/app/shared/utils/number-converters';
import { CalculationResultType } from 'src/api-client/report-api.generated';

@Component({
  selector: 'esg-kpi-chart',
  templateUrl: './kpi-chart.component.html',
  styleUrls: ['./kpi-chart.component.scss'],
})
export class KpiChartComponent {
  @Input({ required: true }) kpiData!: IKpiSeries;

  chart?: Highcharts.Chart;
  calculationResultType = CalculationResultType;

  constructor(public state: PerformancePageStateService) {}

  contextMenuOptionsProvider: (kpiId: string) => IMenuItem[] = kpiId => [
    {
      id: '1',
      label: 'Recalculate',
      onClick: () => {
        this.state.calculate(kpiId);
      },
    },
    {
      id: '2',
      label: 'Export as PNG',
      onClick: () => {
        this.exportToPng();
      },
    },
    {
      id: '3',
      label: 'Export as SVG',
      onClick: () => {
        this.exportToSvg();
      },
    },
    {
      id: '4',
      label: 'Remove',
      onClick: () => {
        this.state.removeDataValueSeries(kpiId);
      },
    },
  ];
  formatNumber(value: number) {
    return numberToFormattedNumberString(value, 0, value < 100 ? 2 : 0);
  }

  formatPerformanceToTarget(value: number, target: number) {
    return numberToFormattedNumberString((Math.abs(value - target) / target) * 100, 0, value < 100 ? 2 : 0);
  }

  handleChartInstanceHandler(chart?: Highcharts.Chart) {
    this.chart = chart;
  }

  exportToPng = () => {
    if (this.chart) {
      this.chart.exportChart(
        {
          type: 'image/png',
          filename: this.kpiData.name,
        },
        {
          chart: {
            backgroundColor: 'white',
          },
        }
      );
    } else {
      console.error('Chart is not initialized');
    }
  };

  exportToSvg = () => {
    if (this.chart) {
      this.chart.exportChart(
        {
          type: 'image/svg+xml',
          filename: this.kpiData.name,
        },
        {
          chart: {
            backgroundColor: 'white',
          },
        }
      );
    } else {
      console.error('Chart is not initialized');
    }
  };
}
