import { Injectable } from '@angular/core';
import { CellObject, WorkBook, WorkSheet, read, utils } from 'xlsx';
import {
  CellValue,
  ExcelCell,
  ExcelSheet,
  GetCellValue,
  ValueType,
  calculateAlphabeticalString,
  isExcelNumberChangedNumberOfDecimals,
  isExcelNumberTextFormat,
  isExcelStringDateFormat,
  isValid,
  isValidExcelRange,
  mapToValueType,
} from './excel-importer';
import { FormControl } from '@angular/forms';
import { IOption } from 'src/app/static-data/options';
import { DebouncedFunc, debounce } from 'lodash';
import { parse } from 'date-fns';

export interface FieldConfig {
  title: string;
  type: ValueType;
  optional?: boolean;
  propertyName: string;
}

export interface DataRecordOutput {
  [propertyName: string]: { rowNo: number; valid: boolean; value: any; type: ValueType };
}

export interface ImportDataColumnOutput {
  destinationColumnAlphabetical: string;
  selectedColumnNo: number;
  selectedColumnAlphabetical: string;
  selectedColumnName: string;
}

export interface ExcelImportValidationMessage {
  title: string;
  description: string;
}

export interface ImportDataOutput {
  columns: ImportDataColumnOutput[];
  records: DataRecordOutput[];
}

export enum ImportExcelSteps {
  Step1UploadDataSource,
  Step2SelectSheet,
  Step3MapData,
}

export class MappintToValueTypeError extends Error {
  constructor(public message: string) {
    super(message);
  }
}

export interface FieldConfigValue {
  propertyName: string;
  title: string;
  type: ValueType;
  optional?: boolean;
  mappedTo: FormControl<IOption | null>;
}

export interface FieldConfigState {
  [propertyName: string]: FieldConfigValue;
}

const selectOption: IOption = { value: '', label: 'Do not import' };

@Injectable()
export class DataImportFromExcelStateService {
  importExcelStep = ImportExcelSteps.Step1UploadDataSource;
  importDataOfUpdateRanteDebounced: DebouncedFunc<(range: string) => void>;
  createConfigurationBaseOnImportedData = false;
  fieldConfigs: FieldConfig[] = [];
  fieldConfigState: FieldConfigState = {};

  get fieldConfigStateArray() {
    return Object.values(this.fieldConfigState);
  }

  get notValidDataImport() {
    var fca = this.fieldConfigStateArray;
    var importedData = this.importedData;

    if (this.fieldConfigs.length === 0) {
      return false;
    }

    var notValidDatFields = importedData.some((row, i) => {
      return fca
        .filter(fc => fc.mappedTo.value?.value)
        .some(f => {
          if (f.mappedTo.value?.value) {
            return !this.isValidImportedField(f.type, row, f.mappedTo.value?.value, f.optional);
          }
          return false;
        });
    });

    return (
      notValidDatFields || this.fieldConfigStateArray.filter(fct => !fct.optional).some(f => !f.mappedTo.value?.value)
    );
  }

  fileName: string = '';
  sheetNames: string[] = [];
  workBook?: WorkBook;
  showPreview = false;

  selectedSheetName: string = '';
  selectedWorkSheet?: WorkSheet;
  selectedSheetRange: string = '';
  importSheetRangeFc: FormControl;
  importedHeaders: ExcelCell[] = [];
  importedData: ExcelCell[][] = [];

  importedFieldOptions: IOption[] = [];

  mapDataHeaderColumns: { label: string; optional: boolean }[] = [];

  get sheetNamesString() {
    return this.sheetNames.join(', ');
  }

  constructor() {
    this.importDataOfUpdateRanteDebounced = debounce(this.importDataOfUpdatedRange, 1000);
    this.importSheetRangeFc = new FormControl<string>('');
    this.importSheetRangeFc.valueChanges.subscribe(value => {
      if (isValidExcelRange(value)) {
        this.importDataOfUpdateRanteDebounced(value);
      }
    });
  }

  initialize(fieldConfigs?: FieldConfig[]) {
    if (!!fieldConfigs) {
      this.fieldConfigs = fieldConfigs;

      this.mapDataHeaderColumns = fieldConfigs.map(fc => ({ label: fc.title, optional: fc.optional || false }));

      this.fieldConfigState = fieldConfigs.reduce<FieldConfigState>((acc, fieldConfig) => {
        const formControl = new FormControl<IOption | null>(selectOption);

        acc[fieldConfig.propertyName] = {
          propertyName: fieldConfig.propertyName,
          title: fieldConfig.title,
          type: fieldConfig.type,
          optional: fieldConfig.optional,
          mappedTo: formControl,
        };
        return acc;
      }, {});
    } else {
      this.createConfigurationBaseOnImportedData = true;
    }
  }

  handleShowHidePreview() {
    this.showPreview = !this.showPreview;
  }

  async uploadExcelFile(file: File) {
    this.clearSelectedSheetData();
    this.fileName = file.name;

    const fileData = await file.arrayBuffer();

    this.workBook = read(fileData, {
      type: 'array',
      cellNF: true,
      cellFormula: true,
      cellText: true,
      cellStyles: true,
      cellDates: true,
      raw: true,
    });

    this.sheetNames = this.workBook.SheetNames;
  }

  handleSelectSheet(sheetName: string) {
    this.clearFieldConfigState();
    this.selectedSheetName = sheetName;
    this.selectedWorkSheet = this.workBook?.Sheets[this.selectedSheetName];

    this.selectedSheetRange = this.selectedWorkSheet?.['!ref'] || '';
    this.importSheetRangeFc.setValue(this.selectedSheetRange);
    this.importDataOfUpdatedRange(this.selectedSheetRange);
  }

  importDataOfUpdatedRange(range: string) {
    const getCellValue: GetCellValue = (row: number, column: number) => {
      if (this.selectedWorkSheet) {
        const cellAddress = `${calculateAlphabeticalString(column)}${row}`;
        const cell = utils.decode_cell(cellAddress);
        const sheetCell: CellObject = this.selectedWorkSheet[utils.encode_cell(cell)];
        if (sheetCell && sheetCell.v !== undefined) {
          try {
            if (isExcelNumberTextFormat(sheetCell)) {
              const cellValue: CellValue = { value: sheetCell.v.toString(), type: 'string' };
              return cellValue;
            }

            if (sheetCell.w && isExcelNumberChangedNumberOfDecimals(sheetCell)) {
              const cellValue: CellValue = { value: parseFloat(sheetCell.w), type: 'number' };
              return cellValue;
            }

            var isExcelStringDateFormatResult = isExcelStringDateFormat(sheetCell);
            if (isExcelStringDateFormatResult.isValid) {
              const formatString = `dd${isExcelStringDateFormatResult.separator}MM${isExcelStringDateFormatResult.separator}yyyy`;
              const parsedDate = parse(sheetCell.v as string, formatString, new Date());
              const cellValue: CellValue = { value: parsedDate, type: 'date' };
              return cellValue;
            }

            var valueType = mapToValueType(sheetCell, row, column);
            const cellValue: CellValue = { value: sheetCell.v, type: valueType };
            return cellValue;
          } catch (e) {
            throw new MappintToValueTypeError(`Error in cell ${cellAddress}: ${JSON.stringify(sheetCell)}. ${e}`);
          }
        }
      }
      return undefined;
    };

    if (this.selectedWorkSheet) {
      const excelSheet = new ExcelSheet(this.selectedSheetName, range, getCellValue);
      this.importedHeaders = excelSheet.headers;
      this.importedData = excelSheet.data;
      this.importedFieldOptions = [
        selectOption,
        ...excelSheet.columnNames.map((c, i) => {
          return {
            value: i.toString(),
            label: c,
          };
        }),
      ];

      if (this.createConfigurationBaseOnImportedData) {
        this.mapDataHeaderColumns = this.importedHeaders.map((h, index) => ({
          label: `Column ${index + 1}`,
          optional: true,
        }));

        this.fieldConfigState = this.importedHeaders.reduce<FieldConfigState>((acc, importedHeader) => {
          const formControl = new FormControl<IOption | null>(selectOption);

          acc[importedHeader.column] = {
            propertyName: importedHeader.columnName,
            title: importedHeader.value,
            type: importedHeader.type,
            optional: true,
            mappedTo: formControl,
          };
          return acc;
        }, {});
      }
      this.setDefaultFieldConfigState(this.importedFieldOptions);
    }
  }

  setDefaultFieldConfigState(options: IOption[]) {
    this.fieldConfigStateArray.forEach((fcs, index) => {
      if (index + 1 < options.length) {
        fcs.mappedTo.setValue(options[index + 1]);
      }
    });
  }

  getSelectedFieldData(cells: ExcelCell[], value?: string) {
    if (!value) {
      return '-';
    }

    const cell = cells[parseInt(value)];

    return cell.type !== 'date' ? cell.value : cell.value?.toLocaleDateString();
  }

  isValidImportedField(expectedType: ValueType, cells: ExcelCell[], value?: string, optional?: boolean) {
    if (this.fieldConfigs.length === 0) {
      return true;
    }

    if (!value) {
      return true;
    }
    const cell = cells[parseInt(value)];

    return isValid(cell.value, expectedType, optional);
  }

  async getImportDataOutput(): Promise<ImportDataOutput> {
    var importedData = this.importedData;
    var importedHeaders = this.importedHeaders;
    var importDataOutput = importedData.map<DataRecordOutput>((row, i) => ({}));

    var mappedColumns: ImportDataColumnOutput[] = [];

    this.fieldConfigStateArray.forEach(element => {
      if (element.mappedTo.value?.value) {
        var mappedToValue = parseInt(element.mappedTo.value.value);
        var alphabeticalIndex = calculateAlphabeticalString(mappedToValue + 1);

        var headerConfiguration = importedHeaders.find(h => h.column === mappedToValue + 1);

        if (!!headerConfiguration) {
          mappedColumns.push({
            destinationColumnAlphabetical: element.propertyName,
            selectedColumnNo: mappedToValue,
            selectedColumnAlphabetical: alphabeticalIndex,
            selectedColumnName: headerConfiguration.value,
          });
        }

        importDataOutput.forEach((row, i) => {
          row[element.propertyName] = {
            rowNo: i + 1,
            type: importedData[i][mappedToValue].type,
            value: importedData[i][mappedToValue].value,
            valid: isValid(importedData[i][mappedToValue].value, importedData[i][mappedToValue].type, element.optional),
          };
        });
      }
    });
    return new Promise<ImportDataOutput>(resolve => {
      resolve({
        columns: mappedColumns,
        records: importDataOutput,
      });
    });
  }

  clearSelectedSheetData() {
    this.selectedSheetName = '';
    this.selectedWorkSheet = undefined;
    this.selectedSheetRange = '';
    this.importSheetRangeFc.setValue('');
    this.importedData = [];
    this.importedFieldOptions = [];
  }

  clearFieldConfigState() {
    Object.values(this.fieldConfigState).forEach((fieldConfig: FieldConfigValue) => {
      fieldConfig.mappedTo.setValue(selectOption);
    });
  }
}

export function validateExcelOptionColumn(
  validationMessages: ExcelImportValidationMessage[],
  propertyName: string,
  options: IOption[],
  records: DataRecordOutput[],
  descriptionPrefix: string,
  isRequired: boolean = true
): ExcelImportValidationMessage[] {
  const notMappedColumns = records
    .filter(record => {
      const valueTrimmedUpperCase = record[propertyName]?.value?.trim().toUpperCase();
      const isRecordValid = !options.some(
        o => o.label.toUpperCase() === valueTrimmedUpperCase || o.value.toUpperCase() === valueTrimmedUpperCase
      );

      return (isRequired && isRecordValid) || (!isRequired && record[propertyName]?.value && isRecordValid);
    })
    .map(record => record[propertyName]?.rowNo);

  if (notMappedColumns.length) {
    return [
      ...validationMessages,
      {
        title: 'Not valid imported data',
        description: `${descriptionPrefix} column is not valid for rows: ${notMappedColumns.join(',')}`,
      },
    ];
  }
  return validationMessages;
}
