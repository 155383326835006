import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-image-icon',
  template: `
    <svg
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="15.5"
        y="0.5"
        width="15"
        height="15"
        rx="1.5"
        transform="rotate(90 15.5 0.5)"
        attr.stroke="{{ color }}" />
      <rect x="4" y="8" width="4" height="4" attr.fill="{{ color }}" />
      <circle cx="11" cy="10" r="2" attr.fill="{{ color }}" />
      <path d="M8.5 3L10.6651 6.75H6.33494L8.5 3Z" attr.fill="{{ color }}" />
    </svg>
  `,
  styles: [],
  host: {
    '[style.width]': 'sizePx',
    '[style.height]': 'sizePx',
  },
})
export class ImageIconComponent {
  @Input() size: string = '16';
  @Input() color: string = 'black';

  sizePx = this.size + 'px';
}
