<div class="data-series" [class.set-target]="kpiData.targetSet">
  <header>
    <div class="title">
      <esg-header-with-guidance
        [hideGuidance]="true"
        [label]="kpiData.name"
        guidance="Description...."></esg-header-with-guidance>
    </div>
    <div class="right-toolbar">
      <button class="edit-target" (click)="state.setTargetDialog(kpiData.id)">
        <esg-icon-target color="#0071E3" size="14"></esg-icon-target>
        <span *ngIf="kpiData.targetSet">Edit Target</span>
        <span *ngIf="!kpiData.targetSet">Set Target</span>
      </button>
      <esg-context-menu [options]="contextMenuOptionsProvider(kpiData.id)"></esg-context-menu>
    </div>
  </header>
  <div class="data-content">
    <div class="chart">
      <div class="chart-parameters">
        <div class="from-to">
          <div class="control">
            <div class="title">From</div>
            <esg-select [formControl]="kpiData.yearFromFc" [options]="kpiData.yearFromOptions"></esg-select>
          </div>
          <span>&mdash;</span>
          <div class="control">
            <div class="title">To</div>
            <esg-select [formControl]="kpiData.yearToFc" [options]="kpiData.yearToOptions"></esg-select>
          </div>
        </div>
        <div class="interval">
          <div class="control">
            <div class="title">Interval</div>
            <esg-select
              class="interval-select"
              [formControl]="kpiData.timePeriodTypeFc"
              [options]="kpiData.timePeriodTypeOptions"></esg-select>
          </div>
        </div>
      </div>
      <esg-chart-v3
        [name]="kpiData.name"
        [isLoading]="state.loadingCharts.includes(kpiData.id)"
        [xLabel]="kpiData.xLabel"
        [yLabel]="kpiData.yLabel"
        [valueSeries]="kpiData.valueSeriesArray"
        [targetSeries]="kpiData.targetSeries"
        (chartIntanceHandler)="handleChartInstanceHandler($event)"></esg-chart-v3>
    </div>
    <div class="in-total-data">
      <div>
        <div class="title">{{ kpiData.type === calculationResultType.Average ? 'Average (Mean)' : 'Total' }}</div>
        <div class="value">{{ formatNumber(kpiData.totalValue) }}</div>
        <div class="description">{{ kpiData.totalUnit }}</div>
      </div>
      <div *ngIf="kpiData.targetSet">
        <div class="title">
          {{ kpiData.type === calculationResultType.Average ? 'Average (Mean) Target' : 'Total target' }}
        </div>
        <div class="value">{{ formatNumber(kpiData.totalTargetValue) }}</div>
        <div class="description">{{ kpiData.totalUnit }}</div>
      </div>
      <div *ngIf="kpiData.targetSet">
        <div class="title">Performance to target</div>
        <div
          class="value"
          [class.value-below]="kpiData.totalValue < kpiData.totalTargetValue"
          [class.value-above]="kpiData.totalValue > kpiData.totalTargetValue">
          {{ formatPerformanceToTarget(kpiData.totalValue, kpiData.totalTargetValue) }}
          <span>%</span>
        </div>
        <div
          class="description"
          [class.value-below]="kpiData.totalValue < kpiData.totalTargetValue"
          [class.value-above]="kpiData.totalValue > kpiData.totalTargetValue">
          {{ kpiData.totalValue >= kpiData.totalTargetValue ? 'Above target' : 'Below target' }}
        </div>
      </div>
    </div>
  </div>
</div>
