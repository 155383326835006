import { Component, Input, OnInit } from '@angular/core';
import { DynamicContentBase, IContentCommonActions, IContentCommonStates } from '../../dynamic-content-data';
import { HeaderColumn, TableDataConfig } from 'src/app/shared/components/table-data/table-data.component';
import { read, utils } from 'xlsx';
import { ImportDataOutput } from 'src/app/shared/components/data-import-from-excel/data-import-from-excel.component';

export interface IImportFromExcelContentActions extends IContentCommonActions {}

export interface IImportFromExcelContentStates extends IContentCommonStates {}

export interface IImportFromExcelContentData {
  tableConfig: TableDataConfig;
  rows: string[][];
}

@Component({
  selector: 'esg-import-from-excel',
  templateUrl: './import-from-excel.component.html',
  styleUrls: ['./import-from-excel.component.scss'],
})
export class ImportFromExcelComponent
  extends DynamicContentBase<IImportFromExcelContentActions, IImportFromExcelContentStates, IImportFromExcelContentData>
  implements OnInit
{
  importFromExcelDialog = false;
  tableConfig!: TableDataConfig;
  rows!: string[][];
  tableColumns?: string;

  textAreaStyles = {
    'font-size': '14px',
    color: '#333333',
    border: 'none',
    padding: '20px 16px 4px 8px',
  };
  textAreaStylesFocus = {
    'font-size': '14px',
    color: '#333333',
    border: '1px solid #0071E3',
    background: '#EDF5FD99',
    padding: '19px 15px 3px 7px',
    borderRadius: '4px',
  };

  toolbarStyles = {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 0)',
    top: '-18px',
    zIndex: '3',
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
  };
  toolbarHidden = {
    display: 'none',
  };

  emptyText = '<p style="color: #999999;">Add text here<p>';

  get importUdpateBtnLabel() {
    return this.rows?.length ? 'Update from Excel' : 'Import from Excel';
  }

  get getHeadersAsString() {
    if (this.tableConfig?.headers?.length) {
      return this.tableConfig.headers.map(header => header.label).join(', ');
    } else return '';
  }

  get showEmptyContent() {
    return !this.tableConfig || !this.tableConfig.headers.length;
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.data) {
      this.tableConfig = this.data?.tableConfig;
      this.rows = this.data.rows;
    }
  }

  handleComponentClick(event: Event) {
    this.onSetActive.emit(true);
  }

  handleOutsideClick() {
    this.onSetActive.emit(false);
  }

  handleImportFromExcel() {
    this.importFromExcelDialog = true;
  }

  importDataFromExcel(data: ImportDataOutput) {
    var contentData: IImportFromExcelContentData = {
      tableConfig: {
        headers: data.columns.map(c => ({
          label: c.selectedColumnName,
        })),
      },
      rows: data.records.map(r => {
        return data.columns.map(c => {
          if (r[c.destinationColumnAlphabetical]?.type === 'date') {
            return r[c.destinationColumnAlphabetical]?.value?.toLocaleDateString() || '';
          }
          return r[c.destinationColumnAlphabetical]?.value != undefined ? r[c.destinationColumnAlphabetical].value : '';
        });
      }),
    };
    this.tableConfig = contentData.tableConfig;
    this.rows = contentData.rows;
    this.tableColumns = `repeat(${contentData.tableConfig.headers.length}, 1fr)`;
    this.onChange(this.contentId, {}, contentData);
    this.importFromExcelDialog = false;
  }

  importDataFromExcelClose() {
    this.importFromExcelDialog = false;
  }
}
