import { Injectable } from '@angular/core';
import { AssetType, OrganizationAssetVm, Permission, UserRole } from 'src/api-client/report-api.generated';
import { AssetApiService } from 'src/app/api-client/report-api/asset-api-service';
import { Subscription } from 'rxjs';
import { AppInfoService } from 'src/app/core/app-info.service';
import { AssetUi } from '../asset-list.component';
import { AssetCategoryRoutes } from '../../assets-page.component';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';

@Injectable()
export class OrganizationAssetsStateService {
  private userInfoSub!: Subscription;
  private organizationContextSub!: Subscription;
  organizationDetailsOpen: boolean = false;
  organizations: AssetUi[] = [];
  selectedOrganization?: AssetUi;
  loading: boolean = false;
  isAdminLinkDisabled: boolean = true;

  assetCategoryRoutes = AssetCategoryRoutes;

  get useNewMainNavigation() {
    return this.appConfig.NewNavbarEnabled;
  }

  constructor(
    private appInfo: AppInfoService,
    private appConfig: AppConfigurationService,
    private assetApiService: AssetApiService
  ) {
    this.userInfoSub = this.appInfo.userInfo$.subscribe(userInfo => {
      if (userInfo && (userInfo.role === UserRole.Admin || userInfo.role === UserRole.SuperAdmin)) {
        this.isAdminLinkDisabled = false;
      } else {
        this.isAdminLinkDisabled = true;
      }
      !this.appInfo.hasPermission(Permission.Change_Current_Context_Organization) && this.init();
    });
    this.organizationContextSub = this.appInfo.organizationContext$.subscribe(() => this.init());
  }

  async init() {
    this.loading = true;
    await this.getOrganizationAssets();
    this.loading = false;
  }

  async getOrganizationAssets() {
    const response = await this.assetApiService.getAssetsByOrganizationAndType(undefined, AssetType.Organization);
    const result = response.result as OrganizationAssetVm[];
    this.organizations = result.map(asset => ({
      id: asset.id,
      name: asset.orgOrSuborgName,
      organizationName: asset.orgOrSuborgName,
      versionValidFrom: asset.versionValidFrom,
      country: asset.countryName,
      recordsCount: asset.recordCount,
      firstRecordDate: asset.firstRecordDate,
      lastRecordDate: asset.lastRecordDate,
    }));
  }

  async getAssetDetails(assetId: string) {
    this.selectedOrganization = this.organizations.find(org => org.id === assetId);
    this.organizationDetailsOpen = true;
  }

  handleCloseAssetDetails() {
    this.selectedOrganization = undefined;
    this.organizationDetailsOpen = false;
    this.getOrganizationAssets();
  }

  ngOnDestroy(): void {
    this.userInfoSub.unsubscribe();
    this.organizationContextSub.unsubscribe();
  }
}
