<table>
  <tr>
    <th>Name</th>
    <th>Unit label</th>
    <th>Unit</th>
    <th>Description</th>
  </tr>
  <tr *ngFor="let definition of state.indicatorDefinitions">
    <td>{{ definition.name }}</td>
    <td>{{ definition.dataValueMetadata.label }}</td>
    <td>
      <div style="text-align: center; white-space: nowrap">{{ definition.dataValueMetadata.unit }}</div>
    </td>
    <td>{{ definition.description }}</td>
  </tr>
</table>
