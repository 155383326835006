import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { TimePeriod, TopicItemType } from 'src/api-client/report-api.generated';
import { Router } from '@angular/router';
import { ComponentsStateService } from './components/components-state.service';
import { TopicItemDetailsStateService, TopicItemContentUi } from './topic-item-details-state.service';

@Component({
  selector: 'esg-topic-item-details',
  templateUrl: './topic-item-details.component.html',
  styleUrls: ['./topic-item-details.component.scss'],
  providers: [TopicItemDetailsStateService, ComponentsStateService],
})
export class TopicItemDetailsComponent implements OnInit {
  @Input({ required: true }) reportId!: string;
  @Input() topicItem?: TopicItemContentUi;
  @Input() timePeriod?: TimePeriod;
  @Output() onReorder = new EventEmitter<boolean>();
  @Output() onCalculate = new EventEmitter<void>();

  stickyComponentsToolbar: boolean = false;

  constructor(
    public hostRef: ElementRef,
    public state: TopicItemDetailsStateService,
    public componentsState: ComponentsStateService,
    private router: Router
  ) {}

  get isCalculatedIndicator() {
    if (
      (this.topicItem?.predefinedIndicatorId && this.isNumericIndicator) ||
      this.topicItem?.type === TopicItemType.NewIndicator
    )
      return true;
    else return false;
  }

  get isNumericIndicator() {
    return (
      this.topicItem?.type === TopicItemType.DataValueIndicator || this.topicItem?.type === TopicItemType.NewIndicator
    );
  }

  get isNewIndicator() {
    return this.topicItem?.type === TopicItemType.NewIndicator;
  }

  get isCalculationDisabled() {
    if (
      this.topicItem?.dataValues?.find(value => value.isCurrentValueConnected || value.isPreviousValueConnected) ||
      this.topicItem?.type === TopicItemType.NewIndicator
    )
      return false;
    else return true;
  }

  get showReferences() {
    return (
      this.topicItem?.type === TopicItemType.DataValueIndicator ||
      this.topicItem?.type === TopicItemType.TextIndicator ||
      this.topicItem?.type === TopicItemType.NewIndicator ||
      (this.topicItem?.type === TopicItemType.TextDisclosure && this.topicItem?.referenceStandards)
    );
  }

  get formattedReferenceStandards() {
    return this.topicItem?.referenceStandards?.replace(/(\r\n|\n|\r)/gm, ' | ');
  }

  ngOnInit(): void {
    this.state.initCalculationData(this.topicItem?.calculationDate, this.topicItem?.substrateData);
    this.componentsState.getTopicItemComponents(this.reportId, this.topicItem?.id || '');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.topicItem && changes.topicItem.currentValue && this.topicItem?.dataValues) {
      this.state.setIndicatorValues(this.topicItem.dataValues);
    }
  }

  handleStickyComponentMenu(tabEntry: IntersectionObserverEntry) {
    if (tabEntry.rootBounds?.top) {
      this.stickyComponentsToolbar = tabEntry.boundingClientRect.top < tabEntry.rootBounds.top;
    } else this.stickyComponentsToolbar = false;
  }

  handleGoToDatacollect() {
    this.router.navigate(['/data-collect']);
  }

  async handleCalculateIndicator() {
    if (!this.topicItem?.id) {
      throw Error('Lack of the information about topicItem identifier');
    } else {
      if (this.topicItem.type === TopicItemType.NewIndicator) {
        await this.state.handleCalculateNewIndicator(this.topicItem.id);
        this.onCalculate.emit();
      } else {
        this.state.handleCalculate(this.topicItem.id, this.topicItem.dataValues || []);
      }
    }
  }
  handleOnReorder(reordering: boolean) {
    this.componentsState.onReorder(reordering);
    this.onReorder.emit(reordering);
  }
}
