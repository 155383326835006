import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IndicatorFormStateService } from '../indicator-form-state.service';

@Component({
  selector: 'esg-indicator-form-values',
  templateUrl: './indicator-form-values.component.html',
  styleUrls: ['./indicator-form-values.component.scss'],
})
export class IndicatorFormValuesComponent implements OnInit {
  constructor(public state: IndicatorFormStateService) {}

  ngOnInit(): void {
    this.handleOnToggle();
  }

  handleOnToggle() {
    const visible = this.state.customIndicatorFc?.dataValues.controls.reduce(
      (acc: FormControl<boolean | null>[], control) => {
        if (control.controls.visible !== undefined) {
          if (control.controls.visible.value) acc.push(control.controls.visible);
          control.controls.visible.enable();
        }
        return acc;
      },
      []
    );

    if (visible && visible.length === 1) visible[0].disable();
  }
}
