<table [class.add-only]="addOnly">
  <thead>
    <tr>
      <th *ngFor="let header of headers">{{ header }}</th>
      <th *ngIf="!addOnly"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="!addOnly">
      <ng-container *ngFor="let asset of assets">
        <tr
          *ngIf="selectedAsset?.id !== asset.id || !fc"
          (click.single)="handleOnSingleClick(asset)"
          (click.double)="iniateEdit(asset)"
          [class.active]="selectedAsset?.id === asset.id">
          <td>{{ asset.name }}</td>
          <td>{{ asset.type }}</td>
          <td>{{ asset.organizationName }}</td>
          <td>{{ asset.country }}</td>
          <td>
            <div class="arrow-cell">
              Details
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13 8C13 7.79839 12.9182 7.6129 12.7628 7.46774L6.28425 1.20968C6.13701 1.07258 5.95706 1 5.74438 1C5.3272 1 5 1.31452 5 1.73387C5 1.93548 5.0818 2.12097 5.21268 2.25806L11.1677 8L5.21268 13.7419C5.0818 13.879 5 14.0565 5 14.2661C5 14.6855 5.3272 15 5.74438 15C5.95706 15 6.13701 14.9274 6.28425 14.7823L12.7628 8.53226C12.9182 8.37903 13 8.20161 13 8Z"
                  fill="#999999" />
              </svg>
            </div>
          </td>
        </tr>
        <tr
          *ngIf="selectedAsset?.id === asset.id && fc"
          class="asset-form"
          esg-on-click-outside
          (onClickOutside)="handleOnFormSubmit()"
          esgKeyboardShortcuts
          (onEscapeKey)="handleOnCancel()"
          (onEnterKey)="fc.name.valid && handleOnFormSubmit()">
          <ng-container *ngTemplateOutlet="assetForm"></ng-container>
          <td>
            <div class="btn-cell">
              <button class="btn-content delete" (click)="handleOnDelete()">
                Delete
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5.16116 15H10.845C11.7314 15 12.3264 14.4154 12.3698 13.5164L12.7975 4.37584H13.5413C13.7955 4.37584 14 4.15581 14 3.89807C14 3.64032 13.7955 3.42658 13.5413 3.42658H10.7087V2.46475C10.7087 1.55321 10.126 1 9.17149 1H6.81612C5.86157 1 5.27893 1.55321 5.27893 2.46475V3.42658H2.46488C2.21694 3.42658 2 3.64032 2 3.89807C2 4.1621 2.21694 4.37584 2.46488 4.37584H3.20868L3.63636 13.5227C3.67975 14.4216 4.2624 15 5.16116 15ZM6.26446 2.52762C6.26446 2.16929 6.5124 1.93669 6.8843 1.93669H9.10331C9.47521 1.93669 9.72314 2.16929 9.72314 2.52762V3.42658H6.26446V2.52762ZM5.24793 14.0507C4.89463 14.0507 4.6281 13.7804 4.6095 13.4095L4.17562 4.37584H11.7996L11.3905 13.4095C11.3781 13.7867 11.1116 14.0507 10.7459 14.0507H5.24793ZM6.16529 12.9758C6.40083 12.9758 6.55579 12.8249 6.54959 12.6048L6.35744 5.87831C6.35124 5.65828 6.19628 5.5137 5.97314 5.5137C5.7376 5.5137 5.58264 5.66457 5.58884 5.8846L5.77479 12.6048C5.78099 12.8312 5.93595 12.9758 6.16529 12.9758ZM8 12.9758C8.23554 12.9758 8.40289 12.8249 8.40289 12.6048V5.8846C8.40289 5.66457 8.23554 5.5137 8 5.5137C7.76446 5.5137 7.60331 5.66457 7.60331 5.8846V12.6048C7.60331 12.8249 7.76446 12.9758 8 12.9758ZM9.84091 12.9758C10.064 12.9758 10.219 12.8312 10.2252 12.6048L10.4112 5.8846C10.4174 5.66457 10.2624 5.5137 10.0269 5.5137C9.80372 5.5137 9.64876 5.65828 9.64256 5.8846L9.45661 12.6048C9.45041 12.8249 9.60537 12.9758 9.84091 12.9758Z" />
                </svg>
              </button>
            </div>
          </td>
        </tr>
      </ng-container>
      <tr *ngIf="!fc" class="add-line">
        <td [class.expand-line]="showStickyDivider">
          <div
            esgIntersectionObserver
            [threshold]="0"
            rootMargin="0px 0px -72px 0px"
            (onIntersection)="handleStickyDivider($event)"></div>
          <esg-add-button *ngIf="!isUpdating" label="Add a new asset" (click)="initateAdd($event)"></esg-add-button>
          <esg-spinner
            *ngIf="isUpdating"
            class="spinner"
            label="Creating..."
            [useDefaultTextStyle]="false"></esg-spinner>
        </td>
      </tr>
      <tr
        *ngIf="fc && !selectedAsset"
        class="asset-form"
        esg-on-click-outside
        (onClickOutside)="handleOnFormSubmit()"
        esgKeyboardShortcuts
        (onEscapeKey)="handleOnCancel()"
        (onEnterKey)="fc.name.valid && handleOnFormSubmit()">
        <ng-container *ngTemplateOutlet="assetForm"></ng-container>
        <td>
          <div class="btn-cell">
            <button class="btn-content" (click)="handleOnCancel()">
              Cancel
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect
                  x="2.8139"
                  y="1.8717"
                  width="16"
                  height="1.33333"
                  rx="0.666667"
                  transform="rotate(45 2.8139 1.8717)"
                  fill="#999999" />
                <rect
                  x="1.87177"
                  y="13.1855"
                  width="16"
                  height="1.33333"
                  rx="0.666667"
                  transform="rotate(-45 1.87177 13.1855)"
                  fill="#999999" />
              </svg>
            </button>
          </div>
        </td>
      </tr>
    </ng-container>
    <tr *ngIf="addOnly" class="asset-form">
      <ng-container *ngTemplateOutlet="assetForm"></ng-container>
    </tr>
    <ng-template #assetForm>
      <ng-container *ngIf="fc">
        <td class="input-cell" [class.error]="showErrors && !fc.name.valid">
          <esg-textbox-form
            class="input-text"
            type="text"
            [autoFocus]="true"
            [fc]="fc.name"
            variant="ghost-table"
            placeholder="Facility name"></esg-textbox-form>
        </td>
        <td class="input-cell" [class.disabled]="typeOptions.length === 1">
          <esg-table-select
            *ngIf="fc.type"
            class="input-dropdown"
            [isPopup]="addOnly"
            [options]="typeOptions"
            [disabled]="typeOptions.length === 1"
            [value]="fc.type.value ? fc.type.value : undefined"
            (onChange)="fc.type.setValue($event)">
          </esg-table-select>
        </td>
        <td class="input-cell" [class.disabled]="fc.organization.disabled">
          <esg-table-select
            class="input-dropdown"
            [isPopup]="addOnly"
            [options]="organizationOptions"
            [disabled]="fc.organization.disabled"
            [value]="fc.organization.value ? fc.organization.value : undefined"
            (onChange)="handleOrganizationChange($event, organizationCountryMap.get($event.value))">
          </esg-table-select>
        </td>
        <td class="input-cell">
          <esg-table-select
            *ngIf="fc.country"
            class="input-dropdown"
            [isPopup]="addOnly"
            [options]="countryOptions"
            [value]="fc.country.value ? fc.country.value : undefined"
            (onChange)="fc.country.setValue($event)">
          </esg-table-select>
          <div tabindex="0" (focus)="fc.name.valid && handleOnFormSubmit()"></div>
        </td> </ng-container
    ></ng-template>
  </tbody>
</table>
