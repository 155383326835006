<div class="container">
  <esg-textbox-form label="FIRST NAME" placeholder="e.g. Greta" [fc]="state.fc.firstName"></esg-textbox-form>
  <esg-textbox-form label="EMAIL" [fc]="state.fc.email"></esg-textbox-form>

  <esg-textbox-form label="LAST NAME" placeholder="e.g Thunberg" [fc]="state.fc.lastName"></esg-textbox-form>

  <esg-select
    label="ORGANIZATION"
    [placeholder]="state.user?.organizationName || ''"
    [fitLongestLabel]="false"
    [disabled]="true"
    class="dropdown">
  </esg-select>

  <div></div>

  <esg-select
    label="ROLE"
    [placeholder]="state.user?.role || ''"
    [fitLongestLabel]="false"
    [disabled]="true"
    class="dropdown">
  </esg-select>
  <div class="btnSave">
    <esg-button [disabled]="!state.formgroup.valid || !state.formgroup.dirty" (onClick)="state.handleSubmit()"
      >Save changes</esg-button
    >
  </div>
</div>
<div class="change-password">
  <esg-button (onClick)="changePasswordRedirect()">Reset password</esg-button>
</div>
