import htmlToPdfmake from 'html-to-pdfmake';
import { Injectable } from '@angular/core';
import { ReportAppApiService } from 'src/app/api-client/report-api/app-api-service';
import { TDocumentDefinitions, Content, ContentImage, TFontDictionary } from 'pdfmake/interfaces';
import { generateReport, generateVfs, throwErrorIfPdfDefinitionNotValid } from 'src/app/pdfmake-common/generate-report';
import { IReportPdf, IComponent, IReportPdfConfiguration } from './types';
import { keyFiguresTable, generateKeyFiguresTable, getKeyFigureFromMainLevels } from './key-figures-table-pdf';
import { ContentTypes } from 'src/app/content/content-configuration';
import { ITextAreaContentData } from '../../../content/content-item/text-area/text-area.component';
import { IPictureContentData } from '../../../content/content-item/picture/picture.component';
import { getBase64ImageFromURL } from '../../../shared/utils/file';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import {
  frontPage,
  footer,
  defaultHTMLStyles,
  pdfStyles,
  pageBreak,
  generateChapterPage,
  generateTopicTitle,
} from './report-pdf-constants';
import { logo } from './logo';
import { fontsFactory, FontTypes } from '../../../pdfmake-common/fonts';
import { IImportFromExcelContentData } from 'src/app/content/content-item/import-from-excel/import-from-excel.component';
import { importFromExcelTable } from './components/import-from-excel';
import { generateTopicItemContent } from './topic-item-pdf';
import _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ReportPdfService {
  constructor(private appConfig: AppConfigurationService, private appApiService: ReportAppApiService) {}

  getPictureUrl(blobName: string): string {
    return this.appApiService.getBlobUrl(blobName);
  }

  getCoverPhotoUrl(blobName: string) {
    return `${this.appConfig.ReportApiBaseUrl}/report/cover-photo/${blobName}`;
  }

  HTMlToContent(html: string): Content {
    html = html.replace(
      /<hr>/g,
      '<hr data-pdfmake="{&quot;color&quot;:&quot;#CCCCCC&quot;, &quot;margin&quot;:[0,6,0,6], &quot;thickness&quot;:1, &quot;left&quot;:122}">'
    );
    return htmlToPdfmake(html, defaultHTMLStyles);
  }

  contentToPdf = {
    [ContentTypes.Text]: async (content: ITextAreaContentData) => {
      const textHtml = /\<[^\>]+\>/.test(content.textHtml) ? content.textHtml : '<p>' + content.textHtml + '</p>';
      return textHtml ? this.HTMlToContent(textHtml) : '';
    },
    [ContentTypes.Picture]: async (content: IPictureContentData): Promise<ContentImage | Content> => {
      const image = await getBase64ImageFromURL(this.getPictureUrl(content.blobName));
      return image
        ? {
            image: image,
            width: 368,
            margin: [122, 0, 14, 0],
          }
        : { text: '' };
    },
    [ContentTypes.ImportFromExcel]: async (content: IImportFromExcelContentData): Promise<Content> =>
      importFromExcelTable(content),
  };

  generateComponentsContent(components: IComponent[]): Promise<Content>[] {
    let content: Promise<Content>[] = [];
    components.map(async c => {
      if (c.type) {
        if (c.content) {
          content.push(this.contentToPdf[c.type](JSON.parse(c.content)));
          content.push(Promise.resolve({ text: ' ', fontSize: 0, margin: [122, 0, 0, 28] }));
        }
      }
    });
    return content;
  }

  async generateReportContent(
    reportData: IReportPdf,
    fonts: TFontDictionary,
    vfs: { [file: string]: string },
    showMainContent: boolean = true
  ): Promise<Content[]> {
    let content: Content[] = [];
    let keyFiguresTables: keyFiguresTable[] = getKeyFigureFromMainLevels(reportData.mainLevels || []);

    let mainContent: Promise<Content>[] = [];
    const mainLevels = reportData.mainLevels || [];
    if (showMainContent) {
      mainLevels.map(async mainTopic => {
        const topics = mainTopic.topics || [];
        topics.length &&
          generateChapterPage(
            mainTopic.name || '',
            reportData.organizationDetails.organizationName || '',
            reportData.timePeriod,
            reportData.organizationDetails.brandColor
          ).map(c => mainContent.push(Promise.resolve(c)));

        topics.map(async (topic, topicIndex) => {
          const topicItems = topic.topicItems || [];
          topicItems.length &&
            mainContent.push(
              Promise.resolve(generateTopicTitle(mainTopic.name, topic.name, reportData.organizationDetails.brandColor))
            );
          topicItems.map(async (topicItem, topicItemIndex) => {
            const topicItemErrorId = `${mainTopic.name || ''}/${topic.name || ''}/${topicItem.name || ''}`;
            generateTopicItemContent(topicItem, reportData.timePeriod).map(c => {
              mainContent.push(this.validateContent(topicItemErrorId, Promise.resolve(c), fonts, vfs));
            });
            this.generateComponentsContent(topicItem.components || []).map(async c => {
              mainContent.push(this.validateContent(topicItemErrorId, c, fonts, vfs));
            });
            if (!(topicIndex === topics.length - 1 && topicItemIndex === topicItems.length - 1)) {
              mainContent.push(Promise.resolve({ text: ' ', fontSize: 0, margin: [122, 0, 0, 14] }));
            }
          });
        });
      });
    }

    const keyFiguresContent: Content[] = keyFiguresTables.length
      ? generateKeyFiguresTable(
          keyFiguresTables,
          reportData.timePeriod,
          reportData.organizationDetails.organizationName,
          reportData.organizationDetails.tableHeadColor
        )
      : [];

    return content.concat(keyFiguresContent, await Promise.all(mainContent));
  }

  async generatePdf(reportData: IReportPdf, appBaseUrl: string, configuration: IReportPdfConfiguration) {
    let content: Content[] = [];

    let frontPageContent: Content[] = frontPage(
      configuration,
      reportData.coverPhotoSquare?.blobName
        ? await getBase64ImageFromURL(this.getCoverPhotoUrl(reportData.coverPhotoSquare.blobName))
        : '',
      reportData.name || '',
      reportData.organizationDetails.organizationName || '',
      reportData.organizationDetails.brandColor,
      reportData.organizationDetails.logo
        ? await getBase64ImageFromURL(this.getPictureUrl(reportData.organizationDetails.logo))
        : ''
    );

    const pageMarginTop = 85;
    const fonts = fontsFactory(appBaseUrl)(configuration.writingSystem);
    const vfs = await generateVfs(fonts);

    const finishedContent = await this.generateReportContent(reportData, fonts, vfs, configuration.showMainContent);

    let dd: TDocumentDefinitions = {
      pageMargins: [34, pageMarginTop, 57, 71],
      pageSize: 'A4', // pixels: [595.28, 841.89], in mm: [210, 297]. 1mm = 2,835px
      content: content.concat([frontPageContent, finishedContent]),
      footer: currentPage =>
        footer(
          currentPage,
          reportData.name || '',
          reportData.organizationDetails.organizationName || '',
          configuration
        ),
      defaultStyle: {
        font: FontTypes.Regular,
      },
      styles: pdfStyles,
      images: {
        logo: logo,
      },
      pageBreakBefore: (currentNode, followingNodesOnPage, nodesOnNextPage) =>
        pageBreak(currentNode, followingNodesOnPage, nodesOnNextPage, pageMarginTop),
    };
    let pdfUrl = await generateReport(dd, fonts, vfs);
    return pdfUrl;
  }

  async validateContent(
    id: string,
    content: Promise<Content>,
    fonts: TFontDictionary,
    vfs: { [file: string]: string }
  ) {
    let dd: TDocumentDefinitions = {
      content: _.cloneDeep(await content),
      defaultStyle: {
        font: FontTypes.Regular,
      },
      styles: pdfStyles,
    };

    throwErrorIfPdfDefinitionNotValid(id, dd, fonts, vfs);

    return content;
  }
}
