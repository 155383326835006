<div class="wrapper">
  <div class="options">
    <div class="option" [class.fade]="reordering" (click)="handleAddText($event)">
      <esg-text-icon></esg-text-icon>
      <div class="label">Add text</div>
    </div>
    <div class="option" [class.fade]="reordering" (click)="handleAddImage($event)">
      <esg-image-icon></esg-image-icon>
      <div class="label">Add image</div>
    </div>
    <div class="option" [class.fade]="reordering" (click)="handleAddImportFromExcel($event)">
      <esg-import-from-excel-icon></esg-import-from-excel-icon>
      <div class="label">Import from Excel</div>
    </div>
  </div>
  <div *ngIf="!reorderHidden && !reordering" class="option" (click)="handleReorder()">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4" y="1.5" width="3" height="3" rx="1" fill="#183642" />
      <rect x="9" y="1.5" width="3" height="3" rx="1" fill="#183642" />
      <rect x="9" y="6.5" width="3" height="3" rx="1" fill="#183642" />
      <rect x="4" y="6.5" width="3" height="3" rx="1" fill="#183642" />
      <rect x="4" y="11.5" width="3" height="3" rx="1" fill="#183642" />
      <rect x="9" y="11.5" width="3" height="3" rx="1" fill="#183642" />
    </svg>
    <div class="label">Organize</div>
  </div>
  <esg-button *ngIf="!reorderHidden && reordering" (click)="handleReorder()">Done</esg-button>
</div>
