<div class="header">
  <ng-content></ng-content>
  <span *ngIf="description || reference" class="info-icon" [class.active]="!collapsed" (click)="handleCollapse()">
    <esg-info-icon-filled></esg-info-icon-filled>
  </span>
</div>
<div *ngIf="!collapsed" class="description">
  <esg-text-view-editor
    *ngIf="description"
    class="text"
    [content]="description"
    typography="standard"></esg-text-view-editor>
  <span *ngIf="description && reference" class="divider"></span>
  <div *ngIf="reference" class="framework-standards">
    <span class="label">
      <esg-framework-icon></esg-framework-icon>
      Reference
    </span>
    <span class="standards">{{ formattedFrameworkStandards }}</span>
  </div>
</div>
