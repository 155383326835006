<table>
  <thead>
    <tr>
      <th>Category</th>
      <th>Records</th>
      <th>First record</th>
      <th>Last record</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of data">
      <td [class.link]="row.link">
        <a *ngIf="row.link" [routerLink]="row.link" [queryParams]="{ assetId: assetId, recordType: row.recordType }">{{
          row.category
        }}</a>
        <ng-container *ngIf="!row.link">{{ row.category }}</ng-container>
      </td>
      <td>{{ getFormattedNumber(row.count.toString(), 0, 0) }}</td>
      <td>{{ row.firstDate | date: 'dd. MMM yyyy' }}</td>
      <td>{{ row.lastDate | date: 'dd. MMM yyyy' }}</td>
    </tr>
  </tbody>
</table>
