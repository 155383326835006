<esg-section-dot-flashing [active]="state.importingFromUniSea" label="Importing in progress...">
  <div class="content">
    <div class="top-section">
      <span class="title">{{ profile.name }}</span>
      <esg-import-from-excel-toolbar
        [title]="state.title"
        [dialogOpen]="state.importFromExcelDialog"
        [filedConfigs]="state.importFromExcelFieldConfigs"
        [inProgress]="state.importingInProgress"
        [inProgressLabel]="state.importingInProgressLabel"
        [enabled]="state.importFromExcelEnabled"
        (onImportData)="state.importDataFromExcel($event)"
        (onImportDataOpen)="state.handleOnImportRecords()"
        (onClose)="state.importDataFromExcelClose()"></esg-import-from-excel-toolbar>
      <span *ngIf="state.showImportFromUniSeaButton" class="uni-sea-import">
        <button [class.disabled]="state.importingFromUniSea" (click)="handleImportFromUniSea()">
          Import from UniSea
        </button>
      </span>
    </div>

    <div class="inputs">
      <span class="input-label">Asset</span>
      <div class="input">
        <esg-select
          [options]="assetOptions"
          variant="ghost"
          size="small"
          [disabled]="assetOptions.length < 2"
          [value]="formgroup.controls.asset.value"
          (onChange)="handleDropdownChange(formgroup.controls.asset, $event)"></esg-select>
      </div>
      <span class="input-label">Valid From</span>
      <td class="date">
        {{ startDate | date : 'dd. MMM yyyy' }}
      </td>
      <span class="input-label">Valid To</span>
      <td class="date">
        {{ endDate | date : 'dd. MMM yyyy' }}
      </td>
    </div>
  </div>
  <div class="records-wrapper">
    <esg-gender-diversity-equality-records
      [profileId]="profile.id"
      [assetId]="profile.organizationAssetId"
      [currencyCode]="currencyCode"
      class="records"></esg-gender-diversity-equality-records>
  </div>
</esg-section-dot-flashing>
