import {
  ChangeTopicDeactivatedCommandParams,
  CreateNewVersionReportFromOldVersionCommandParams,
  CreateReportBasedOnTemplateCommandParams,
  CreateReportCommandParams,
  UpdateReportCommandParams,
} from './../../../api-client/report-api.generated';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import {
  MoveMainSubjectCommandResponse,
  MoveTopicCommandResponse,
  ReportClient,
  ReportTopic,
  GetCompleteReportDataVm,
  DeleteTopicCommandParams,
  DeleteTopicCommandResponse,
  DeleteMainSubjectCommandParams,
  DeleteMainSubjectCommandResponse,
  GetReportsQueryResponse,
  CreateReportCommandResponse,
  ReportMainLevel,
  UpdateReportCommandResponse,
  GetReportByIdQueryResponse,
  MoveTopicCommandParams,
  MoveMainSubjectCommandParams,
  DeleteReportCommandResponse,
} from '../../../api-client/report-api.generated';

@Injectable({
  providedIn: 'root',
})
export class ReportApiService {
  client: ReportClient;

  constructor(private httpClient: HttpClient, readonly appSettings: AppConfigurationService) {
    this.client = new ReportClient(httpClient, appSettings.ReportApiBaseUrl);
  }

  all(): Observable<GetReportsQueryResponse> {
    return this.client.getAllReports().pipe(
      catchError(error => {
        console.log(error.errors);
        // this.notificationService.showResponseErrors(error.error?.errors);
        return throwError(() => error);
      })
    );
  }

  async getByReportId(reportId: string): Promise<GetReportByIdQueryResponse> {
    const observable$ = this.client.getReportById(reportId).pipe(
      catchError(error => {
        console.log(error.errors);
        // this.notificationService.showResponseErrors(error.error?.errors);
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }
  async createNewVersionReportFromOldVersion(reportId: string): Promise<GetReportByIdQueryResponse> {
    const observable$ = this.client.createNewVersionReportFromOldVersion(
      new CreateNewVersionReportFromOldVersionCommandParams({ sourceReportId: reportId })
    );
    return await lastValueFrom(observable$);
  }

  async getCompleteReportById(reportId: string): Promise<GetCompleteReportDataVm> {
    const observable$ = this.client.getCompleteReportById(reportId).pipe(
      catchError(error => {
        console.log(error.errors);
        // this.notificationService.showResponseErrors(error.error?.errors);
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  async getCompleteTokenAuthReportById(reportId: string, token: string): Promise<GetCompleteReportDataVm> {
    const observable$ = this.client.getCompleteTokenAuthReportById(reportId, token).pipe(
      catchError(error => {
        console.log(error.errors);
        // this.notificationService.showResponseErrors(error.error?.errors);
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  removeById(reportId: string): Observable<DeleteReportCommandResponse> {
    return this.client.deleteReport(reportId).pipe(
      catchError(error => {
        console.log(error.errors);
        // this.notificationService.showResponseErrors(error.error?.errors);
        return throwError(() => error);
      })
    );
  }

  async create(body: CreateReportCommandParams): Promise<CreateReportCommandResponse> {
    const observable$ = this.client.createReport(body);
    return await lastValueFrom(observable$);
  }

  async edit(body: UpdateReportCommandParams): Promise<UpdateReportCommandResponse> {
    const observable$ = this.client.updateReport(body);
    return await lastValueFrom(observable$);
  }

  async createBaseOnTemplate(body: CreateReportBasedOnTemplateCommandParams): Promise<CreateReportCommandResponse> {
    const observable$ = this.client.createBaseOnTemplate(body);
    return await lastValueFrom(observable$);
  }

  addMainLevel(reportId: string, name: string): Observable<ReportMainLevel> {
    return this.client.addMainSubject(reportId, name).pipe(
      catchError(error => {
        console.log(error.errors);
        // this.notificationService.showResponseErrors(error.error?.errors);
        return throwError(() => error);
      })
    );
  }

  updateMainLevel(reportId: string, mainLevelId: string, name: string): Observable<void> {
    return this.client.updateMainSubject(reportId, mainLevelId, name).pipe(
      catchError(error => {
        console.log(error.errors);
        // this.notificationService.showResponseErrors(error.error?.errors);
        return throwError(() => error);
      })
    );
  }

  deleteMainLevel(reportId: string, mainLevelId: string): Observable<DeleteMainSubjectCommandResponse> {
    return this.client
      .deleteMainSubject(
        new DeleteMainSubjectCommandParams({
          reportId: reportId,
          mainLevelId: mainLevelId,
        })
      )
      .pipe(
        catchError(error => {
          console.log(error.errors);
          // this.notificationService.showResponseErrors(error.error?.errors);
          return throwError(() => error);
        })
      );
  }

  addTopic(reportId: string, mainLevelId: string, name: string): Observable<ReportTopic> {
    return this.client.addTopic(reportId, mainLevelId, name).pipe(
      catchError(error => {
        console.log(error.errors);
        // this.notificationService.showResponseErrors(error.error?.errors);
        return throwError(() => error);
      })
    );
  }

  editTopicName(reportId: string, mainLevelId: string, topicId: string, name: string): Observable<void> {
    return this.client.editTopic(reportId, mainLevelId, topicId, name).pipe(
      catchError(error => {
        console.log(error.errors);
        // this.notificationService.showResponseErrors(error.error?.errors);
        return throwError(() => error);
      })
    );
  }

  deleteTopic(reportId: string, mainLevelId: string, topicId: string): Observable<DeleteTopicCommandResponse> {
    return this.client
      .deleteTopic(
        new DeleteTopicCommandParams({
          reportId: reportId,
          mainLevelId: mainLevelId,
          topicId: topicId,
        })
      )
      .pipe(
        catchError(error => {
          console.log(error.errors);
          // this.notificationService.showResponseErrors(error.error?.errors);
          return throwError(() => error);
        })
      );
  }

  deactivateTopic(
    reportId: string,
    mainLevelId: string,
    topicId: string,
    deactivated: boolean
  ): Observable<DeleteTopicCommandResponse> {
    return this.client.changeTopicDeactivated(
      new ChangeTopicDeactivatedCommandParams({
        reportId: reportId,
        mainLevelId: mainLevelId,
        topicId: topicId,
        deactivated: deactivated,
      })
    );
  }

  moveMainLevel(body: MoveMainSubjectCommandParams): Observable<MoveMainSubjectCommandResponse> {
    return this.client.moveMainSubject(body).pipe(
      catchError(error => {
        console.error(error.errors);
        return throwError(() => error);
      })
    );
  }

  moveTopic(body: MoveTopicCommandParams): Observable<MoveTopicCommandResponse> {
    return this.client.moveTopic(body).pipe(
      catchError(error => {
        console.error(error.errors);
        return throwError(() => error);
      })
    );
  }
}
