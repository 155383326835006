<div class="main-level-list" cdkDrag [cdkDragDisabled]="editingMainLevelFormControl !== undefined">
  <div class="main-level" (mouseleave)="handleMouseLeave()" [class.editing]="editingMainLevelFormControl">
    <ng-container *ngIf="!editingMainLevelFormControl">
      <div class="icon-container" (mousedown)="handleDragStart()" cdkDragHandle>
        <svg
          class="move-icon"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <rect x="4" y="1.5" width="3" height="3" rx="1" fill="#999999" />
          <rect x="9" y="1.5" width="3" height="3" rx="1" fill="#999999" />
          <rect x="9" y="6.5" width="3" height="3" rx="1" fill="#999999" />
          <rect x="4" y="6.5" width="3" height="3" rx="1" fill="#999999" />
          <rect x="4" y="11.5" width="3" height="3" rx="1" fill="#999999" />
          <rect x="9" y="11.5" width="3" height="3" rx="1" fill="#999999" />
        </svg>
      </div>
      <svg
        *ngIf="!expanded"
        class="expand-icon"
        (click)="toggleExpand()"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.5714 8C10.5714 7.88479 10.5247 7.7788 10.4359 7.69585L6.73386 4.11982C6.64972 4.04147 6.54689 4 6.42536 4C6.18697 4 6 4.17972 6 4.41935C6 4.53456 6.04674 4.64055 6.12153 4.71889L9.52439 8L6.12153 11.2811C6.04674 11.3594 6 11.4608 6 11.5806C6 11.8203 6.18697 12 6.42536 12C6.54689 12 6.64972 11.9585 6.73386 11.8756L10.4359 8.30415C10.5247 8.21659 10.5714 8.11521 10.5714 8Z"
          fill="#999999" />
      </svg>
      <svg
        *ngIf="expanded"
        class="expand-icon"
        (click)="toggleExpand()"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 10.5714C8.11521 10.5714 8.2212 10.5247 8.30415 10.4359L11.8802 6.73386C11.9585 6.64972 12 6.54689 12 6.42536C12 6.18697 11.8203 6 11.5806 6C11.4654 6 11.3594 6.04674 11.2811 6.12153L8 9.52439L4.71889 6.12153C4.64055 6.04674 4.53917 6 4.41935 6C4.17972 6 4 6.18697 4 6.42536C4 6.54689 4.04147 6.64972 4.12442 6.73386L7.69585 10.4359C7.78341 10.5247 7.88479 10.5714 8 10.5714Z"
          fill="#999999" />
      </svg>

      <div class="name" (click.single)="handleSingleClick()" (click.double)="handleDoubleClick()">
        <span>
          {{ mainLevelData.name }}
        </span>
        <esg-spinner *ngIf="isLoading"></esg-spinner>
      </div>

      <esg-context-menu
        class="edit-icon"
        type="horizontal"
        menuPosition="BottomRight"
        [options]="mainLevelMenuProvider()"
        [isPopup]="true">
      </esg-context-menu>

      <svg
        (click)="handleEnableAddTopic($event)"
        class="plus-icon"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.5 2.5H7.5V7.5H2.5V8.5H7.5V13.5H8.5V8.5H13.5V7.5H8.5V2.5Z"
          fill="#999999" />
      </svg>
    </ng-container>
    <span
      *ngIf="editingMainLevelFormControl"
      class="edit-main-level"
      esg-on-click-outside
      [ignoreIfSelected]="true"
      (onClickOutside)="handleEditingMainLevelClickOutside()"
      esgKeyboardShortcuts
      (onEscapeKey)="handleCancelMainLevelForm()"
      (onEnterKey)="handleEditingMainLevelClickOutside()">
      <esg-textbox-form
        [fc]="editingMainLevelFormControl"
        name="edit-main-level-item"
        [autoFocus]="true"
        placeholder="Write a main topic name"
        [showClearText]="true"
        variant="icon-box"></esg-textbox-form>
    </span>
  </div>
  <div
    class="adding-topic"
    *ngIf="addingTopicFormControl"
    esg-on-click-outside
    [ignoreIfSelected]="true"
    (onClickOutside)="handleAddingTopicClickOutside()"
    esgKeyboardShortcuts
    (onEscapeKey)="handleCancelTopicForm()"
    (onEnterKey)="handleAddingTopicClickOutside()">
    <esg-textbox-form
      placeholder="Write a topic name"
      [fc]="addingTopicFormControl"
      name="add-topic"
      [autoFocus]="true"
      [showClearText]="false"></esg-textbox-form>
  </div>
  <div class="sub-items">
    <ng-content *ngIf="expanded"></ng-content>
  </div>
</div>
