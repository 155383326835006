import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CalculationResultType, IndicatorDataValueSeries, TimePeriodType } from 'src/api-client/report-api.generated';
import { IndicatorDataValuesSeriesApiService } from 'src/app/api-client/report-api/indicator-data-value-series-api-service';
import { AppInfoService } from 'src/app/core/app-info.service';
import { IDataSeriesUi } from 'src/app/shared/components/chart/types';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { IOption, KpisOptions, KpisTimePeriodTypeOptions } from 'src/app/static-data/options';

export interface IKpiSeries {
  id: string;
  name: string;
  type: CalculationResultType;
  xLabel: string;
  yLabel: string;
  valueSeriesArray: IDataSeriesUi[];
  targetSeries: IDataSeriesUi;
  targetSet: boolean;
  totalValue: number;
  totalUnit: string;
  totalTargetValue: number;
  yearFromFc: FormControl<IOption>;
  yearFromOptions: IOption[];
  yearToFc: FormControl<IOption>;
  yearToOptions: IOption[];
  timePeriodTypeFc: FormControl<IOption>;
  timePeriodTypeOptions: IOption[];
}

@Injectable()
export class PerformancePageStateService {
  targetDialogForKpiId?: string;
  kpis: IKpiSeries[] = [];
  newKpiOptions: IOption[] = KpisOptions;
  newKpiContextMenuOpen = false;
  loadingCharts: string[] = [];
  isLoadingKpis = false;

  constructor(
    public seriesApi: IndicatorDataValuesSeriesApiService,
    public appInfoService: AppInfoService,
    public notificationService: NotificationService
  ) {}

  async init() {
    this.isLoadingKpis = true;
    var { result } = await this.seriesApi.getAllForCurrentContextOrganization();

    this.kpis = result.map<IKpiSeries>(this.mapDataItemSeries);
    this.isLoadingKpis = false;
  }

  generateYearFromOptions(yearFrom: number, yearTo: number, selectedYearTo?: number) {
    const yearOptions: IOption[] = [];

    if (!yearFrom || !yearTo) {
      return yearOptions;
    }

    for (let year = yearFrom; year <= yearTo; year++) {
      yearOptions.push({
        value: year.toString(),
        label: year.toString(),
        disabled: selectedYearTo ? (year < selectedYearTo ? false : true) : false,
      });
    }
    return yearOptions;
  }

  generateYearToOptions(yearFrom: number, yearTo: number, selectedYearFrom?: number) {
    const yearOptions: IOption[] = [];

    if (!yearFrom || !yearTo) {
      return yearOptions;
    }

    for (let year = yearFrom; year <= yearTo; year++) {
      yearOptions.push({
        value: year.toString(),
        label: year.toString(),
        disabled: selectedYearFrom ? (year > selectedYearFrom ? false : true) : false,
      });
    }
    return yearOptions;
  }

  async updateTimePeriod(id: string, yearFrom: number, yearTo: number, timePeriodType: TimePeriodType) {
    await this.seriesApi.updateKpiTimePerdiod(id, yearFrom, yearTo, timePeriodType);
  }

  mapDataItemSeries = (s: IndicatorDataValueSeries): IKpiSeries => {
    const yearFromOptions =
      s.scopeTimeRange?.startTimePeriod?.year && s.scopeTimeRange?.endTimePeriod?.year
        ? this.generateYearFromOptions(
            s.scopeTimeRange?.startTimePeriod?.year,
            s.scopeTimeRange?.endTimePeriod?.year,
            s.endTimePeriod?.year
          )
        : [];
    const yearFromSelectedOption =
      yearFromOptions.find(o => o.value === s.startTimePeriod.year?.toString()) || yearFromOptions[0];
    const yearFromFc = new FormControl<IOption>(yearFromSelectedOption, { nonNullable: true });
    yearFromFc.statusChanges.subscribe(async value => {
      await this.updateTimePeriod(
        s.id,
        parseInt(yearFromFc.value.value),
        parseInt(yearToFc.value.value),
        TimePeriodType[timePeriodTypeFc.value.value as TimePeriodType]
      );
      this.calculate(s.id);
    });

    const yearToOptions =
      s.scopeTimeRange?.startTimePeriod?.year && s.scopeTimeRange?.endTimePeriod?.year
        ? this.generateYearToOptions(
            s.scopeTimeRange?.startTimePeriod?.year,
            s.scopeTimeRange?.endTimePeriod?.year,
            s.startTimePeriod?.year
          )
        : [];
    const yearToSelectedOption =
      yearFromOptions.find(o => o.value === s.endTimePeriod.year?.toString()) ||
      yearToOptions[yearToOptions.length - 1];
    const yearToFc = new FormControl<IOption>(yearToSelectedOption, { nonNullable: true });
    yearToFc.statusChanges.subscribe(async value => {
      await this.updateTimePeriod(
        s.id,
        parseInt(yearFromFc.value.value),
        parseInt(yearToFc.value.value),
        TimePeriodType[timePeriodTypeFc.value.value as TimePeriodType]
      );
      this.calculate(s.id);
    });

    const timePeriodTypeSelectedOption =
      KpisTimePeriodTypeOptions.find(o => o.value === s.startTimePeriod.type) || KpisTimePeriodTypeOptions[0];
    const timePeriodTypeFc = new FormControl<IOption>(timePeriodTypeSelectedOption, { nonNullable: true });
    timePeriodTypeFc.statusChanges.subscribe(async value => {
      await this.updateTimePeriod(
        s.id,
        parseInt(yearFromFc.value.value),
        parseInt(yearToFc.value.value),
        TimePeriodType[timePeriodTypeFc.value.value as TimePeriodType]
      );
      this.calculate(s.id);
    });

    var totalValue = s.calculatedSeries.reduce((prev, curr) => curr.value + prev, 0);
    var totalTarget = s.targetSeries.reduce((prev, curr) => curr.value + prev, 0);

    var valueSeriesArray: IDataSeriesUi[] = [];

    if (!!s.calculatedSeries.length) {
      valueSeriesArray.push({
        name: 'Total',
        series: s.calculatedSeries.map(di => ({
          name: di.label,
          value: di.value,
          errorMessage: di.errorMessage,
        })),
        labelSeries: [],
      });

      if (
        s.calculatedAssetsSeries.length &&
        true
        // (s.indicatorDataValueDefinitionId === '46452e86-f3a9-4b05-9ca8-cac263a023aa' || // Show only for CII KPIs
        //   s.indicatorDataValueDefinitionId === '51CA055D-FFBB-49CC-B868-F4C04F44DAE3' || // Show only for CII KPIs
        //   s.indicatorDataValueDefinitionId === '93CA1FA3-D57A-4F07-9954-C449BDEC5EDA') // Show only for CII KPIs
      ) {
        s.calculatedAssetsSeries.forEach(cas => {
          valueSeriesArray.push({
            name: cas.assetName,
            hidden: false,
            series: cas.calculatedSeries.map(di => ({
              name: di.label,
              value: di.value,
              errorMessage: di.errorMessage,
            })),
            labelSeries: [],
          });
        });
      }
    } else {
      valueSeriesArray.push({
        name: 'Total',
        series: [],
        labelSeries: [],
      });
    }

    return {
      id: s.id,
      name: s.indicatorDataValueDefinitionData.label,
      type: s.indicatorDataValueDefinitionData.calculationResultType,
      yLabel: s.indicatorDataValueDefinitionData.unit,
      xLabel: s.startTimePeriod.type,
      valueSeriesArray: valueSeriesArray,
      targetSeries: !!s.targetSeries.length
        ? {
            name: 'Target',
            series: s.targetSeries.map(di => ({ name: di.label, value: di.value })),
            labelSeries: [],
          }
        : {
            name: 'Target',
            series: [],
            labelSeries: [],
          },
      targetSet: !!s.targetSeries.length,
      totalValue:
        s.indicatorDataValueDefinitionData.calculationResultType === CalculationResultType.Average
          ? totalValue / s.calculatedSeries.filter(s => s.value !== 0).length
          : totalValue,
      totalUnit: s.indicatorDataValueDefinitionData.unit,
      totalTargetValue:
        s.indicatorDataValueDefinitionData.calculationResultType === CalculationResultType.Average
          ? totalTarget / s.targetSeries.length
          : totalTarget,

      yearFromFc: yearFromFc,
      yearFromOptions: yearFromOptions,
      yearToFc: yearToFc,
      yearToOptions: yearToOptions,
      timePeriodTypeFc: timePeriodTypeFc,
      timePeriodTypeOptions: KpisTimePeriodTypeOptions,
    };
  };

  async calculate(id: string) {
    if (!this.loadingCharts.includes(id)) {
      this.loadingCharts = [...this.loadingCharts, id];
      await this.seriesApi.calculate(id);
      const { result } = await this.seriesApi.getById(id);
      this.kpis = this.kpis.map(k => (k.id !== id ? k : this.mapDataItemSeries(result)));
      this.loadingCharts = this.loadingCharts.filter(c => c !== id);
    }
  }

  setTargetDialog(id: string) {
    this.targetDialogForKpiId = id;
  }

  async removeDataValueSeries(id: string) {
    await this.seriesApi.remove(id);
    this.kpis = this.kpis.filter(c => c.id !== id);
  }

  async onUpdatedTarget(id: string) {
    this.targetDialogForKpiId = undefined;
    this.loadingCharts = [...this.loadingCharts, id];
    const response = await this.seriesApi.getById(id);
    this.kpis = this.kpis.map(k => (k.id !== id ? k : this.mapDataItemSeries(response.result)));
    this.loadingCharts = this.loadingCharts.filter(c => c !== id);
  }

  closeTargetDialog() {
    this.targetDialogForKpiId = undefined;
  }

  newKpiContextMenu(open: boolean) {
    this.newKpiContextMenuOpen = open;
  }

  async newKpiSelected(id: string) {
    this.newKpiContextMenuOpen = false;
    if (!this.appInfoService.organizationContext.value) {
      throw Error("OrganizationContext isn't set.");
    }
    const newKpiResponse = await this.seriesApi.createKpi(this.appInfoService.organizationContext.value, id);
    if (!newKpiResponse.success) {
      newKpiResponse.validationErrors?.forEach(e => {
        this.notificationService.showError('It is not possible to add new KPI', e);
      });
      return;
    }
    const newKpiSeriesResponse = await this.seriesApi.getById(newKpiResponse.result);
    this.kpis = [...this.kpis, this.mapDataItemSeries(newKpiSeriesResponse.result)];
    await this.calculate(newKpiResponse.result);
  }
}
