import { Component, Input } from '@angular/core';
import { IndicatorPerformanceStateService } from './indicator-performance-state.service';
import { IndicatorUnitUi } from '../indicator-details-state.service';
import { CalculationType, IColumnDefinition, IndicatorTableValueDefinition } from 'src/api-client/report-api.generated';
import {
  GenericColumnType,
  GenericColumnUi,
  mapCellValueTypeToGenericType,
} from 'src/app/shared/table/generic-table/generic-table.types';
import { IOption } from 'src/app/static-data/options';

@Component({
  selector: 'esg-indicator-performance',
  templateUrl: './indicator-performance.component.html',
  styleUrl: './indicator-performance.component.scss',
  providers: [IndicatorPerformanceStateService],
})
export class IndicatorPerformanceComponent {
  @Input({ required: true }) indicatorId!: string;
  @Input({ required: true }) indicatorUnit!: IndicatorUnitUi;
  @Input() indicatorNumericCalculationType?: CalculationType;
  @Input() indicatorTableDefinition?: IndicatorTableValueDefinition;

  calculatedTableHeaders: GenericColumnUi[] = [];
  calculatedTableColumnTypeMap: Map<string, GenericColumnType> = new Map();
  calculatedTableColumnOptionsMap: Map<string, IOption[]> = new Map();
  calculatedTableColumnUnitMap: Map<string, string | undefined> = new Map();

  get isNumericAverage() {
    return this.indicatorNumericCalculationType === CalculationType.Average;
  }

  constructor(public state: IndicatorPerformanceStateService) {}

  ngOnInit(): void {
    this.setTableDefinitionHeadersAndMaps();
    this.state.init(this.indicatorId);
  }

  handleSetTarget() {
    this.state.targetDialogOpen = true;
  }

  handleCloseTargetDialog() {
    this.state.targetDialogOpen = false;
  }

  setTableDefinitionHeadersAndMaps() {
    if (this.indicatorTableDefinition) {
      const tableDefinitionHasRows =
        this.indicatorTableDefinition.rows && this.indicatorTableDefinition.rows.length > 0;

      this.calculatedTableHeaders =
        this.indicatorTableDefinition.columns.map((col: IColumnDefinition, index) => ({
          header: col.header,
          description: col.description,
          columnId: col.columnId,
          type:
            tableDefinitionHasRows && index === 0
              ? GenericColumnType.StaticText
              : mapCellValueTypeToGenericType(col.type),
          width: undefined,
          unit: col.unit,
          options: col.selectorOptions?.map(option => ({ value: option.value.toString(), label: option.label })),
        })) || [];

      this.calculatedTableHeaders.forEach(column => {
        this.calculatedTableColumnTypeMap.set(column.columnId, column.type);
        this.calculatedTableColumnUnitMap.set(column.columnId, column.unit);
        if (column.options) {
          this.calculatedTableColumnOptionsMap.set(column.columnId, column.options);
        }
      });
    }
  }
}
