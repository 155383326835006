import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import _ from 'lodash';
import {
  CalculationType,
  CreateIndicatorDefinitionCommandParams,
  GetAllIndicatorDefinitionsQueryVm,
  IndicatorCategory,
  IndicatorNumericValueDefinition,
  IndicatorValueType,
  RecordDuration,
  UpdateIndicatorDefinitionCommandParams,
} from 'src/api-client/report-api.generated';
import { IndicatorDefinitionApiService } from 'src/app/api-client/report-api/indicator-definition-api-service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { IOption } from 'src/app/static-data/options';
import { IndicatorDefinitionUi } from './admin-indicator-library.component';
import { Constants } from 'src/app/static-data/constants';

interface IndicatorDefinitionForm {
  name: FormControl<string>;
  category: FormControl<IOption>;
}

@Injectable()
export class IndicatorsLibraryStateService {
  indicatorDefinitions: IndicatorDefinitionUi[] = [];
  selectedIndicatorId?: string;
  loading: boolean = false;

  categoryOptions: IOption[] = Object.values(IndicatorCategory).map(c => ({ value: c, label: c }));

  editedIndicatorId?: string;
  indicatorForm?: IndicatorDefinitionForm;
  indicatorFormGroup?: FormGroup<IndicatorDefinitionForm>;

  showDeleteDialog: boolean = false;

  showStickyDivider: boolean = false;

  constructor(
    private indicatorDefinitionsApiService: IndicatorDefinitionApiService,
    private notificationService: NotificationService
  ) {
    this.init();
  }

  async init() {
    try {
      this.loading = true;
      await this.getIndicators();
    } catch (error) {
      this.notificationService.showError('Failed to Load Indicators');
    } finally {
      this.loading = false;
    }
  }

  async getIndicators() {
    const response = await this.indicatorDefinitionsApiService.getAll(false);
    this.indicatorDefinitions = _.sortBy(response.result, [pi => pi.name.toLowerCase()]).map(indicator => ({
      id: indicator.id,
      name: indicator.name,
      category: indicator.category,
      valueType: indicator.valueType,
      description: indicator.description,
      tableValueDefinition: indicator.tableValueDefinition,
      numericValueDefinition: indicator.numericValueDefinition,
      frameworkStandards: indicator.frameworkStandards,
      sectors: indicator.sectors,
      isIndicatorFromSystem: indicator.isIndicatorFromSystem,
      rawDataSourceIds: indicator.indicatorRawDataSourceIds,
      indicatorDefinitionDataSourceIds: indicator.indicatorDefinitionAsDataSourceIds,
      organizationAccessIds: indicator.organizationAccessIds,
      recordDuration: indicator.recordDuration,
      accessibility: this.setAccessibility(indicator),
      createdBy: indicator.createdBy,
    }));
  }

  setAccessibility(indicatorDefinition: GetAllIndicatorDefinitionsQueryVm) {
    if (!indicatorDefinition.organizationAccessIds || indicatorDefinition.organizationAccessIds.length === 0) {
      return 'Not set';
    }
    if (
      indicatorDefinition.organizationAccessIds.length === 1 &&
      indicatorDefinition.organizationAccessIds.includes(Constants.GlobalKey)
    ) {
      return 'Global';
    }
    return 'Custom';
  }

  selectIndicator(indicatorId: string) {
    this.resetForm();
    this.editedIndicatorId = undefined;
    this.selectedIndicatorId = indicatorId;
  }

  closeSelectedIndicator() {
    this.selectedIndicatorId = undefined;
    this.getIndicators();
  }

  createIndicator() {
    this.editedIndicatorId = undefined;
    this.indicatorForm = {
      name: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      category: new FormControl<IOption>(this.categoryOptions[0], {
        validators: [Validators.required],
        nonNullable: true,
      }),
    };
    this.indicatorFormGroup = new FormGroup(this.indicatorForm);
  }

  async createIndicatorSubmit() {
    const category = Object.values(IndicatorCategory).find(type => type === this.indicatorForm?.category.value.value);
    if (!this.indicatorFormGroup || !this.indicatorFormGroup?.valid || !category) {
      this.cancelCreate();
      return;
    }

    const name = this.indicatorFormGroup.controls.name.value;
    this.resetForm();

    try {
      const response = await this.indicatorDefinitionsApiService.createIndicatorDefinition(
        new CreateIndicatorDefinitionCommandParams({
          name: name,
          category: category,
          valueType: IndicatorValueType.Numeric,
          description: '',
          numericValueDefinition: new IndicatorNumericValueDefinition({
            unit: 'Number',
            unitLabel: '',
            calculationType: CalculationType.Sum,
          }),
          frameworkStandards: '',
          sectors: [],
          indicatorRawDataSourceIds: [],
          indicatorDefinitionAsDataSourceIds: [],
          recordDuration: RecordDuration.DateFromTo,
        })
      );
      if (response.success) {
        this.getIndicators();
      }
    } catch (error) {
      this.notificationService.showError('Failed to create indicator');
    }
  }

  cancelCreate() {
    this.resetForm();
  }

  editIndicator(indicatorId: string) {
    this.editedIndicatorId = indicatorId;
    const indicator = this.indicatorDefinitions.find(i => i.id === indicatorId);
    if (indicator) {
      this.indicatorForm = {
        name: new FormControl<string>(indicator.name, { nonNullable: true }),
        category: new FormControl<IOption>(
          this.categoryOptions.find(opt => opt.value === indicator.category) || this.categoryOptions[0],
          { nonNullable: true }
        ),
      };
      this.indicatorFormGroup = new FormGroup(this.indicatorForm);
    }
  }

  async updateIndicatorSubmit() {
    const indicator = this.indicatorDefinitions.find(i => i.id === this.editedIndicatorId);
    if (!this.indicatorFormGroup || !this.indicatorFormGroup?.dirty || !this.indicatorFormGroup?.valid || !indicator) {
      this.cancelEdit();
      return;
    }

    const name = this.indicatorFormGroup.controls.name.value;
    const category = Object.values(IndicatorCategory).find(type => type === this.indicatorForm?.category.value.value);
    this.editedIndicatorId = undefined;
    this.resetForm();

    try {
      const response = await this.indicatorDefinitionsApiService.updateIndicatorDefinition(
        new UpdateIndicatorDefinitionCommandParams({
          id: indicator.id,
          name: name,
          description: indicator.description,
          frameworkStandards: indicator.frameworkStandards,
          category: category || indicator.category,
          sectors: indicator.sectors,
          tableValueDefinition: indicator.tableValueDefinition,
          numericValueDefinition: indicator.numericValueDefinition,
          indicatorRawDataSourceIds: indicator.rawDataSourceIds,
          indicatorDefinitionAsDataSourceIds: indicator.indicatorDefinitionDataSourceIds,
          organizationAccessIds: indicator.organizationAccessIds,
          valueType: indicator.valueType,
          recordDuration: indicator.recordDuration,
        })
      );
      if (response.success) {
        this.getIndicators();
      }
    } catch (error) {
      this.notificationService.showError('Failed to update indicator');
    }
  }

  cancelEdit() {
    this.editedIndicatorId = undefined;
    this.resetForm();
  }

  initiateDelete() {
    this.showDeleteDialog = true;
  }

  async deleteIndicatorSubmit() {
    this.showDeleteDialog = false;
    const definition = this.indicatorDefinitions.find(def => def.id === this.selectedIndicatorId);
    if (definition) {
      const response = await this.indicatorDefinitionsApiService.deleteIndicatorDefinition(definition.id);
      if (response.success) {
        this.selectedIndicatorId = undefined;
        this.getIndicators();
      } else {
        this.notificationService.showError('Failed to delete indicator', response.message);
      }
    }
  }

  cancelDelete() {
    this.showDeleteDialog = false;
  }

  resetForm() {
    this.indicatorForm = undefined;
    this.indicatorFormGroup = undefined;
  }

  handleStickyDivider(tabEntry: IntersectionObserverEntry) {
    this.showStickyDivider = tabEntry.intersectionRatio === 0;
  }

  handleSelectCategory(category: IOption) {
    if (this.indicatorForm && this.indicatorForm.category.value !== category) {
      this.indicatorForm.category.setValue(category);
      this.indicatorForm.category.markAsDirty();
    }
  }
}
