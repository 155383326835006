import { Component, Input, OnInit } from '@angular/core';
import { formatNumbersInString } from 'src/app/shared/utils/number-converters';

export interface SubstrateDataUi {
  tHead: TrUi[];
  tBody: TrUi[];
  tFoot: TrUi[];
}

interface TrUi {
  items: TrItemUi[];
}

interface TrItemUi {
  rowSpan?: number;
  colSpan?: number;
  content?: string;
  isNumber: boolean;
}

@Component({
  selector: 'esg-substrate-data',
  templateUrl: './substrate-data.component.html',
  styleUrls: ['./substrate-data.component.scss'],
})
export class SubstrateDataComponent implements OnInit {
  @Input({ required: true }) substrateData!: SubstrateDataUi;
  @Input() showFooter: boolean = false;

  getFormattedNumbers = formatNumbersInString;

  constructor() {}

  ngOnInit(): void {}
}
