import { Component, OnInit } from '@angular/core';
import { IOption } from 'src/app/shared/table/table-dropdown/table-dropdown.component';
import { IMenuItem } from 'src/app/shared/ui/context-menu/context-menu.component';
import { PredefinedIndicatorsStateService } from './predefined-indicators-state.service';

export interface PredefinedIndicatorListItemUi {
  id: string;
  name: string;
  type: IOption;
  lastUpdated: Date;
  editedBy: string;
}

export enum ViewModeEnums {
  Read,
  Create,
  Edit,
}

@Component({
  selector: 'esg-predefined-indicators',
  templateUrl: './predefined-indicators.component.html',
  styleUrls: ['./predefined-indicators.component.scss'],
  providers: [PredefinedIndicatorsStateService],
})
export class PredefinedIndicatorsComponent implements OnInit {
  public ViewModes = ViewModeEnums;
  constructor(public state: PredefinedIndicatorsStateService) {}

  ngOnInit(): void {}

  handleClickCreate(event: Event) {
    event.stopPropagation();
    this.state.create();
  }

  handleSubmitCreate() {
    this.state.submitCreate();
  }

  handleCreateEnter() {
    this.state.submitCreate();
  }

  handleCancelCreate() {
    this.state.cancelCreate();
  }

  handleCancelEdit() {
    this.state.clearSelection();
  }

  handleEditIndicator(event: Event, indicator: PredefinedIndicatorListItemUi) {
    this.state.editIndicator(indicator);
  }

  handleSelectIndicator(event: Event, indicatorId: string) {
    this.state.selectIndicator(indicatorId);
  }

  handleSubmitEdit() {
    this.state.submitEdit();
    this.state.clearSelection();
  }

  handleDelete() {
    this.state.deleteIndicator();
  }

  predefinedIndicatorMenuProvider = (): IMenuItem[] => [
    {
      id: 'delete',
      label: 'Delete',
      onClick: () => this.handleDelete(),
      disabled: false,
    },
  ];
}
