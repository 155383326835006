<div class="title">
  {{ indicator.name }}
</div>
<ng-container *ngIf="isDataValueIndicator">
  <table class="numeric-value-table" *ngIf="numericalValues && numericalValues.length">
    <thead>
      <tr>
        <th></th>
        <th class="unit">
          <div class="header-content">
            <span class="label">Unit</span>
            <span *ngIf="isCustomPeriod" class="description"></span>
          </div>
        </th>
        <th class="number">
          <div class="header-content">
            <span class="label target">{{ isCustomPeriod ? 'target' : 'target ' + getFigureTimeLabel(1) }}</span>
            <span *ngIf="isCustomPeriod" class="description">{{ getFigureTimeLabel(1) }}</span>
          </div>
        </th>
        <th class="number">
          <div class="header-content">
            <span class="label current">{{ isCustomPeriod ? 'current' : getFigureTimeLabel(0) }}</span>
            <span *ngIf="isCustomPeriod" class="description">{{ getFigureTimeLabel(0) }}</span>
          </div>
        </th>
        <th class="number">
          <div class="header-content">
            <span class="label">{{ isCustomPeriod ? 'previous' : getFigureTimeLabel(-1) }}</span>
            <span *ngIf="isCustomPeriod" class="description">{{ getFigureTimeLabel(-1) }}</span>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let value of numericalValues">
        <tr *ngIf="value.metadata.isVisibleInReport">
          <td class="name">{{ value.metadata.label || '' }}</td>
          <td
            class="unit"
            [innerHTML]="value.currentValue.unitDetails || value.metadata.unit | formatUnitWithHtml"></td>
          <td class="number">{{ getFormattedNumber(value.targetValue.value) }}</td>
          <td class="number current">
            {{ getFormattedNumber(value.currentValue.value) }}
          </td>
          <td class="number">
            {{ getFormattedNumber(value.previousValue.value) }}
          </td>
        </tr>
      </ng-container>
      <tr *ngIf="indicator.substrateData">
        <esg-substrate-data-preview
          class="substrate-data"
          [substrateData]="indicator.substrateData"></esg-substrate-data-preview>
      </tr>
      <tr>
        <td class="reference">
          <span class="label">Reference:&nbsp;</span>
          <span>{{ indicator.references }}</span>
        </td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="tableValues && tableValues.length" class="table-values">
    <ng-container *ngFor="let table of tableValues">
      <esg-calculation-table
        *ngIf="table.metadata.isVisibleInReport"
        [calculatedData]="table"
        [timePeriod]="indicator.timePeriod"
        [references]="indicator.references"></esg-calculation-table>
    </ng-container>
  </div>
</ng-container>
<div *ngIf="indicator.contents && indicator.contents.length" class="content">
  <div class="component" *ngFor="let component of indicator.contents">
    <esg-text-view-editor *ngIf="component?.textHtml" [content]="component?.textHtml"></esg-text-view-editor>
    <esg-table-data
      *ngIf="component?.tableConfig"
      [editMode]="false"
      [config]="component.tableConfig"
      [rows]="component.rows"></esg-table-data>
    <img *ngIf="component?.blobUrl" [src]="component?.blobUrl" [alt]="component?.altText" />
  </div>
</div>
