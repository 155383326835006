<h2>Predefined Indicators</h2>
<table>
  <thead>
    <tr>
      <th><esg-table-header-text>INDICATOR</esg-table-header-text></th>
      <th><esg-table-header-text>TYPE</esg-table-header-text></th>
      <th><esg-table-header-text>LAST UPDATED</esg-table-header-text></th>
      <th><esg-table-header-text>EDITED BY</esg-table-header-text></th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let indicator of state.indicators">
      <tr
        class="read-mode"
        *ngIf="state.editedIndicator?.id !== indicator.id"
        [class.active]="state.selectedIndicatorId === indicator.id"
        (click.single)="handleSelectIndicator($event, indicator.id)"
        (click.double)="handleEditIndicator($event, indicator)">
        <td>
          <esg-table-text>{{ indicator.name }}</esg-table-text>
        </td>
        <td>
          <esg-table-text class="nowrap">{{ indicator.type.label }}</esg-table-text>
        </td>
        <td>
          <esg-table-text class="nowrap">{{ indicator.lastUpdated | date }}</esg-table-text>
        </td>
        <td>
          <esg-table-text class="nowrap">{{ indicator.editedBy }}</esg-table-text>
        </td>
        <td>
          <esg-table-button label="Details" (onClick)="state.selectIndicator(indicator.id)"></esg-table-button>
        </td>
      </tr>
      <tr
        class="edit-row"
        *ngIf="state.editedIndicator?.id === indicator.id"
        esg-on-click-outside
        (onClickOutside)="handleSubmitEdit()"
        esgKeyboardShortcuts
        (onEscapeKey)="handleCancelEdit()"
        (onEnterKey)="handleSubmitEdit()">
        <td>
          <esg-table-input name="indicatorName" [fc]="state.editedIndicatorNameFormControl"> </esg-table-input>
        </td>
        <td class="disabled-dropdown">
          <esg-table-dropdown
            [disabled]="true"
            name="indicatorType"
            [fc]="state.editedIndicatorTypeFormControl"
            [options]="state.predefinedIndicatorTypeOptions"></esg-table-dropdown>
        </td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </ng-container>
    <tr *ngIf="state.viewMode !== ViewModes.Create" class="add-line">
      <td [class.expand-line]="state.showStickyDivider">
        <div
          esgIntersectionObserver
          [threshold]="0"
          rootMargin="0px 0px -72px 0px"
          (onIntersection)="state.handleStickyDivider($event)"></div>
        <esg-add-button label="Create New" (onClick)="!state.viewMode && handleClickCreate($event)"></esg-add-button>
      </td>
    </tr>
    <tr
      class="create-row"
      *ngIf="state.viewMode === ViewModes.Create"
      esg-on-click-outside
      (onClickOutside)="handleSubmitCreate()"
      esgKeyboardShortcuts
      (onEscapeKey)="handleCancelCreate()"
      (onEnterKey)="handleCreateEnter()">
      <td>
        <esg-table-input name="indicatorName" [fc]="state.editedIndicatorNameFormControl"> </esg-table-input>
      </td>
      <td>
        <esg-table-dropdown
          name="indicatorType"
          [fc]="state.editedIndicatorTypeFormControl"
          [options]="state.predefinedIndicatorTypeOptions"></esg-table-dropdown>
      </td>
      <td></td>
      <td></td>
      <td>
        <esg-table-cancel-button label="Cancel" (onClick)="handleCancelCreate()"></esg-table-cancel-button>
      </td>
    </tr>
  </tbody>
</table>
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<esg-details-dialog
  *ngIf="state.selectedIndicatorId"
  [open]="true"
  [options]="predefinedIndicatorMenuProvider()"
  (onClose)="state.closeSelectedIndicator()">
  <esg-predefined-indicator-details
    [selectedIndicatorId]="state.selectedIndicatorId"></esg-predefined-indicator-details>
</esg-details-dialog>
