<esg-form-dialog
  submitText="Create asset"
  [submitDisabled]="isFormDisabled"
  (onCancel)="handleCancel()"
  (onSubmit)="handleOnSubmitClick()">
  <div class="container">
    <header>Create new asset</header>

    <div class="content">
      <div class="section">
        <span class="section-header"> 1. Select Asset type </span>
        <esg-select
          [options]="state.assetTypeOptions"
          [value]="state.selectedAssetType"
          label="ASSET TYPE"
          placeholder="Select"
          [isPopup]="true"
          (onChange)="state.setSelectAssetType($event)">
        </esg-select>
      </div>
      <div class="section">
        <span class="section-header" [class.disabled]="!state.selectedAssetType?.value"> 2. Add Asset properties </span>

        <ng-container [ngSwitch]="state.selectedAssetType?.value">
          <esg-vessels-table
            *ngSwitchCase="state.assetTypeEnum.Vessel"
            [addOnly]="true"
            [organizationId]="state.organizationId"
            [organizationOptions]="state.organizationOptions"
            [countryOptions]="state.countryOptions"
            [organizationCountryMap]="state.organizationCountryMap"
            (onFormSubmit)="handleOnFormSubmit($event)"></esg-vessels-table>
          <esg-vehicles-crafts-machinery-table
            *ngSwitchCase="state.assetTypeEnum.VehicleCraftMachinery"
            [addOnly]="true"
            [organizationId]="state.organizationId"
            [organizationOptions]="state.organizationOptions"
            [countryOptions]="state.countryOptions"
            [organizationCountryMap]="state.organizationCountryMap"
            (onFormSubmit)="handleOnFormSubmit($event)"></esg-vehicles-crafts-machinery-table>
          <esg-suppliers-table
            *ngSwitchCase="state.assetTypeEnum.Supplier"
            [addOnly]="true"
            [organizationId]="state.organizationId"
            [organizationOptions]="state.organizationOptions"
            [countryOptions]="state.countryOptions"
            [sectorOptions]="state.sectorOptions"
            [organizationCountryMap]="state.organizationCountryMap"
            (onFormSubmit)="handleOnFormSubmit($event)"></esg-suppliers-table>
          <esg-facilities-table
            *ngSwitchCase="state.assetTypeEnum.Facility"
            [addOnly]="true"
            [organizationId]="state.organizationId"
            [organizationOptions]="state.organizationOptions"
            [countryOptions]="state.countryOptions"
            [organizationCountryMap]="state.organizationCountryMap"
            (onFormSubmit)="handleOnFormSubmit($event)"></esg-facilities-table>
          <esg-clients-table
            *ngSwitchCase="state.assetTypeEnum.Client"
            [addOnly]="true"
            [organizationId]="state.organizationId"
            [organizationOptions]="state.organizationOptions"
            [countryOptions]="state.countryOptions"
            [organizationCountryMap]="state.organizationCountryMap"
            (onFormSubmit)="handleOnFormSubmit($event)"></esg-clients-table>
        </ng-container>
      </div>
    </div>
  </div>
</esg-form-dialog>
