import {
  GetAllIndicatorDataValueDefinitionsQueryResponse,
  GetAllNamesIndicatorDataValueDefinitionsQueryResponse,
  IndicatorDataValueDefinitionClient,
} from './../../../api-client/report-api.generated';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';

@Injectable({
  providedIn: 'root',
})
export class IndicatorDataValueDefinitionApiService {
  client: IndicatorDataValueDefinitionClient;

  constructor(readonly httpClient: HttpClient, readonly appSettings: AppConfigurationService) {
    this.client = new IndicatorDataValueDefinitionClient(httpClient, appSettings.ReportApiBaseUrl);
  }

  async getAllDataValueTypes(): Promise<GetAllNamesIndicatorDataValueDefinitionsQueryResponse> {
    const observable$ = this.client.getAllNamesIndicatorDataValueDefinitions();
    return await lastValueFrom(observable$);
  }
  async getAll(): Promise<GetAllIndicatorDataValueDefinitionsQueryResponse> {
    const observable$ = this.client.getAllIndicatorDataValueDefinitions();
    return await lastValueFrom(observable$);
  }
}
