import { Component, Input, OnInit } from '@angular/core';
import { formatNumbersInString } from 'src/app/shared/utils/number-converters';

export interface SubstrateDataPreviewUi {
  tHead: TrUi[];
  tBody: TrUi[];
  tFoot: TrUi[];
}

interface TrUi {
  items: TrItemUi[];
}

interface TrItemUi {
  rowSpan?: number;
  colSpan?: number;
  content?: string;
  isNumber: boolean;
}

@Component({
  selector: 'esg-substrate-data-preview',
  templateUrl: './substrate-data-preview.component.html',
  styleUrls: ['./substrate-data-preview.component.scss'],
})
export class SubstrateDataPreviewComponent implements OnInit {
  @Input({ required: true }) substrateData!: SubstrateDataPreviewUi;

  getFormattedNumbers = formatNumbersInString;

  constructor() {}

  ngOnInit(): void {}
}
