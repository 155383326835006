import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-picture-toolbar',
  templateUrl: './picture-toolbar.component.html',
  styleUrls: ['./picture-toolbar.component.scss'],
})
export class PictureToolbarComponent implements OnInit {
  @Input() altTagControl?: FormControl<string | null> = undefined;
  @Input() edit: boolean = false;
  @Output() onReplace = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Output() onTagUpdate = new EventEmitter<string>();
  @Output() onDelete = new EventEmitter();

  @ViewChild('input') private input: ElementRef<HTMLElement> | undefined;
  inputFocusOut = false;

  constructor() {}

  ngOnInit(): void {}

  handleOnImageEditClick() {
    this.inputFocusOut = false;
    this.edit ? this.onCancel.emit() : this.onReplace.emit();
  }

  handleOnDelete() {
    this.inputFocusOut = false;
    this.onDelete.emit();
  }

  handleOnAltTagClick() {
    if (this.altTagControl && this.altTagControl.disabled && !this.inputFocusOut) {
      this.altTagControl.enable();
      if (!this.altTagControl.disabled && this.input) {
        this.input.nativeElement.focus();
      }
    } else {
      this.inputFocusOut = false;
    }
  }

  handleOnAltTagSave(value: string) {
    if (this.altTagControl) {
      this.onTagUpdate.emit(value);
      this.altTagControl.disable();
    }
  }

  handleOnAltTagEnter() {
    this.input && this.input.nativeElement.blur();
    this.inputFocusOut = false;
  }

  handleOnAltTagBlur(value: string) {
    this.inputFocusOut = true;
    this.handleOnAltTagSave(value);
  }
}
