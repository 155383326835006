<tr
  esg-on-click-outside
  class="input-row"
  (onClickOutside)="handleSubmit()"
  esgKeyboardShortcuts
  (onEscapeKey)="handleCancel()"
  (onEnterKey)="handleSubmit()">
  <td>
    <span class="indent" [style.padding-left.px]="indent" (click)="handleSubmit()"></span>
    <span class="expand-icon" [class.expanded]="expanded" (click)="handleSubmit()">
      <esg-suborg-icon></esg-suborg-icon>
    </span>
    <esg-textbox-form
      class="input-name"
      [class.error]="showErrors && !subOrgForm.name.valid"
      type="text"
      [fc]="subOrgForm.name"
      [autoFocus]="true"
      variant="ghost-table"
      placeholder="Name of Sub Organization"></esg-textbox-form>
  </td>
  <td class="input-cell">
    <esg-table-select
      class="input-dropdown"
      [options]="countryOptions"
      [value]="subOrgForm.country.value || undefined"
      [fitLongestLabel]="false"
      (onChange)="handleCountryChange($event)">
    </esg-table-select>
  </td>
  <td class="input-cell" [class.error]="showErrors && !subOrgForm.orgID.valid">
    <esg-textbox-form
      type="text"
      [fc]="subOrgForm.orgID"
      variant="ghost-table"
      placeholder="NO 123 456 780 ABC"></esg-textbox-form>
  </td>
  <td></td>
  <td></td>
  <td>
    <esg-table-cancel-button label="Cancel" (onClick)="handleCancel()"></esg-table-cancel-button>
  </td>
</tr>
