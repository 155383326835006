<div class="first-toolbar">
  <button (click)="handleOnClose()">
    <esg-icon-close></esg-icon-close>
  </button>
</div>
<div class="second-toolbar">
  <button class="close" (click)="handleOnClose()">
    <esg-arrow-right-long></esg-arrow-right-long>
    Close
  </button>
</div>
<div class="content">
  <span class="background" [style.background]="brandColor"></span>
  <div class="menu">
    <span class="title">{{ reportName }}</span>
    <div *ngFor="let tab of tabs" class="tabs">
      <a
        *ngIf="tab.link"
        class="tab-item"
        [class.disabled]="tab.disabled"
        [routerLink]="tab.link"
        routerLinkActive="active"
        (click)="handleOnTabClick()"
        >{{ tab.label ? tab.label : tab.value }}
      </a>
    </div>
  </div>
</div>
