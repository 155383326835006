<tr *ngIf="mode !== modes.Edit">
  <td [style.padding-left.px]="indent">
    <button (click)="handleExpand($event)" class="expand-button" [class.expanded]="expanded">
      <esg-suborg-icon></esg-suborg-icon>
    </button>
    <span (dblclick)="handleOnEditClick($event)" class="name">
      <button *ngIf="mode === modes.Read" class="add-button" (click)="handleOnAddClick($event)">
        <esg-icon-add color="white"></esg-icon-add></button
      >{{ organization.name }}</span
    >
  </td>
  <td (dblclick)="handleOnEditClick($event)">
    {{ organization.countryName }}
  </td>
  <td (dblclick)="handleOnEditClick($event)">
    {{ organization.orgID }}
  </td>
  <td (dblclick)="handleOnEditClick($event)" class="align-right">
    {{ organization.allSubOrganizationsCount || '-' }}
  </td>
  <td (dblclick)="handleOnEditClick($event)"></td>
  <td class="align-right">
    <esg-table-delete-button label="Delete" (onClick)="handleOnDelete()"></esg-table-delete-button>
  </td>
</tr>
<esg-sub-organization-form
  *ngIf="mode === modes.Edit && subOrgFc"
  [subOrgForm]="subOrgFc"
  [showErrors]="showErrors"
  [countryOptions]="countryOptions"
  (onSubmit)="handleOnSubOrgEdit()"
  (onCancel)="handleOnSubOrgCancel()"
  [indent]="indent"
  [expanded]="expanded"></esg-sub-organization-form>
<ng-container *ngIf="expanded">
  <esg-sub-organization-form
    *ngIf="mode === modes.Add && subOrgFc"
    [subOrgForm]="subOrgFc"
    [showErrors]="showErrors"
    [countryOptions]="countryOptions"
    (onSubmit)="handleOnSubOrgSubmit()"
    (onCancel)="handleOnSubOrgCancel()"
    [indent]="indent + 50"></esg-sub-organization-form>
  <esg-sub-organization-row
    *ngFor="let sub of organization.subOrganizations"
    [organization]="sub"
    [countryOptions]="countryOptions"
    (onEditSubOrganization)="onEditSubOrganization.emit($event)"
    (onDeleteSubOrganization)="onDeleteSubOrganization.emit($event)"
    (onAddSubOrganization)="onAddSubOrganization.emit($event)"
    [indent]="indent + 50"></esg-sub-organization-row>
</ng-container>
