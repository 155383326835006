import {
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ComponentFactory,
  ContentActionsTypes,
  ContentDataTypes,
  ContentStatesTypes,
} from 'src/app/content/content-configuration';
import { DynamicContent } from 'src/app/content/dynamic-content';
import { DynamicContentMode, IDynamicContentData } from 'src/app/content/dynamic-content-data';
import { DynamicContentHostDirective } from './ad-host.directive';

@Component({
  selector: 'esg-dynamic-content-item',
  template: `<ng-template appDynamicContentHost></ng-template>`,
})
export class DynamicContentItemComponent implements OnInit, OnChanges {
  contentRef?: ComponentRef<IDynamicContentData<ContentActionsTypes, ContentStatesTypes, ContentDataTypes>>;
  @Input({ required: true }) item!: DynamicContent;
  @Input() readMode = false;
  @Input() reorderMode = false;
  @Input() active = false;
  @Input({ required: true }) onChange!: (
    contentId: string,
    states: ContentStatesTypes,
    data?: ContentDataTypes
  ) => void;
  @Output() onDelete = new EventEmitter<string>();
  @Output() onSetActive = new EventEmitter<string>();

  @ViewChild(DynamicContentHostDirective, { static: true }) adHost!: DynamicContentHostDirective;

  activeContentId?: string;

  constructor() {}

  ngOnInit(): void {
    this.loadComponent();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const itemField: SimpleChange = changes['item'];
    const reorderMode: SimpleChange = changes['reorderMode'];
    const active: SimpleChange = changes['active'];

    if (
      itemField &&
      itemField.previousValue &&
      JSON.stringify(itemField.previousValue) !== JSON.stringify(itemField.currentValue)
    ) {
      this.setInstance();
    }

    if (
      reorderMode &&
      reorderMode.previousValue !== undefined &&
      reorderMode.previousValue !== reorderMode.currentValue
    ) {
      this.setInstance();
    }

    if (active && active.previousValue !== undefined && active.previousValue !== active.currentValue) {
      this.setInstance();
    }
  }

  handleOnSetActive(active: boolean) {
    this.onSetActive.emit(active ? this.item.contentId : undefined);
  }

  loadComponent() {
    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();
    const element = this.item;
    this.contentRef = viewContainerRef.createComponent<
      IDynamicContentData<ContentActionsTypes, ContentStatesTypes, ContentDataTypes>
    >(ComponentFactory(element.contentType));
    this.setInstance();
  }

  setInstance() {
    const element = this.item;
    if (this.contentRef) {
      this.contentRef.instance.contentId = element.contentId;
      this.contentRef.instance.mode = this.readMode ? DynamicContentMode.Read : DynamicContentMode.Edit;
      this.contentRef.instance.actions = element.actions;
      this.contentRef.instance.states = element.states;
      this.contentRef.instance.data = element.data;
      this.contentRef.instance.onChange = this.onChange;
      this.contentRef.instance.reorderMode = this.reorderMode;
      this.contentRef.instance.active = this.active;
      this.contentRef.instance.onDelete.subscribe(id => this.onDelete.emit(id));
      this.contentRef.instance.onSetActive.subscribe(active => this.handleOnSetActive(active));
    }
  }
}
