import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import {
  CanCreateAsGlobalTemplateQueryResponse,
  FileParameter,
  GetTemplatesQueryResponse,
  ReportTemplate,
  TemplateClient,
} from '../../../api-client/report-api.generated';

@Injectable({
  providedIn: 'root',
})
export class TemplateApiService {
  client: TemplateClient;

  constructor(readonly httpClient: HttpClient, readonly appSettings: AppConfigurationService) {
    this.client = new TemplateClient(httpClient, appSettings.ReportApiBaseUrl);
  }

  saveReportAsTemplate(
    name: string,
    shortDescription: string,
    description: string,
    reportId: string,
    global: boolean,
    coverPhoto?: FileParameter
  ) {
    return this.client.saveReportAsTemplate(name, shortDescription, description, reportId, global, coverPhoto);
  }

  removeTemplate(templateId: string): Observable<ReportTemplate> {
    return this.client.removeTemplate(templateId);
  }

  getAllTemplates(includeGlobal: boolean): Observable<GetTemplatesQueryResponse> {
    return this.client.getAllTemplates(includeGlobal);
  }

  getTemplate(templateId: string): Observable<ReportTemplate> {
    return this.client.getTemplate(templateId);
  }

  update(
    templateId: string,
    name: string,
    shortDescription: string,
    description: string,
    global: boolean,
    coverPhoto?: FileParameter
  ) {
    return this.client.updateTemplate(templateId, name, shortDescription, description, global, coverPhoto);
  }

  async canCreateAsGlobalTemplate(sourceReportId: string): Promise<CanCreateAsGlobalTemplateQueryResponse> {
    const observable$ = this.client.canCreateAsGlobalTemplate(sourceReportId).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }
}
